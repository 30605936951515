import apiConfigs from "@apis/common/commonApiConfig";
import { SamplingRule, SamplingRulePayload } from "@convin/type/Sampling.model";
import { getDomain } from "@convin/utils/helper/common.helper";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const protocol = import.meta.env.VITE_APP_API_PROTOCOL ?? apiConfigs.HTTPS;
const api = import.meta.env.VITE_APP_INGRESS_SERVICE_URL;
const baseApi = api
    ? `${protocol}${api}/`
    : "https://ingus.convin.ai/pickr/v1/";

export const samplingManagerApiSlice = createApi({
    reducerPath: "samplingManagerApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseApi}${getDomain()}`,
    }),
    tagTypes: ["Sampling Rule"],
    endpoints: (builder) => ({
        getAllSamplingRules: builder.query<
            { sampling_rules: SamplingRule[] },
            { is_archived: boolean }
        >({
            query: ({ is_archived }) => ({
                url: "sampling_rules",
                method: "GET",
                params: {
                    filter: `is_archived:${is_archived ? "true" : "false"}`,
                },
            }),
            providesTags: [{ type: "Sampling Rule", id: "ALL" }],
        }),
        getSamplingRuleById: builder.query<SamplingRule, string>({
            query: (id) => `sampling_rules/${id}/`,
            providesTags: (_, err, id) =>
                !!err ? [] : [{ type: "Sampling Rule", id }],
        }),
        createSamplingRule: builder.mutation<
            unknown,
            Omit<SamplingRulePayload, "dist_or_alloc"> &
                Partial<Pick<SamplingRulePayload, "dist_or_alloc">>
        >({
            query: (body) => ({
                url: "sampling_rules",
                method: "POST",
                body,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: samplingRule } = await queryFulfilled;
                    dispatch(
                        samplingManagerApiSlice.util.updateQueryData(
                            "getAllSamplingRules",
                            { is_archived: false },
                            (draft) => {
                                Object.assign(draft, {
                                    sampling_rules: [
                                        ...draft.sampling_rules,
                                        samplingRule,
                                    ],
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        archiveSamplingRule: builder.mutation<
            unknown,
            { id: string; archive: boolean }
        >({
            query: ({ id, archive }) => ({
                url: `sampling_rules/${id}:archive`,
                method: "POST",
                body: { archive },
            }),
            async onQueryStarted(
                { id, archive },
                { dispatch, queryFulfilled, getState }
            ) {
                //get samplingRules
                const currentSamplingRules =
                    samplingManagerApiSlice.endpoints.getAllSamplingRules.select(
                        { is_archived: !archive }
                    )(getState()).data?.sampling_rules || [];

                //find current sampling rule
                const samplingRule = currentSamplingRules.find(
                    (rule) => rule.id === id
                );

                let patchResult1: PatchCollection;
                let patchResult2: PatchCollection;

                if (samplingRule) {
                    //remove it from the respective rule list
                    patchResult1 = dispatch(
                        samplingManagerApiSlice.util.updateQueryData(
                            "getAllSamplingRules",
                            { is_archived: !archive },
                            (draft) => {
                                Object.assign(draft, {
                                    sampling_rules: draft.sampling_rules.filter(
                                        (e) => e.id !== id
                                    ),
                                });
                            }
                        )
                    );

                    //add the rule to the respective rules list
                    patchResult2 = dispatch(
                        samplingManagerApiSlice.util.updateQueryData(
                            "getAllSamplingRules",
                            { is_archived: archive },
                            (draft) => {
                                Object.assign(draft, {
                                    sampling_rules: [
                                        ...draft.sampling_rules,
                                        {
                                            ...samplingRule,
                                            is_archived: archive,
                                        },
                                    ],
                                });
                            }
                        )
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult1.undo();
                        patchResult2.undo();
                    }
                }
            },
        }),
    }),
});

export const {
    useArchiveSamplingRuleMutation,
    useCreateSamplingRuleMutation,
    useGetAllSamplingRulesQuery,
    useGetSamplingRuleByIdQuery,
} = samplingManagerApiSlice;
