import { SxProps, Theme, alpha } from "@mui/material";

export const moreOptionsChildSxProp = (theme: Theme): SxProps => ({
    px: 1.5,
    py: 1.2,
    "&:hover": {
        color: "primary.main",
        bgcolor: alpha(theme.palette.primary.main, 0.2),
    },
});
