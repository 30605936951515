import useGetInfiniteRecommendationSnippetsListQuery from "../hooks/useGetInfiniteRecommendationSnippetsListQuery";
import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import { isDefined } from "@convin/utils/helper/common.helper";
import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import { ConversationsWithSnippets } from "@convin/type/customerIntelligence";
import MonologueCard from "@convin/modules/customerInteligence/components/CISnippetMonologueCard";
import { Stack } from "@mui/material";
import EmptyDataSvg from "@convin/components/svg/EmptyDataSvg";
import { Label } from "@convin/components/custom_components/Typography/Label";

const RecommendationSnippets = ({ payload }: { payload: string }) => {
    const { data, isLoading, isFetching, hasNext, fetchNext, currentData } =
        useGetInfiniteRecommendationSnippetsListQuery({
            payload,
        });

    return (
        <Stack gap={2} className="h-full">
            <Label
                colorType="333"
                variant="extraLarge"
                isEllipses
                hasTooltip
                sx={{
                    fontWeight: 600,
                    gap: 2,
                    pl: 1,
                }}
                className="flex"
            >
                Snippets - {payload}
            </Label>
            {isLoading || (isFetching && !isDefined(currentData)) ? (
                <SkeletonLoader />
            ) : isDefined(data?.results) && data?.results?.length ? (
                <VirtualList<ConversationsWithSnippets>
                    Component={MonologueCard}
                    data={data?.results}
                    hasNext={hasNext}
                    fetchNext={fetchNext}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    hasShare={false}
                    sx={{
                        p: 1,
                    }}
                />
            ) : (
                <Stack
                    className="h-full"
                    gap={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <EmptyDataSvg />
                    <Label
                        colorType="333"
                        variant="large"
                        sx={{ fontWeight: 600 }}
                    >
                        No Data
                    </Label>
                </Stack>
            )}
        </Stack>
    );
};

export default RecommendationSnippets;
