import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    CategoryListViewPaylodType,
    CategoryListViewType,
    CreateAssessmentSessionPayloadType,
    CreateAssessmentSessionReponseType,
    CreateCoachingSessionPayloadType,
} from "@convin/type/coaching";

export const coachingDashboardApiSlice = createApi({
    reducerPath: "coachingDashboardApiSlice",
    baseQuery: axiosBaseQuery(),
    // refetchOnFocus: true,
    // refetchOnMountOrArgChange: true,
    // keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getCategoryListView: builder.query<
            CategoryListViewType,
            CategoryListViewPaylodType & { next?: null | string }
        >({
            query: ({ next = null, ...payload }) => ({
                url: next || "/library/category_list_view/",
                method: "POST",
                body: payload,
            }),
            serializeQueryArgs: ({ queryArgs }) => {
                const newQueryArgs = { ...queryArgs };
                if (newQueryArgs.next) {
                    delete newQueryArgs.next;
                }
                return newQueryArgs;
            },
            merge: (currentCache, newItems) => {
                if (currentCache.results) {
                    return {
                        ...currentCache,
                        ...newItems,
                        results: [...currentCache.results, ...newItems.results],
                    };
                }
                return newItems;
            },
        }),
        createManualCoaching: builder.mutation<
            CreateCoachingSessionPayloadType,
            Partial<CreateCoachingSessionPayloadType>
        >({
            query: (payload) => ({
                url: "/coachings/create_manual_coaching/",
                method: "POST",
                body: payload,
            }),
        }),
        createManualAssessment: builder.mutation<
            CreateAssessmentSessionReponseType,
            Partial<CreateAssessmentSessionPayloadType>
        >({
            query: (payload) => ({
                url: "/coachings/assessments/create/",
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetCategoryListViewQuery,
    useLazyGetCategoryListViewQuery,
    useCreateManualCoachingMutation,
    useCreateManualAssessmentMutation,
} = coachingDashboardApiSlice;
