import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { Comment, PaginationType } from "@convin/type/Common";
import { CommentState } from "@convin/modules/components/Comments/CommentsComponent";
import { isDefined } from "@convin/utils/helper/common.helper";

type RequiredType = {
    id: number;
    dashboard_type: CommentState["dashboard_type"];
    type: CommentState["type"];
};

const getApiPath = (
    dashboard_type: RequiredType["dashboard_type"],
    type: RequiredType["type"]
): string => {
    if (type === "auditDisputeComment") {
        return `audit/${
            dashboard_type === "account" ? "account" : "meeting"
        }_score/create/dispute_comment/`;
    }
    return dashboard_type === "account"
        ? `${dashboard_type}/edit/comment/`
        : "meeting/meeting/edit/comment/";
};

export const commentApiSlice = createApi({
    reducerPath: "commentApiSlice",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getComments: builder.query<
            PaginationType<Comment>,
            RequiredType & { next?: string }
        >({
            query: ({ id, dashboard_type, next, type }) =>
                next || `/${getApiPath(dashboard_type, type)}${id}/`,
        }),
        addComment: builder.mutation<
            Comment,
            RequiredType & { payload: FormData }
        >({
            query: ({ dashboard_type, id, payload, type }) => ({
                url: `/${getApiPath(dashboard_type, type)}${id}/`,
                body: payload,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
            async onQueryStarted(
                { id, dashboard_type, type },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    const createdComment = data;
                    if (isDefined(createdComment))
                        if (isDefined(createdComment.parent))
                            dispatch(
                                commentApiSlice.util.updateQueryData(
                                    "getCommentReplies",
                                    {
                                        commentId: createdComment.parent,
                                        dashboard_type,
                                    },
                                    (draft) => {
                                        draft.count = draft.count + 1;
                                        draft.results = [
                                            createdComment,
                                            ...draft.results,
                                        ];
                                    }
                                )
                            );
                        else
                            dispatch(
                                commentApiSlice.util.updateQueryData(
                                    "getComments",
                                    { id, dashboard_type, type },
                                    (draft) => {
                                        draft.count = draft.count + 1;
                                        draft.results = [
                                            createdComment,
                                            ...draft.results,
                                        ];
                                    }
                                )
                            );
                } catch {}
            },
        }),
        editComment: builder.mutation<
            Comment,
            { commentId: number; payload: FormData } & RequiredType
        >({
            query: ({ commentId, payload }) => ({
                url: `/calendar/comment/${commentId}/`,
                body: payload,
                method: "PATCH",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
            async onQueryStarted(
                { id, dashboard_type, type },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;

                    if (!isDefined(data.parent))
                        // Checkes weather it is comment or reply
                        dispatch(
                            commentApiSlice.util.updateQueryData(
                                "getComments",
                                { id, dashboard_type, type },
                                (draft) => {
                                    if (isDefined(data)) {
                                        draft.results = draft.results.map((e) =>
                                            e.id === data.id ? data : e
                                        );
                                    }
                                }
                            )
                        );
                    else
                        dispatch(
                            commentApiSlice.util.updateQueryData(
                                "getCommentReplies",
                                { dashboard_type, commentId: data.parent },
                                (draft) => {
                                    if (isDefined(data)) {
                                        draft.results = draft.results.map((e) =>
                                            e.id === data.id ? data : e
                                        );
                                    }
                                }
                            )
                        );
                } catch {}
            },
        }),
        getCommentReplies: builder.query<
            PaginationType<Comment>,
            { commentId: number } & Pick<RequiredType, "dashboard_type"> & {
                    next?: string;
                }
        >({
            query: ({ next, commentId }) =>
                next || `/account/comment/${commentId}/replies/`,
        }),
        deleteComment: builder.mutation<
            Comment | undefined,
            { commentId: number; parent: number | null } & RequiredType
        >({
            query: ({ commentId }) => ({
                url: `/calendar/comment/${commentId}/`,
                method: "DELETE",
            }),
            async onQueryStarted(
                { id, dashboard_type, type },
                { queryFulfilled, dispatch }
            ) {
                const { data } = await queryFulfilled;
                //a comment which has replies has deleted
                if (isDefined(data))
                    if (isDefined(data.parent))
                        dispatch(
                            commentApiSlice.util.updateQueryData(
                                "getCommentReplies",
                                { dashboard_type, commentId: data.parent },
                                (draft) => {
                                    const commentToRemove = draft.results.find(
                                        (e) => e.id === data.id
                                    );
                                    if (isDefined(commentToRemove)) {
                                        draft.results = draft.results.filter(
                                            (e) => e.id !== data.id
                                        );
                                        draft.count = draft.count - 1;
                                    }
                                }
                            )
                        );
                    else
                        dispatch(
                            commentApiSlice.util.updateQueryData(
                                "getComments",
                                { dashboard_type, id, type },
                                (draft) => {
                                    const commentToRemove = draft.results.find(
                                        (e) => e.id === data.id
                                    );
                                    commentToRemove;
                                    if (isDefined(commentToRemove)) {
                                        draft.results = draft.results.filter(
                                            (e) => e.id !== data.id
                                        );
                                        draft.count = draft.count - 1;
                                    }
                                }
                            )
                        );
            },
        }),
    }),
});

export const {
    useGetCommentsQuery,
    useAddCommentMutation,
    useLazyGetCommentsQuery,
    useEditCommentMutation,
    useGetCommentRepliesQuery,
    useLazyGetCommentRepliesQuery,
    useDeleteCommentMutation,
} = commentApiSlice;
