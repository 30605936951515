import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";

export default function AddButton({ sx, className, ...rest }: BoxProps) {
    return (
        <Box
            sx={{
                background: "#000",
                color: "#fff",
                borderRadius: "50%",
                display: "flex",
                lineHeight: "8px",
                ...sx,
            }}
            className={[
                "items-center justify-center h-[40px] w-[40px]",
                className,
            ]
                .filter(Boolean)
                .join(" ")}
            component="button"
            {...rest}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <g clipPath="url(#clip0_1336_20143)">
                    <path
                        d="M15.8307 10.8307H10.8307V15.8307H9.16406V10.8307H4.16406V9.16406H9.16406V4.16406H10.8307V9.16406H15.8307V10.8307Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1336_20143">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Box>
    );
}
