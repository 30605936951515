import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import { toast } from "react-toastify";

export function showToast({ type, message, ...rest }) {
    return toast[type]?.(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        ...rest,
    });
}

export function showNoPermissionToast(payload = {}) {
    return showToast({
        ...CreateUpdateToastSettings,
        type: "error",
        message: "You do not have the permission to access this feature",
        ...payload,
    });
}
