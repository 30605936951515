// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText,
} from "@mui/material";

import { SelectProps } from "@mui/material/Select";
import { isDefined } from "@convin/utils/helper/common.helper";

// ----------------------------------------------------------------------

export default function RHFSelect<T extends string | number | null>({
    name,
    options,
    label,
    required,
    size,
    onChangeCallback,
    ...other
}: {
    name: string;
    options: { id: T; label: string }[];
    onChangeCallback?: () => void;
} & SelectProps): JSX.Element {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl className="w-full" size={size}>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            mb: 1,
                            ...(error?.message && { color: "error.main" }),
                        }}
                        required={required}
                    >
                        {label}
                    </InputLabel>

                    <Select
                        {...field}
                        {...other}
                        value={field.value ?? ""}
                        fullWidth
                        label={label}
                        error={!!error}
                    >
                        {options.map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option?.id ?? ""}
                                className="capitalize"
                                onMouseDown={() => {
                                    if (isDefined(onChangeCallback))
                                        onChangeCallback();
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {error ? (
                        <FormHelperText error>{error?.message}</FormHelperText>
                    ) : null}
                </FormControl>
            )}
        />
    );
}
