const CreateUpdateToastSettings = {
    type: "success",
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

export { CreateUpdateToastSettings };
