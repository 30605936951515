// ----------------------------------------------------------------------

import { isDarkMode } from "@convin/utils/helper/common.helper";
import { alpha } from "@mui/material";

export default function Button(theme) {
    return {
        MuiButton: {
            variants: [
                {
                    props: { variant: "global" },
                    style: {
                        textTransform: "capitalize !important",
                        fontSize: "0.875rem",
                        background: `${theme?.palette?.primary?.main} !important`,
                        color: "white",
                        fontWeight: 600,
                        "&:hover": {
                            background: `${alpha(
                                theme?.palette?.primary?.main,
                                0.4
                            )} !important`,
                        },
                        "&.Mui-disabled": {
                            background: "rgba(153, 153, 153, 0.20) !important",
                            cursor: "not-allowed !important",
                            ...(isDarkMode(theme) && {
                                color: theme.palette.common.white,
                            }),
                        },
                        ".MuiLoadingButton-loadingIndicator": {
                            color: "white",
                        },
                        height: "40px",
                    },
                },
                {
                    props: { variant: "danger" },
                    style: {
                        textTransform: "capitalize !important",
                        fontSize: "0.875rem",
                        background: `${theme?.palette?.danger} !important`,
                        color: "white",
                        fontWeight: 600,
                        "&:hover": {
                            background: `${alpha(
                                theme?.palette?.danger,
                                0.4
                            )} !important`,
                        },
                        ".Mui-disabled": {
                            background: "rgba(153, 153, 153, 0.20) !important",
                            cursor: "not-allowed",
                        },
                        ".MuiLoadingButton-loadingIndicator": {
                            color: "white",
                        },
                    },
                },
                {
                    props: { variant: "success" },
                    style: {
                        textTransform: "capitalize !important",
                        fontSize: "0.875rem",
                        background: `${theme?.palette?.auditColors?.good} !important`,
                        color: "white",
                        fontWeight: 600,
                        "&:hover": {
                            background: `${alpha(
                                theme?.palette?.auditColors?.good,
                                0.4
                            )} !important`,
                        },
                        ".Mui-disabled": {
                            background: "rgba(153, 153, 153, 0.20) !important",
                            cursor: "not-allowed",
                        },
                        ".MuiLoadingButton-loadingIndicator": {
                            color: "white",
                        },
                    },
                },
                {
                    props: { variant: "globalOutlined" },
                    style: {
                        textTransform: "capitalize !important",
                        fontSize: "0.875rem",
                        ...(theme.palette.mode !== "dark" && {
                            background: `${theme?.palette?.common?.white} !important`,
                        }),
                        border: `1px solid ${theme.palette.grey["666"]}`,
                        color: theme?.palette?.textColors["666"],
                        fontWeight: 600,
                        "&:hover": {
                            borderColor: theme?.palette?.primary?.main,
                            color: theme?.palette?.primary?.main,
                        },
                    },
                },
                {
                    props: { variant: "disabled" },
                    style: {
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                        background: `${theme?.palette?.common?.white} !important`,
                        border: `1px solid ${alpha(
                            theme?.palette?.grey[999],
                            0.2
                        )}`,

                        color:
                            theme.palette.mode === "dark"
                                ? theme.palette.common.white
                                : theme.palette.textColors[999],
                        fontWeight: 600,
                        cursor: "not-allowed",
                    },
                    height: "40px",
                },
            ],
        },
        // MuiButtonBase: {
        //   defaultProps: {
        //     // The props to change the default for.
        //     // No more ripple, on the whole application 💣!
        //   },
        // },
    };
}
