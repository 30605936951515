import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function PredefinedFuncSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <mask
                id="mask0_1174_34068"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1174_34068)">
                <path
                    d="M6.66406 15L1.66406 10L6.66406 5L7.85156 6.1875L4.01823 10.0208L7.83073 13.8333L6.66406 15ZM13.3307 15L12.1432 13.8125L15.9766 9.97917L12.1641 6.16667L13.3307 5L18.3307 10L13.3307 15Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
