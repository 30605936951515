import { useCallback, useState } from "react";

export default function useAnchorEl<T>() {
    const [anchorEl, setAnchorEl] = useState<T | null>(null);

    const handleClick = useCallback((event: React.MouseEvent<T>): void => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback((): void => {
        setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);

    return {
        handleClose,
        handleClick,
        anchorEl,
        open,
    };
}
