// ----------------------------------------------------------------------

import { alpha } from "@mui/material";

export default function InputBase(theme) {
    return {
        MuiInputBase: {
            styleOverrides: {
                inputAdornedEnd: {
                    flex: 1,
                },
                root: {
                    "& .MuiChip-deleteIcon": {
                        fill: theme.palette.primary.main,
                    },
                    "&.MuiInput-underline": {
                        "::before": {
                            borderColor: alpha(
                                theme.palette.textColors[999],
                                0.2
                            ),
                        },
                    },
                },
            },
        },
    };
}
