const routes = {
    HOME: "/",
    SIGNIN: "/signin",
    SIGNUP: "/signup",
    SETUP: "/account-setup",
    CALLS: "/calls",
    CALL: "/call",
    CHAT: "/chat",
    EMAIL: "/email",
    SEARCH: "/search",
    COACHING: "/home/coaching",
    STATISTICS: "/statistics",
    SETTINGS: "/settings",
    PREVIEW: "/share",
    FORGET_PASSWORD: "/forgetpassword",
    RESET_PASSWORD: "/resetpassword",
    HELP: "/help",
    CI_DASHBOARD: "/ci_dashboard",
    AUDIT_REPORT: "/audit_report",
    AI_AUDIT_DASHBOARD: "/home",
    SUCCESS_MAIL: "/success_sent",
    CREATING_ORG: "/cofu",
    ACCOUNTS: "/accounts",
    CHANGE_PASSWORD: "/changepassword",
    VERIFY_CODE: "/verify_otp",
    LIBRARY: {
        resources: "/lms/knowledge_base/",
        folder: "/library/resources/:folder",
        assessment: "/lms/assesments",
    },
    GPT: "/gpt/chat",
    settings: {
        user_profile: "/settings/user_profile",
        user_manager: "/settings/user_manager",
        topic_manager: "/settings/topic_manager",
        integrations: "/settings/integrations",
        team_manager: "/settings/team_manager",
        feedback_manager: "/settings/feedback_manager",
        recording_manager: "/settings/recording_manager",
        voice_print_dashboard: "/settings/recording_manager/voice_print",
        call_type_manager: "/settings/calls_type_manager",
        call_tags_manager: "/settings/calls_tags_manager",
        audit_manager: "/settings/audit_manager",
        calibration:
            "/settings/audit_manager/filters/:id/calibration/:calibrationId",
        billing: "/settings/billing",
        sch_reports: "/settings/scheduled_reports",
        violation_manager: "/settings/violation_manager",
        moments_settings: "/settings/moments_settings",
        role_manager: "/settings/role_manager",
        sampling_manager: "/settings/sampling_manager",
        ci: "/settings/ci",
        live_assist: "/settings/live_assist",
        battle_cards: "/settings/live_assist/battle_cards",
        knowledge_base: "/settings/live_assist/knowledge_base",
        notes: "/settings/notes",
        qms: "/settings/qms",
        recommendations: "/settings/ai_recommendations",
    },
};

export default routes;
