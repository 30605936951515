import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function ConditionSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <mask
                id="mask0_1185_42937"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1185_42937)">
                <path
                    d="M11.6641 16.6693V15.0026H14.1641C14.4002 15.0026 14.5981 14.9227 14.7578 14.763C14.9175 14.6033 14.9974 14.4054 14.9974 14.1693V12.5026C14.9974 11.9748 15.1502 11.4957 15.4557 11.0651C15.7613 10.6345 16.1641 10.329 16.6641 10.1484V9.85677C16.1641 9.67622 15.7613 9.37066 15.4557 8.9401C15.1502 8.50955 14.9974 8.03038 14.9974 7.5026V5.83594C14.9974 5.59983 14.9175 5.40191 14.7578 5.24219C14.5981 5.08247 14.4002 5.0026 14.1641 5.0026H11.6641V3.33594H14.1641C14.8585 3.33594 15.4488 3.57899 15.9349 4.0651C16.421 4.55122 16.6641 5.14149 16.6641 5.83594V7.5026C16.6641 7.73872 16.7439 7.93663 16.9036 8.09635C17.0634 8.25608 17.2613 8.33594 17.4974 8.33594H18.3307V11.6693H17.4974C17.2613 11.6693 17.0634 11.7491 16.9036 11.9089C16.7439 12.0686 16.6641 12.2665 16.6641 12.5026V14.1693C16.6641 14.8637 16.421 15.454 15.9349 15.9401C15.4488 16.4262 14.8585 16.6693 14.1641 16.6693H11.6641ZM5.83073 16.6693C5.13628 16.6693 4.54601 16.4262 4.0599 15.9401C3.57378 15.454 3.33073 14.8637 3.33073 14.1693V12.5026C3.33073 12.2665 3.25087 12.0686 3.09115 11.9089C2.93142 11.7491 2.73351 11.6693 2.4974 11.6693H1.66406V8.33594H2.4974C2.73351 8.33594 2.93142 8.25608 3.09115 8.09635C3.25087 7.93663 3.33073 7.73872 3.33073 7.5026V5.83594C3.33073 5.14149 3.57378 4.55122 4.0599 4.0651C4.54601 3.57899 5.13628 3.33594 5.83073 3.33594H8.33073V5.0026H5.83073C5.59462 5.0026 5.3967 5.08247 5.23698 5.24219C5.07726 5.40191 4.9974 5.59983 4.9974 5.83594V7.5026C4.9974 8.03038 4.84462 8.50955 4.53906 8.9401C4.23351 9.37066 3.83073 9.67622 3.33073 9.85677V10.1484C3.83073 10.329 4.23351 10.6345 4.53906 11.0651C4.84462 11.4957 4.9974 11.9748 4.9974 12.5026V14.1693C4.9974 14.4054 5.07726 14.6033 5.23698 14.763C5.3967 14.9227 5.59462 15.0026 5.83073 15.0026H8.33073V16.6693H5.83073Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
