import React, {
    PropsWithChildren,
    ReactElement,
    useReducer,
    useState,
} from "react";
import {
    IBlockState,
    NodeStateContext,
    NodeStateDispatchAction,
    NodeStateProviderProps,
} from "./NodeStateContext";

const intialState: IBlockState = {
    buildingBlockType: null,
    selectedBlockType: null,
};

function Reducer(state: IBlockState, action: NodeStateDispatchAction) {
    const { type, payload } = action;
    switch (type) {
        case "UPDATE":
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}

export default function NodeStatePorvider({
    children,
    ...rest
}: PropsWithChildren<NodeStateProviderProps>): ReactElement {
    const [state, dispatch] = useReducer(Reducer, intialState);
    const [createBlock, setCreateBlock] = useState<boolean>(false);
    return (
        <NodeStateContext.Provider
            value={{
                state,
                dispatch,
                createBlock,
                setCreateBlock,
                ...rest,
            }}
        >
            {children}
        </NodeStateContext.Provider>
    );
}
