import CustomModal from "@convin/components/custom_components/CustomModal";
import { Box, Button, Stack, Typography, alpha } from "@mui/material";
import AIFeatureStickSvg from "../../svg/AIFeatureStickSvg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TableSearchInput } from "@convin/components/custom_components/Input/styles";
import { Search } from "@mui/icons-material";
import { useLazyGetRecommendationsQuery } from "@convin/redux/services/settings/recommendation.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { AIRecommendationQueryResponse } from "@convin/type/Recommendation.model";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import NoDataSvg from "@convin/components/svg/NoDataSvg";
import RecommendationSnippets from "@convin/modules/settings/modules/recommendations/components/RecommendationSnippets";
import SkeletonLoader from "@convin/components/custom_components/Loader/SkeletonLoader";
import { MomentBlockFormType } from "../../../types/bert";
import { useFieldArray, useFormContext } from "react-hook-form";
import { nanoid } from "@reduxjs/toolkit";

const getTableData: (
    e: AIRecommendationQueryResponse["data"]
) => Array<{ phrase: string; id: string; count: number; action: string }> = (
    data
) => {
    return data.map((e) => ({
        id: e.phrase,
        phrase: e.phrase,
        action: e.phrase,
        count: e.count,
    }));
};

export const AIRecommendationModaUI: React.FC<{
    phrase?: string;
    handleClose: () => void;
}> = ({ phrase, handleClose }) => {
    const { control } = useFormContext<MomentBlockFormType>();

    const { append } = useFieldArray({
        control,
        name: "phrase",
    });

    const [searchPhrase, setSearchPhrase] = useState<string>(phrase ?? "");
    const [activePhrase, setActivePhrase] = useState<string | null>(null);

    const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);

    const [getRecommendations, { data: recommendations, isFetching }] =
        useLazyGetRecommendationsQuery();

    const columns: GridColDef<ReturnType<typeof getTableData>[number]>[] =
        useMemo(() => {
            return [
                {
                    field: "phrase",
                    headerName: "Phrases",
                    minWidth: 235,
                    renderCell: (params) => {
                        return (
                            <Typography
                                component="span"
                                className="font-semibold"
                                variant="medium"
                                color={"textColors.333"}
                            >
                                {params.row.phrase}
                            </Typography>
                        );
                    },
                },
                {
                    field: "count",
                    headerName: "Calls",
                    minWidth: 165,
                    renderCell: (params) => {
                        return (
                            <Typography
                                component="span"
                                variant="medium"
                                color={"textColors.666"}
                            >
                                {params.row.count}
                            </Typography>
                        );
                    },
                },
                {
                    field: "action",
                    headerName: "Snippets",
                    minWidth: 60,
                    renderCell: (params) => {
                        const onClick = (
                            e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                        ) => {
                            e.stopPropagation();
                            const currentRow = params.row;
                            setActivePhrase(currentRow.phrase);
                        };

                        return (
                            <Box
                                component="span"
                                sx={{ color: "#1A62F2", cursor: "pointer" }}
                                onClick={onClick}
                            >
                                View
                            </Box>
                        );
                    },
                },
            ];
        }, []);

    const rows = getTableData(recommendations?.data ?? []);

    const handleSearch = () => {
        if (searchPhrase.length) {
            setActivePhrase(null), getRecommendations(searchPhrase);
        }
    };

    useEffect(() => {
        if (searchPhrase) getRecommendations(searchPhrase);
    }, []);

    return (
        <CustomModal
            open={true}
            handleClose={handleClose}
            header={
                <Box className="flex items-center" gap={1}>
                    <AIFeatureStickSvg /> <span>AI Recommendation</span>
                </Box>
            }
            dialogContentSx={{
                width: "1172px",
            }}
            footer={
                <>
                    <Button
                        onClick={handleClose}
                        variant="globalOutlined"
                        className="w-[130px]"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            for (let i = 0; i < selectedIds.length; i++) {
                                append({
                                    id: nanoid(),
                                    phrase: selectedIds[i] as string,
                                });
                            }
                            handleClose();
                        }}
                        variant="global"
                        className="w-[130px] nodrag nopan"
                    >
                        Add Phrases
                    </Button>
                </>
            }
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "12px",
                    height: "752px",
                },
            }}
        >
            <Box
                className="flex h-[100%] overflow-hidden"
                sx={{
                    position: "relative",
                    "&::after": {
                        content: "''",
                        position: "absolute",
                        height: "100%",
                        width: "1px",
                        bgcolor: "divider",
                        left: "50%",
                    },
                }}
            >
                <Box className="flex-1 flex flex-col overflow-hidden" py={2}>
                    <Box
                        className="relative flex-shrink-0"
                        sx={{
                            mb: 1,
                        }}
                    >
                        <TableSearchInput
                            value={searchPhrase}
                            onChange={(e) => {
                                setSearchPhrase(e.target.value ?? "");
                            }}
                            variant="outlined"
                            label=""
                            placeholder="Search for phrases"
                            InputProps={{
                                startAdornment: (
                                    <Search
                                        sx={{
                                            width: "18px",
                                            height: "18px",
                                            color: "textColors.666",
                                        }}
                                    />
                                ),
                            }}
                            inputProps={{
                                autoFocus: true,
                            }}
                            className="rounded-md h-12 w-full *:!h-[inherit]"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                        />
                        <Button
                            className="absolute top-1/2 -translate-y-1/2 right-4"
                            sx={{
                                color: "common.white",
                                bgcolor: "grey.333",
                                "&:hover": {
                                    color: "common.white",
                                    bgcolor: "grey.666",
                                },
                                px: 2,
                            }}
                            onClick={handleSearch}
                        >
                            Generate
                        </Button>
                    </Box>
                    <Box className="flex-1 overflow-hidden">
                        {isFetching ? (
                            <SkeletonLoader />
                        ) : (
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                checkboxSelection
                                hideFooter
                                disableColumnMenu
                                disableColumnFilter
                                disableColumnSelector
                                selectionModel={selectedIds}
                                headerHeight={50}
                                sx={(theme) => ({
                                    overflow: "hidden",
                                    border: "1px solid",
                                    borderColor: "divider",
                                    borderRadius: "12px",

                                    "&>.MuiDataGrid-main": {
                                        "&>.MuiDataGrid-columnHeaders": {
                                            borderBottom: "none",
                                        },

                                        "& div div div div >.MuiDataGrid-cell":
                                            {
                                                borderBottom: "none",
                                            },
                                    },

                                    "& .MuiDataGrid-columnHeaders": {
                                        bgcolor: alpha(
                                            theme.palette.textColors["333"],
                                            0.1
                                        ),
                                    },
                                    "& .MuiDataGrid-columnSeparator svg": {
                                        display: "none",
                                    },
                                    "& .MuiDataGrid-cell:focus": {
                                        outline: "none",
                                    },
                                    "& .MuiDataGrid-columnHeader:focus-within":
                                        {
                                            outline: "none",
                                        },
                                    "& .MuiDataGrid-cell:focus-within": {
                                        outline: "none",
                                    },
                                })}
                                onSelectionModelChange={(
                                    newRowSelectionModel: GridRowId[]
                                ) => {
                                    setSelectedIds(newRowSelectionModel);
                                }}
                                className="h-full"
                            />
                        )}
                    </Box>
                </Box>

                <Box
                    className="flex-1 overflow-y-auto "
                    sx={{
                        pl: 3,
                        py: 2,
                    }}
                >
                    {!isDefined(activePhrase) ? (
                        <Stack
                            gap={1}
                            alignItems="center"
                            direction="column"
                            justifyContent="center"
                            height="100%"
                        >
                            <NoDataSvg />
                            <Typography
                                variant="large"
                                className="font-semibold"
                            >
                                Select phrase to view snippets
                            </Typography>
                        </Stack>
                    ) : (
                        <RecommendationSnippets payload={activePhrase} />
                    )}
                </Box>
            </Box>
        </CustomModal>
    );
};

const AIRecommendationModal: React.FC<{ phrase?: string }> = ({ phrase }) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    return (
        <>
            <Button
                variant="text"
                onClick={() => {
                    if (phrase?.length) setOpen(true);
                }}
            >
                <AIFeatureStickSvg />
            </Button>
            {open && <AIRecommendationModaUI {...{ phrase, handleClose }} />}
        </>
    );
};

export default AIRecommendationModal;
