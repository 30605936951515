import { NotificationHistory } from "@convin/type/WebSocketNotification";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getDomain } from "tools/helpers";

export const notificationApiSlice = createApi({
    reducerPath: "notificationApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: `https://${
            import.meta.env.VITE_APP_GPT || "gpt.convin.ai"
        }/${getDomain(window.location.host)}/api/v1`,
    }),
    endpoints: (builder) => ({
        getPrivateHistory: builder.query<
            {
                notifications: NotificationHistory["messages"];
                lastUpdated: number | null;
            },
            void
        >({
            queryFn: () => {
                return {
                    data: {
                        notifications: [],
                        lastUpdated: null,
                    },
                };
            },
        }),
        getGeneralHistory: builder.query<
            {
                notifications: NotificationHistory["messages"];
                lastUpdated: number | null;
            },
            void
        >({
            queryFn: () => {
                return {
                    data: {
                        notifications: [],
                        lastUpdated: null,
                    },
                };
            },
        }),
        generateSummary: builder.mutation<
            void,
            {
                meeting_id: number;
                meeting_title: string;
                transcript_path: string;
                channel: string;
            }
        >({
            query: (payload) => ({
                url: "/summary/",
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

export const { useGetPrivateHistoryQuery, useGenerateSummaryMutation } =
    notificationApiSlice;
