import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AssessmentSubmissionRequest,
    AssessmentSubmissionResponse,
    CoachingSession,
} from "@convin/type/Coaching.model";
import { CoachingProgressPayloadType } from "@convin/type/coaching";

export const coachingApiSlice = createApi({
    reducerPath: "coachingApiSlice",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 0,
    tagTypes: ["Session", "Response"],
    endpoints: (builder) => ({
        getCoachingSession: builder.query<
            CoachingSession,
            { id: string; payload: { reps_id?: number[] } }
        >({
            query: ({ id, payload }) => ({
                url: `/coachings/coaching_session/${id}/`,
                method: "POST",
                body: payload,
            }),
            providesTags: ["Session"],
        }),
        submitCoachingAssessment: builder.mutation<
            { message: string },
            AssessmentSubmissionRequest
        >({
            query: (payload) => ({
                url: "/coachings/response/create/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(
                { submission_id },
                { dispatch, queryFulfilled }
            ) {
                await queryFulfilled;
                dispatch(
                    coachingApiSlice.util.updateQueryData(
                        "getCoachingSession",
                        { id: submission_id, payload: {} },
                        (draft) => ({
                            ...draft,
                            assessment: {
                                ...draft.assessment,
                                status: "Completed",
                            },
                        })
                    )
                );
            },
        }),
        getCoachingAssessmentDetails: builder.query<
            AssessmentSubmissionResponse,
            Pick<
                AssessmentSubmissionRequest,
                "form_id" | "rep_id" | "submission_id"
            >
        >({
            query: (payload) => ({
                url: "/coachings/form_and_response/",
                method: "POST",
                body: payload,
            }),
        }),
        publishCoachingAssessment: builder.mutation<
            void,
            {
                session_id: number;
                is_published_key: boolean;
            }
        >({
            query: (payload) => ({
                url: "/coachings/coaching_session/update/",
                method: "POST",
                body: payload,
            }),
        }),
        getCoachingProgress: builder.mutation<
            CoachingProgressPayloadType,
            Array<{ epoch: number; percentage: number; trend: number }>
        >({
            query: (payload) => ({
                url: "/coachings/completion_graph/",
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetCoachingSessionQuery,
    useGetCoachingAssessmentDetailsQuery,
    useSubmitCoachingAssessmentMutation,
    usePublishCoachingAssessmentMutation,
    useGetCoachingProgressMutation,
} = coachingApiSlice;
