import { SvgIcon, SxProps, Theme, useTheme } from "@mui/material";
import { ReactElement } from "react";

export default function NotOperatorLegendSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    const theme = useTheme();
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <rect
                width="24"
                height="24"
                rx="6"
                fill={theme.palette.ruleEngineColors.not}
            />
            <g>
                <path
                    d="M12.0013 6.16406C8.7813 6.16406 6.16797 8.7774 6.16797 11.9974C6.16797 15.2174 8.7813 17.8307 12.0013 17.8307C15.2213 17.8307 17.8346 15.2174 17.8346 11.9974C17.8346 8.7774 15.2213 6.16406 12.0013 6.16406ZM12.0013 16.6641C9.42297 16.6641 7.33464 14.5757 7.33464 11.9974C7.33464 10.9182 7.70214 9.92656 8.32047 9.13906L14.8596 15.6782C14.0721 16.2966 13.0805 16.6641 12.0013 16.6641ZM15.6821 14.8557L9.14297 8.31656C9.93047 7.69823 10.9221 7.33073 12.0013 7.33073C14.5796 7.33073 16.668 9.41906 16.668 11.9974C16.668 13.0766 16.3005 14.0682 15.6821 14.8557Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath>
                    <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(5 5)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
