import { Box, Typography } from "@mui/material";
import { CloseSvg } from "@convin/components/svg";
import AIRecommendationModal from "./AiRecommendation/AIRecommendationModal";

// ----------------------------------------------------------------------

export default function MomentPhraseCard({
    name = "",
    onClick,
}: {
    name: string;
    onClick: () => void;
}) {
    return (
        <Box
            sx={{
                px: 1.5,
                py: 1,
                mx: 1.5,
                my: 1,
                background: "rgba(153, 153, 153, 0.10)",
                borderRadius: "6px",
            }}
            className="flex items-center justify-between"
        >
            <Typography variant="small">{name}</Typography>
            <Box className="flex items-center">
                <AIRecommendationModal phrase={name} />
                <CloseSvg onClick={onClick} />
            </Box>
        </Box>
    );
}
