import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { ApiResponseType } from "@convin/type/Common";
import {
    Batch,
    CampaignCreatePayload,
    CampaignData,
    Preset,
    Voice,
} from "@convin/type/aiVoiceCall";
import { toast } from "react-toastify";

export const aiVoiceCallSlice = createApi({
    reducerPath: "aiVoiceCall",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getCampaignList: builder.query<
            ApiResponseType<Batch[], "batches">,
            void
        >({
            query: () => ({
                url: "live_assist/campaign/",
                method: "GET",
            }),
        }),
        getCampaignDetails: builder.query<CampaignData, string>({
            query: (id) => ({
                url: `live_assist/campaign/${id}/`,
                method: "GET",
            }),
        }),
        stopCampaign: builder.mutation<CampaignData, string>({
            query: (id) => ({
                url: `live_assist/campaign/${id}/`,
                method: "PATCH",
            }),
        }),
        createCampaign: builder.mutation<
            { batch_id: string },
            CampaignCreatePayload
        >({
            query: (payload) => ({
                url: "live_assist/campaign/",
                method: "POST",
                body: { ...payload, record: true },
            }),
        }),
        getVoicesList: builder.query<
            {
                voices: Voice[];
                presets: Preset[];
            },
            void
        >({
            query: () => ({
                url: "live_assist/voices/",
                method: "GET",
            }),
        }),
        downloadRecording: builder.mutation<unknown, string>({
            query: (id) => ({
                url: `live_assist/recording/${id}/`,
                method: "GET",
                responseType: "blob",
            }),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            async onQueryStarted(_, { queryFulfilled }) {
                const id = toast.loading(
                    "Please wait...Dowloading your recording",
                    { theme: "dark" }
                );
                const { data } = await queryFulfilled;

                const a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                // Log size of the binary js string
                toast.update(id, {
                    render: "Recording Dowloaded",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                });

                const blob = new Blob([data as string], { type: "audio/mpeg" });
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "recording";
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            },
        }),
    }),
});

export const {
    useGetCampaignListQuery,
    useGetVoicesListQuery,
    useGetCampaignDetailsQuery,
    useCreateCampaignMutation,
    useStopCampaignMutation,
    useDownloadRecordingMutation,
} = aiVoiceCallSlice;
