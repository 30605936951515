export const SETLOADER = "SETLOADER";
export const SETDOMAIN = "SETDOMAIN";
export const STORE_TAGS = "STORE_TAGS";
export const STORE_CALL_TYPES = "STORE_CALL_TYPES";
export const STORE_CALL_DURATION = "STORE_CALL_DURATION";
export const STORE_ALL_CLIENTS = "STORE_CALL_CLIENTS";
export const STORE_ALL_TOPICS = "STORE_ALL_TOPICS";
export const STORE_SEARCH_FIELDS = "STORE_SEARCH_FIELDS";
export const STORE_ALL_USERS = "STORE_ALL_USERS";
export const STORE_TEAMS = "STORE_TEAMS";
export const STORE_SALES_TASKS = "STORE_SALES_TASKS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SET_FILTER_TEAMS = "SET_FILTER_TEAMS";
export const SET_FILTER_REPS = "SET_FILTER_REPS";
export const SET_ACTIVE_TEAM = "SET_ACTIVE_TEAM";
export const SET_ACTIVE_REP = "SET_ACTIVE_REP";
export const SET_FILTER_CALL_TYPES = "SET_FILTER_CALL_TYPES";
export const SET_ACTIVE_CALL_TYPE = "SET_ACTIVE_CALL_TYPE";
export const SET_ACTIVE_CALL_DURATION = "SET_ACTIVE_CALL_DURATION";
export const SET_FILTER_CALL_DURATION = "SET_FILTER_CALL_DURATION";
export const SET_ACTIVE_FILTER_DATE = "SET_ACTIVE_FILTER_DATE";
export const SET_FILTER_DATES = "SET_FILTER_DATES";
export const SET_GS_TEXT = "SET_GS_TEXT";
export const SALES_TASK_QUERY_LOADING = "SALES_TASK_QUERY_LOADING";
export const SALES_TASK_NEXT_LOADING = "SALES_TASK_NEXT_LOADING";
export const SET_FILTER_AUDIT_TEMPLATES = "SET_FILTER_AUDIT_TEMPLATES";
export const SET_ACTIVE_TEMPLATE = "SET_ACTIVE_TEMPLATE";
export const SET_DID_FILTER_CHANGE = "SET_DID_FILTER_CHANGE";
export const STORE_VERSION_DATA = "STORE_VERSION_DATA";
export const SET_ACTIVE_REPORT_TYPE = "SET_ACTIVE_REPORT_TYPE";
export const SET_FETCHING_REPS = "SET_FETCHING_REPS";
export const SET_ACTIVE_CALLTAG = "SET_ACTIVE_CALLTAG";
