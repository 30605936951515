export const FETCH_COACHING_SESSION_REQUEST = "FETCH_COACHING_SESSION_REQUEST";
export const FETCH_COACHING_SESSION_SUCCESS = "FETCH_COACHING_SESSION_SUCCESS";
export const FETCH_COACHING_SESSION_FALIURE = "FETCH_COACHING_SESSION_FALIURE";
export const FETCH_ONE_SESSION_REQUEST = "FETCH_ONE_SESSION_REQUEST";
export const FETCH_ONE_SESSION_SUCCESS = "FETCH_ONE_SESSION_SUCCESS";
export const FETCH_ONE_SESSION_FALIURE = "FETCH_ONE_SESSION_FALIURE";
export const FETCH_MODULE_REQUEST = "FETCH_MODULE_REQUEST";
export const FETCH_MODULE_SUCCESS = "FETCH_MODULE_SUCCESS";
export const FETCH_MODULE_FALIURE = "FETCH_MODULE_FALIURE";
export const FETCH_CLIP_REQUEST = "FETCH_CLIP_REQUEST";
export const FETCH_CLIP_SUCCESS = "FETCH_CLIP_SUCCESS";
export const FETCH_CLIP_FALIURE = "FETCH_CLIP_FALIURE";
