import React from "react";
import "../reusables/spinner.style.scss";
import { Box } from "@mui/material";

export default function FallbackUI() {
    return (
        <Box className="fallback" color="primary.main">
            {/* <i className="fa fa-spinner fa-pulse fa-4x fa-fw"></i> */}
            <div className="loader">...Loading</div>
        </Box>
    );
}
