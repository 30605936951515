import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function GroupBlockNodeSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <rect
                x="1"
                y="1"
                width="30"
                height="30"
                rx="6"
                fill="#F26EA6"
                stroke="#EA62B4"
            />
            <mask
                id="mask0_1425_10347"
                maskUnits="userSpaceOnUse"
                x="6"
                y="6"
                width="20"
                height="20"
            >
                <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1425_10347)">
                <path
                    d="M15.9661 22.7373L9.26562 17.5258L10.494 16.5952L15.9661 20.8388L21.4381 16.5952L22.6665 17.5258L15.9661 22.7373ZM15.9661 18.9776L9.26562 13.7661L15.9661 8.55469L22.6665 13.7661L15.9661 18.9776Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}
