// ----------------------------------------------------------------------

export default function Slider(theme) {
    return {
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    background: theme?.palette?.common?.white,
                    border: "1px solid",
                    borderColor: theme?.palette?.primary?.main,
                },
            },
        },
    };
}
