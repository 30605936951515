import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    BattleCard,
    BattleCardCategory,
    BattleCardPayload,
} from "@convin/type/LiveAssist";

export const liveAssistApiSlice = createApi({
    reducerPath: "liveAssistApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["Battle Card", "Categories"],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getBattleCards: builder.query<BattleCard[], void>({
            query: () => "live_assist/battle_cards/",
            providesTags: ["Battle Card"],
        }),
        getBattleCardById: builder.query<BattleCard, number>({
            query: (id) => `live_assist/battle_card/${id}/`,
        }),
        createBattleCard: builder.mutation<BattleCard, BattleCardPayload>({
            query: ({ ...payload }) => ({
                url: "live_assist/battle_card/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, err) => (!!err ? [] : ["Battle Card"]),
        }),
        updateBattleCard: builder.mutation<
            BattleCard,
            Partial<BattleCardPayload> & Pick<BattleCard, "id">
        >({
            query: ({ id, ...payload }) => ({
                url: `live_assist/battle_card/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            invalidatesTags: (_, err) => (!!err ? [] : ["Battle Card"]),
            async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        liveAssistApiSlice.util.updateQueryData(
                            "getBattleCardById",
                            id,
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                } catch {
                    return;
                }
            },
        }),
        deleteBattleCard: builder.mutation<void, number>({
            query: (id) => ({
                url: `live_assist/battle_card/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (!!err ? [] : ["Battle Card"]),
        }),
        getCategories: builder.query<BattleCardCategory[], void>({
            query: () => "live_assist/categories/",
            providesTags: ["Categories"],
        }),
        createCategory: builder.mutation<
            BattleCardCategory,
            Omit<BattleCardCategory, "id" | "created_at" | "updated_at">
        >({
            query: ({ ...payload }) => ({
                url: "live_assist/categories/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, err) => (!!err ? [] : ["Categories"]),
        }),
    }),
});

export const {
    useGetBattleCardsQuery,
    useGetBattleCardByIdQuery,
    useCreateBattleCardMutation,
    useUpdateBattleCardMutation,
    useDeleteBattleCardMutation,
    useGetCategoriesQuery,
    useCreateCategoryMutation,
} = liveAssistApiSlice;
