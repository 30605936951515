import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function SingleBlockNodeSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 26 26"
            sx={{ width: 26, height: 26, fill: "none", ...sx }}
        >
            <rect
                x="1"
                y="1"
                width="24"
                height="24"
                rx="6"
                fill="#F9A641"
                stroke="#F9A641"
            />
            <mask
                id="mask0_1256_8112"
                maskUnits="userSpaceOnUse"
                x="5"
                y="5"
                width="17"
                height="17"
            >
                <rect
                    x="5.26562"
                    y="5.27344"
                    width="16"
                    height="16"
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_1256_8112)">
                <path
                    d="M13.1975 18.7388L7.66406 13.2053L13.1975 7.67188L18.731 13.2053L13.1975 18.7388Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}
