import React, { Dispatch, FC } from "react";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Box,
    Typography,
    Stack,
} from "@mui/material";
import InfoSvg from "@convin/components/svg/InfoSvg";
import { LoadingButton } from "@mui/lab";

interface DeleteDialogProps {
    open: boolean;
    setOpen: Dispatch<React.SetStateAction<boolean>>;
    onDelete: () => void;
    title: string;
    message: string;
    children?: React.ReactNode;
    isLoading?: boolean;
    okText?: string;
    isArchive?: boolean;
}

const DeleteDialog: FC<DeleteDialogProps> = ({
    open,
    setOpen,
    onDelete,
    title,
    message,
    children = null,
    isLoading,
    okText = "Delete",
    isArchive,
}) => {
    const handleDelete = () => {
        onDelete();
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-paper": {
                    width: "468px",
                    borderRadius: "12px",
                },
            }}
            aria-label="delete-dialog"
        >
            <Stack className="items-center">
                <DialogTitle
                    sx={{ p: 0, pt: 4, pb: 1, color: "textColors.333" }}
                >
                    {title}
                </DialogTitle>
                {children}
                <Box sx={{ p: 0 }} className="flex items-center">
                    <Box
                        component={"span"}
                        sx={{
                            color: "danger",
                            transform: "scale(1.2)",
                            mr: 1,
                        }}
                    >
                        <InfoSvg />
                    </Box>
                    <Typography
                        variant="medium"
                        sx={{
                            color: isArchive ? "grey.333" : "danger",
                        }}
                    >
                        {message}
                    </Typography>
                </Box>
            </Stack>
            <DialogActions
                className="flex items-center justify-center"
                sx={{
                    gap: 2.5,
                    pb: 4,
                    pt: 2,
                    "*": {
                        boxSizing: "border-box",
                    },
                }}
            >
                <Button
                    className="w-[124px] h-[40px]"
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                    }}
                    variant="globalOutlined"
                >
                    Cancel
                </Button>
                <LoadingButton
                    className="w-[124px] h-[40px] font-semibold text-base"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDelete();
                    }}
                    variant={isArchive ? "global" : "danger"}
                    loading={isLoading}
                >
                    {okText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
