import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { PaginationType } from "@convin/type/Common";
import { SearchFilter } from "@convin/type/Search";
import {
    Alert,
    AlertPayload,
    ConversationItem,
    ConversationSearchPayload,
    ConversationView,
    ShareMettingsPayload,
    ShareMettingsResponse,
    UpcomingConveration,
    UpcomingConversationFilters,
} from "@convin/type/Conversation";
import { DownloadReport } from "@convin/type/Report";
import { updateDownloadQue } from "@convin/utils/helper/common.helper";

export const meetingsApiSlice = createApi({
    reducerPath: "meetingsApiSlice",
    baseQuery: axiosBaseQuery({ transformResponse: (response) => response }),
    endpoints: (builder) => ({
        getMeetings: builder.query<
            PaginationType<ConversationItem>,
            ConversationSearchPayload
        >({
            query: ({ next, filters, method = "POST", order_by }) => ({
                url: next || "search/search/",
                method,
                body: [...filters],
                params: { order_by },
            }),
        }),
        updateMeeting: builder.mutation<
            ConversationItem,
            { call_id: number; filters: SearchFilter[] } & Partial<{
                call_types: Array<number>;
                tags: Array<number>;
                title: string;
            }>
        >({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            query: ({ filters: _, ...payload }) => ({
                url: `/meeting/meeting/update/${payload.call_id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(
                { call_id, filters },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        meetingsApiSlice.util.updateQueryData(
                            "getMeetings",
                            { filters },
                            (draft) => {
                                Object.assign(draft, {
                                    results: [
                                        ...draft.results.map((e) =>
                                            e.id === call_id ? data : e
                                        ),
                                    ],
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        deleteMeeting: builder.mutation<
            void,
            { id: number; filters: SearchFilter[] }
        >({
            query: ({ id }) => ({
                url: `/meeting/meeting/update/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(
                { id, filters },
                { dispatch, queryFulfilled }
            ) {
                try {
                    await queryFulfilled;
                    dispatch(
                        meetingsApiSlice.util.updateQueryData(
                            "getMeetings",
                            { filters },
                            (draft) => {
                                Object.assign(draft, {
                                    results: [
                                        ...draft.results.filter(
                                            (e) => e.id !== id
                                        ),
                                    ],
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        createView: builder.mutation<
            ConversationView,
            Omit<ConversationView, "id" | "owner">
        >({
            query: (payload) => ({
                url: "/meeting/meeting-filter/create/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    const isDefault = data.is_default;
                    dispatch(
                        meetingsApiSlice.util.updateQueryData(
                            "getListView",
                            undefined,
                            (draft) => {
                                Object.assign(draft, [
                                    ...draft.map((e) =>
                                        isDefault
                                            ? { ...e, is_default: false }
                                            : e
                                    ),
                                    data,
                                ]);
                            }
                        )
                    );
                } catch {}
            },
        }),
        getDefaultView: builder.query<
            ConversationView | Record<"id", undefined>,
            void
        >({
            query: () => "/meeting/meeting-filter/default/",
        }),
        getListView: builder.query<Array<ConversationView>, void>({
            query: () => "/meeting/meeting-filter/list/",
        }),
        deleteView: builder.mutation<ConversationView, number>({
            query: (id) => ({
                url: `/meeting/meeting-filter/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        meetingsApiSlice.util.updateQueryData(
                            "getListView",
                            undefined,
                            (draft) => draft.filter((e) => e.id !== id)
                        )
                    );
                } catch {}
            },
        }),

        downloadMeetingDump: builder.mutation<
            DownloadReport,
            {
                filters: SearchFilter[];
                conversation_score?: boolean;
            }
        >({
            query: (payload) => ({
                url: "/meeting/meeting/dump/download",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        addBot: builder.mutation<
            boolean,
            { clients: string[]; title: string; uri: string }
        >({
            query: (payload) => ({
                url: "/calendar/calendar/join/uri/",
                method: "POST",
                body: payload,
            }),
        }),
        retrieveBot: builder.query<
            { created: string; link: string; title: string },
            { link: string }
        >({
            query: (payload) => ({
                url: "/bot/bot/active/retrieve/",
                method: "POST",
                body: payload,
            }),
            keepUnusedDataFor: 0,
        }),
        getUpcomingMeetings: builder.query<
            PaginationType<UpcomingConveration>,
            {
                next?: string;
            } & UpcomingConversationFilters
        >({
            query: ({ next, ...filters }) => ({
                url: next || "calendar/calendar/upcoming/",
                method: "POST",
                body: filters,
            }),
        }),
        updateUpcomingMeetingCallType: builder.mutation<
            UpcomingConveration,
            { call: number; type: number; filters: UpcomingConversationFilters }
        >({
            query: ({ call, type }) => ({
                url: `calendar/calendar/edit/change_call_type/${call}/${type}/`,
                method: "PATCH",
            }),
            async onQueryStarted(
                { call, filters },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        meetingsApiSlice.util.updateQueryData(
                            "getUpcomingMeetings",
                            filters,
                            (draft) =>
                                Object.assign(draft, {
                                    results: [
                                        ...draft.results.map((meeting) =>
                                            meeting.id === call ? data : meeting
                                        ),
                                    ],
                                })
                        )
                    );
                } catch {}
            },
        }),
        getAlerts: builder.query<
            PaginationType<Alert>,
            {
                next?: string;
            }
        >({
            query: ({ next }) => next || "/tracker/tracker/list_all/",
        }),
        createAlert: builder.mutation<Alert, AlertPayload>({
            query: (payload) => ({
                url: "/tracker/tracker/create/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        meetingsApiSlice.util.updateQueryData(
                            "getAlerts",
                            {},
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    count: draft.count + 1,
                                    results: [data, ...draft.results],
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        updateAlert: builder.mutation<Alert, Omit<AlertPayload, "search_json">>(
            {
                query: ({ id, ...payload }) => ({
                    url: `/tracker/tracker/update/${id}/`,
                    method: "PATCH",
                    body: payload,
                }),
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled;
                        dispatch(
                            meetingsApiSlice.util.updateQueryData(
                                "getAlerts",
                                {},
                                (draft) => {
                                    Object.assign(draft, {
                                        ...draft,
                                        results: draft.results.map((e) =>
                                            e.id === data.id ? data : e
                                        ),
                                    });
                                }
                            )
                        );
                    } catch {}
                },
            }
        ),
        deleteAlert: builder.mutation<Alert, number>({
            query: (id) => ({
                url: `/tracker/tracker/delete/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        meetingsApiSlice.util.updateQueryData(
                            "getAlerts",
                            {},
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    results: draft.results.filter(
                                        (e) => e.id !== id
                                    ),
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        shareMeetings: builder.mutation<
            ShareMettingsResponse,
            ShareMettingsPayload
        >({
            query: (payload) => ({
                url: "/share/meetings/",
                method: "POST",
                body: payload,
            }),
        }),
        getShareMeetings: builder.query<ShareMettingsResponse, string>({
            query: (id) => `/share/meetings/${id}/`,
        }),
        getTrackerMeetings: builder.query<Alert, string>({
            query: (id) => `/tracker/tracker/update/${id}/`,
        }),
        getBotConfiguration: builder.query<
            {
                announcement_text: string;
                client_email_mandatory: boolean;
                name: string;
                play_announcement: true;
            },
            void
        >({
            query: () => "/bot/bot/configure/",
        }),
    }),
});

export const {
    useGetMeetingsQuery,
    useLazyGetMeetingsQuery,
    useUpdateMeetingMutation,
    useDeleteMeetingMutation,
    useCreateViewMutation,
    useGetDefaultViewQuery,
    useGetListViewQuery,
    useDeleteViewMutation,
    useDownloadMeetingDumpMutation,
    useAddBotMutation,
    useLazyRetrieveBotQuery,
    useGetUpcomingMeetingsQuery,
    useLazyGetUpcomingMeetingsQuery,
    useUpdateUpcomingMeetingCallTypeMutation,
    useGetAlertsQuery,
    useLazyGetAlertsQuery,
    useCreateAlertMutation,
    useUpdateAlertMutation,
    useDeleteAlertMutation,
    useShareMeetingsMutation,
    useGetShareMeetingsQuery,
    useGetTrackerMeetingsQuery,
    useGetBotConfigurationQuery,
} = meetingsApiSlice;
