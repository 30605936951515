export const SET_AUDIT_PERFORMANCE_DETAILS = "SET_OVERALL_PERFORMANCE_DETAILS";
export const SET_AUDITOR_LIST_DATA = "SET_AUDITOR_LIST_DATA";
export const SET_TEAM_PERFORMANCE_DETAILS = "SET_TEAM_PERFORMANCE_DETAILS";
export const SET_TEAM_LIST_DATA = "SET_TEAM_LIST_DATA";
export const SET_AGENT_LIST_DATA = "SET_AGENT_LIST_DATA";
export const SET_REPORT_ALL_TEAMS = "SET_REPORT_ALL_TEAMS";
export const SET_REPORT_AGENT = "SET_REPORT_AGENT";
export const SET_REPORT_FILTER_DATES = "SET_REPORT_FILTER_DATES";
export const SET_REPORT_ACTIVE_FILTER_DATE = "SET_REPORT_ACTIVE_FILTER_DATE";
export const SET_REPORT_ACTIVE_TEAM = "SET_REPORT_ACTIVE_TEAM";
export const SET_REPORT_ACTIVE_AGENT = "SET_REPORT_ACTIVE_AGENT";
export const STORE_TOP_INSIGHTS = "STORE_TOP_INSIGHTS";
export const STORE_CATEGORY_INSIGHTS = "STORE_CATEGORY_INSIGHTS";
export const SET_AGENT_PERFORMANCE_DETAILS = "SET_AGENT_PERFORMANCE_DETAILS";
export const STORE_CALL_WISE_LIST_DATA = "STORE_CALL_WISE_LIST_DATA";
export const SET_AUDITOR_GRAPH_DATA = "SET_AUDITOR_GRAPH_DATA";
export const SET_CARD_LOADER = "SET_CARD_LOADER";
export const SET_TOP_INSIGHT_LOADER = "SET_TOP_INSIGHT_LOADER";
export const SET_CATEGORY_INSIGHT_LOADER = "SET_CATEGORY_INSIGHT_LOADER";
export const SET_TABLE_LOADING = "SET_TABLE_LOADING";
export const SET_REPORT_CALL_DURATION = "SET_REPORT_CALL_DURATION";
export const SET_REPORT_FILTER_CALL_DURATION =
    "SET_REPORT_FILTER_CALL_DURATION";
export const SET_AUDITOR_CALL_WISE_LIST = "SET_AUDITOR_CALL_WISE_LIST";
