import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AuthUserType,
    PaginatedUserListType,
    UpdateUserPayload,
    UserType,
} from "@convin/type/User";
import {
    getUserName,
    updateDownloadQue,
} from "@convin/utils/helper/common.helper";
import { CreateUserPayload, InviteUserType } from "@convin/type/UserManager";
import { AvailableSubscription } from "@convin/type/Subscription";
import { DownloadReport } from "@convin/type/Report";

export const userManagerApiSlice = createApi({
    reducerPath: "userManagerApiSlice",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["User", "InvitedUser"],
    endpoints: (builder) => ({
        getAllUsers: builder.query<
            PaginatedUserListType,
            {
                page?: number;
                payload?: Record<
                    string,
                    | (string | number | boolean | null | undefined)[]
                    | string
                    | null
                    | undefined
                >;
            }
        >({
            query: ({ page, payload }) => ({
                url: "/person/person/list_all/",
                method: "POST",
                body: { ...payload },
                params: { page: page },
            }),
            transformResponse: (response: PaginatedUserListType) => {
                return {
                    ...response,
                    results: Array.isArray(response.results)
                        ? response.results?.map((e) => ({
                              ...e,
                              label: getUserName(e),
                          }))
                        : response.results,
                };
            },
            providesTags: ["User"],
        }),

        getInvitedUsers: builder.query<InviteUserType[], void>({
            query: () => "/person/person/invite/",
            providesTags: ["InvitedUser"],
        }),

        deleteInvitedUser: builder.mutation<void, string>({
            query: (id) => ({
                url: `/person/person/invite/${id}`,
                method: "DELETE",
            }),
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                const deleteResult = dispatch(
                    userManagerApiSlice.util.updateQueryData(
                        "getInvitedUsers",
                        undefined,
                        (draft) => draft.filter((e) => e.invitation_id !== id)
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),

        getAvailableSubscriptions: builder.query<AvailableSubscription[], void>(
            {
                query: () => "/subscription/available-subscriptions/",
            }
        ),
        downloadUsers: builder.query<Blob, void>({
            query: () => "/person/download",
        }),
        changePasswordByUserId: builder.mutation<
            string[],
            { user_id: number; password: string }
        >({
            query: (payload) => ({
                url: "/person/reset_password/",
                method: "POST",
                body: payload,
            }),
        }),
        updateUserById: builder.mutation<
            UserType,
            Partial<UpdateUserPayload> & { id: number }
        >({
            query: ({ id, ...payload }) => ({
                url: `/person/person/retrieve_update/${id}`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        userManagerApiSlice.util.updateQueryData(
                            "getAllUsers",
                            {},
                            (draft) =>
                                Object.assign(
                                    draft,
                                    draft.results.map((e) =>
                                        e.id === id ? { ...e, ...data } : e
                                    )
                                )
                        )
                    );
                } catch {
                    return;
                }
            },
        }),
        inviteUser: builder.mutation<string, string>({
            query: (invitation_id) => ({
                url: `/person/person/invite/${invitation_id}`,
                method: "POST",
            }),
        }),
        createBulkUser: builder.mutation<
            Partial<AuthUserType>,
            Partial<CreateUserPayload>[]
        >({
            query: (payload) => ({
                url: "/person/bulk/create/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, error) => (!!error ? [] : ["User"]),
        }),
        inviteBulkUser: builder.mutation<
            Partial<AuthUserType>,
            Partial<CreateUserPayload>[]
        >({
            query: (payload) => ({
                url: "/person/bulk/invite/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (res, error) => (!!error ? [] : ["InvitedUser"]),
        }),
        downloadUsersExcelSheet: builder.mutation<DownloadReport, void>({
            query: () => ({
                url: "/person/download/",
                method: "POST",
                body: {},
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                        message:
                            "Excel sheet generation has started. You will be notified once it is ready ",
                    });
                } catch (err) {}
            },
        }),
    }),
});

export const {
    useGetAllUsersQuery,
    useLazyGetAllUsersQuery,
    useUpdateUserByIdMutation,
    useGetInvitedUsersQuery,
    useGetAvailableSubscriptionsQuery,
    useInviteUserMutation,
    useChangePasswordByUserIdMutation,
    useCreateBulkUserMutation,
    useInviteBulkUserMutation,
    useDownloadUsersExcelSheetMutation,
    useDeleteInvitedUserMutation,
} = userManagerApiSlice;
