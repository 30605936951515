import { useContext } from "react";
import {
    INodeStateContext,
    NodeStateContext,
} from "../context/NodeStateContext";

const useNodeContext = (): INodeStateContext => {
    const context = useContext(NodeStateContext);

    if (!context)
        throw new Error(
            "Node Creator context must be use inside BertProviderprovider"
        );

    return context;
};

export default useNodeContext;
