export const STORE_CALL_TYPES = "STORE_CALL_TYPES";
export const STORE_SEARCH_FIELDS = "STORE_SEARCH_FIELDS";
export const STORE_COMPLETED_CALLS = "STORE_COMPLETED_CALLS";
export const STORE_UPCOMING_CALLS = "STORE_UPCOMING_CALLS";
export const STORE_COMPLETED_NEXT_URL = "STORE_COMPLETED_NEXT_URL";
export const STORE_UPCOMING_NEXT_URL = "STORE_UPCOMING_NEXT_URL";
export const SET_LOADER = "SET_LOADER";
export const STORE_SIDEBAR = "STORE_SIDEBAR";
export const SET_UPDATING_CALL = "SET_UPDATING_CALL";
export const SET_UPDATING_TAGS = "SET_UPDATING_TAGS";
export const SET_UPDATING_SIDEBAR = "SET_UPDATING_SIDEBAR";
export const SET_UPCOMMING_CALLS_FILTERS = "SET_UPCOMMING_CALLS_FILTERS";
export const SET_UPCOMMING_CALLS_ACTIVE_TEAM_FILTER =
    "SET_UPCOMMING_CALLS_ACTIVE_TEAM_FILTER";
export const SET_UPCOMMING_CALLS_PARTICIPANTS_FILTERS =
    "SET_UPCOMMING_CALLS_PARTICIPANTS_FILTERS";
