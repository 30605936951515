export const domainMappingConfig = {
    voiceanalysis1: "mahindrafirstchoice",
};

export const subDomainMappingConfig = {
    mahindrafirstchoice: "elisiontec.com",
};

export const redirectDomainMappingConfig = {
    mahindrafirstchoice: "voiceanalysis1",
};
