import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Box, Typography, TextField } from "@mui/material";
import MomentPhraseCard from "@convin/modules/settings/modules/auditManager/components/bertRuleEngine/components/MomentPhraseCard";
import { nanoid } from "@reduxjs/toolkit";
import { MomentBlockFormType } from "@convin/modules/settings/modules/auditManager/components/types/bert";
import AIRecommandationModal from "@convin/modules/settings/modules/auditManager/components/bertRuleEngine/components/AiRecommendation/AIRecommendationModal";

const RHFPhraseListField: React.FC = () => {
    const { control, watch } = useFormContext<MomentBlockFormType>();

    const [phraseToAdd, setPhraseToAdd] = useState("");
    const phrases = watch("phrase");
    const { append, remove } = useFieldArray({
        control,
        name: "phrase",
    });

    const handleAddPhrase = (phrase: string) => {
        if (phrase) {
            append({ id: nanoid(), phrase });
        }
        setPhraseToAdd("");
    };

    return (
        <>
            <Controller
                name="phrase"
                control={control}
                render={() => (
                    <>
                        <Box
                            className="flex items-center justify-between"
                            sx={{
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: 1,
                            }}
                        >
                            <TextField
                                value={phraseToAdd}
                                onChange={(e) => {
                                    setPhraseToAdd(e.target.value);
                                }}
                                label="Add a Phrase"
                                className="w-full"
                                placeholder="Add a Phrase"
                                size="small"
                                sx={(theme) => ({
                                    "& .MuiInputBase-root > fieldset": {
                                        border: "none",
                                    },
                                    "& .MuiFormLabel-root": {
                                        background:
                                            theme.palette.background.paper,
                                    },
                                })}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleAddPhrase(phraseToAdd);
                                    }
                                }}
                            />
                            <Box className="flex items-center">
                                <AIRecommandationModal phrase={phraseToAdd} />
                                <Box
                                    component="button"
                                    sx={{
                                        background: "#000",
                                        px: 1.5,
                                        py: 0.5,
                                        color: "#fff",
                                        m: 0.5,
                                        borderRadius: 1,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleAddPhrase(phraseToAdd);
                                    }}
                                    className="nodrag nopan"
                                >
                                    +
                                </Box>
                            </Box>
                        </Box>
                        {phrases.length !== 0 ? (
                            <Box
                                sx={{
                                    height: "130px",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    mb: 1,
                                    borderRadius: "6px",
                                }}
                                className="flex flex-col flex-auto overflow-y-auto"
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        p: 1,
                                        background: "rgba(153, 153, 153, 0.10)",
                                        borderBottom:
                                            "1px solid rgba(0, 0, 0, 0.23)",
                                        fontWeight: 600,
                                    }}
                                    component={"div"}
                                >
                                    Added Phrases-{`${phrases.length ?? 0}`}
                                </Typography>
                                <Box className="overflow-y-scroll nowheel">
                                    {phrases.map((item, idx) => (
                                        <MomentPhraseCard
                                            key={item.id}
                                            name={item.phrase}
                                            onClick={() => remove(idx)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ) : null}
                    </>
                )}
            />
        </>
    );
};

export default RHFPhraseListField;
