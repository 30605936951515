import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function AttributeSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <mask
                id="mask0_1174_34033"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1174_34033)">
                <path
                    d="M5 16.6693L5.83333 13.3359H2.5L2.91667 11.6693H6.25L7.08333 8.33594H3.75L4.16667 6.66927H7.5L8.33333 3.33594H10L9.16667 6.66927H12.5L13.3333 3.33594H15L14.1667 6.66927H17.5L17.0833 8.33594H13.75L12.9167 11.6693H16.25L15.8333 13.3359H12.5L11.6667 16.6693H10L10.8333 13.3359H7.5L6.66667 16.6693H5ZM7.91667 11.6693H11.25L12.0833 8.33594H8.75L7.91667 11.6693Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
