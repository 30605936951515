import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@mui/material";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
    name: PropTypes.string,
};

export default function RHFTextField({
    name = "",
    onChange,
    value,
    ...other
}: TextFieldProps) {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                    onChange={(e) => {
                        field.onChange(e);
                        onChange?.(e);
                    }}
                    value={value ?? field.value ?? ""}
                />
            )}
        />
    );
}
