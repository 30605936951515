import { Box, SxProps, Theme } from "@mui/material";
import React, { FunctionComponent } from "react";

export const CrudButtonWrapper: FunctionComponent<{
    onClick: () => void;
    children: React.ReactNode;
    sx?: SxProps<Theme>;
}> = ({ onClick, children, sx }) => (
    <Box
        sx={sx}
        onClick={onClick}
        className="cursor-pointer h-[32px] flex items-center justify-center flex-shrink-0"
    >
        {children}
    </Box>
);
