import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function GroupBlockSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <mask
                id="mask0_1174_33828"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1174_33828)">
                <path
                    d="M9.96606 16.7373L3.26562 11.5258L4.49404 10.5952L9.96606 14.8388L15.4381 10.5952L16.6665 11.5258L9.96606 16.7373ZM9.96606 12.9776L3.26562 7.76614L9.96606 2.55469L16.6665 7.76614L9.96606 12.9776Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
