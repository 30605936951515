import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function BertMoreOptionsSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 4 14"
            sx={{ width: 4, height: 14, fill: "none", ...sx }}
        >
            <path
                d="M0.330729 2.0026C0.330729 1.54427 0.493924 1.15191 0.820312 0.825521C1.1467 0.499132 1.53906 0.335937 1.9974 0.335937C2.45573 0.335937 2.84809 0.499132 3.17448 0.825521C3.50087 1.15191 3.66406 1.54427 3.66406 2.0026C3.66406 2.46094 3.50087 2.8533 3.17448 3.17969C2.84809 3.50608 2.45573 3.66927 1.9974 3.66927C1.53906 3.66927 1.1467 3.50608 0.820312 3.17969C0.493924 2.8533 0.330729 2.46094 0.330729 2.0026ZM0.330729 7.0026C0.330729 6.54427 0.493923 6.15191 0.820312 5.82552C1.1467 5.49913 1.53906 5.33594 1.9974 5.33594C2.45573 5.33594 2.84809 5.49913 3.17448 5.82552C3.50087 6.15191 3.66406 6.54427 3.66406 7.0026C3.66406 7.46094 3.50087 7.8533 3.17448 8.17969C2.84809 8.50608 2.45573 8.66927 1.9974 8.66927C1.53906 8.66927 1.1467 8.50608 0.820312 8.17969C0.493923 7.8533 0.330729 7.46094 0.330729 7.0026ZM0.330729 12.0026C0.330729 11.5443 0.493923 11.1519 0.820312 10.8255C1.1467 10.4991 1.53906 10.3359 1.9974 10.3359C2.45573 10.3359 2.84809 10.4991 3.17448 10.8255C3.50087 11.1519 3.66406 11.5443 3.66406 12.0026C3.66406 12.4609 3.50087 12.8533 3.17448 13.1797C2.84809 13.5061 2.45573 13.6693 1.9974 13.6693C1.53906 13.6693 1.1467 13.5061 0.820312 13.1797C0.493923 12.8533 0.330729 12.4609 0.330729 12.0026Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
