import { TextField, alpha, styled } from "@mui/material";

export const TableSearchInput = styled(TextField)(({ theme }) => ({
    border: `1px solid ${alpha(theme.palette.textColors[999], 0.08)}`,
    fieldset: {
        border: "none",
    },
    borderRadius: 6,
    background: alpha(theme.palette.textColors[999], 0.1),
    ".MuiInputBase-root": {
        height: 40,
    },
    input: {
        fontSize: 14,
        marginLeft: 4,
    },
}));
