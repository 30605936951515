import commonConfig from "@constants/common/index";
import routes from "@constants/Routes/index";

const settingsConfig = {
    SUBNAV: "settings-subnav",
    PLACEHOLDER_USER_ROLE: "Enter your role in the organization",
    PLACEHOLDER_EMAIL: "abc@xyz.com",
    PLACEHOLDER_PASSWORD: "Password",
    PLACEHOLDER_PHONE: "+91 00000 00000",
    PLACEHOLDER_ADDRESS: "Enter your address",
    PLACEHOLDER_LOCATION: "Enter your location",
    LABEL_EMAIL: "EMAIL ADDRESS",
    LABEL_PASSWORD: "PASSWORD",
    LABEL_PHONE: "PHONE NUMBER",
    LABEL_ADDRESS: "ADDRESS",
    LABEL_LOCATION: "LOCATION",
    LABEL_BTN_CANCEL: "CANCEL",
    LABEL_BTN_SAVE: "SAVE SETTINGS",
    NAME: "name",
    EMAILNAME: "email",
    PASSNAME: "password",
    PHONENAME: "primary_phone",
    ADDRESSNAME: "address",
    LOCATIONNAME: "location",
    CANCELTITLE: "Cancel",
    SAVETITLE: "Save",
    ROLENAME: "role",
    DESIGNATION: "designation",
    TEAM: "Team",
    SEARCHNAME: "searchuser",
    SEARCHTITLE: "Search User",
    CREATEUSERLABEL: "Create User",
    SEARCHPLACEHOLDER: "Search User by Name",
    USERSPERPAGE: 10,
    PREVLABEL: "Previous Page",
    NEXTLABEL: "Next Page",

    // Table Headings

    DATEJOINED: "Date Joined",
    ROLE: "Role",
    FULLNAME: "Full Name",
    EMAIL: "Email Address",
    USERID: "User ID",
    ACTIONS: "Actions",

    // Modal Boxes
    MODALLABEL: "CREATE USER",
    EMAILTITLE: "Enter Email Address",
    ROLETITLE: "Select a Role",
    SENDLABEL: "SEND INVITE",
    SENDTITLE: "Send Invite",
    TEAMDROPDOWNNAME: "team",
    TEAMDROPDOWNTITLE: "Select A Team",

    // Tabs
    TABS: [
        {
            tabIdx: 0,
            tabName: "User Profile",
            text: "Edit Profile",
            protected: false,
            showForManager: true,
            class: "profile-view",
            tour_key: commonConfig.TOURS_KEYS.user,
            isInTour: false,
        },
        {
            tabIdx: 1,
            tabName: "User Manager",
            text: "Manage Users",
            protected: true,
            showForManager: false,
            class: "user-manager-view",
            tour_key: commonConfig.TOURS_KEYS.userManager,
            isInTour: false,
        },
        {
            tabIdx: 2,
            tabName: "Role Manager",
            text: "Role Manager",
            protected: true,
            showForManager: true,
            class: "role-manager-view",
        },
        {
            tabIdx: 3,
            tabName: "Topic Manager",
            text: "Manage Topics",
            protected: true,
            showForManager: false,
            class: "topic-manager-view",
            tour_key: commonConfig.TOURS_KEYS.topicManager,
            isInTour: true,
        },
        {
            tabIdx: 4,
            tabName: "Integrations",
            text: "Integrations",
            protected: true,
            showForManager: true,
            class: "integrations-view",
            tour_key: commonConfig.TOURS_KEYS.integrations,
            isInTour: false,
        },
        {
            tabIdx: 5,
            tabName: "Team Manager",
            text: "Manage Teams",
            protected: true,
            showForManager: false,
            class: "team-manager-view",
            tour_key: commonConfig.TOURS_KEYS.teamManager,
            isInTour: false,
        },
        {
            tabIdx: 6,
            tabName: "Sampling Manager",
            text: "",
            protected: true,
            showForManager: true,
            class: "",
            tour_key: "",
            isInTour: false,
        },
        {
            tabIdx: 7,
            tabName: "Audit Manager",
            text: "Manage Audit",
            protected: true,
            showForManager: false,
            class: "audit-manager-view",
            tour_key: commonConfig.TOURS_KEYS.auditManager,
            isInTour: false,
            noAiAuditTabName: "Scorecard Manager",
        },
        {
            tabIdx: 8,
            tabName: "Violation Manager",
            text: "Violation Manager",
            protected: true,
            showForManager: true,
            class: "violation-manager",
            tour_key: commonConfig.TOURS_KEYS.violation_manager,
            isInTour: false,
        },
        {
            tabIdx: 9,
            tabName: "Recording Manager",
            text: "Manage Recordings",
            protected: false,
            showForManager: true,
            class: "recording-manager-view",
            tour_key: commonConfig.TOURS_KEYS.recordingManager,
            isInTour: false,
        },
        {
            tabIdx: 10,
            tabName: "Call Type Manager",
            text: "Manage Recordings",
            protected: true,
            showForManager: false,
            class: "calltype-manager-view",
            tour_key: commonConfig.TOURS_KEYS.callTypeManager,
            isInTour: false,
        },
        {
            tabIdx: 11,
            tabName: "Call Tags Manager",
            text: "Manage Tags",
            protected: true,
            showForManager: true,
            class: "calltags-manager-view",
            tour_key: commonConfig.TOURS_KEYS.callTagsManager,
            isInTour: false,
        },
        {
            tabIdx: 12,
            tabName: "Billing",
            text: "Manage Subscription/Invoices",
            protected: true,
            showForManager: true,
            class: "billing-view",
            tour_key: commonConfig.TOURS_KEYS.billing,
            isInTour: false,
        },
        {
            tabIdx: 13,
            tabName: "Scheduled Reports",
            text: "Scheduled Reports",
            protected: true,
            showForManager: true,
            class: "sch_reports-view",
            tour_key: commonConfig.TOURS_KEYS.sch_reports,
            isInTour: false,
        },
        {
            tabIdx: 14,
            tabName: "Moments Settings",
            text: "Moments Settings",
            protected: true,
            showForManager: true,
            class: "moments-settings-view",
        },
        {
            tabIdx: 15,
            tabName: "Live Assist Settings",
            text: "",
            protected: false,
            showForManager: true,
            class: "",
            tour_key: "",
            isInTour: false,
            options: [
                { tabName: "Battle Cards", path: routes.settings.battle_cards },

                // {
                //     tabName: 'Call Flow Template',
                //     path: routes.settings.bat,
                // },
                // {
                //     tabName: 'Knowledge Base',
                //     path: routes.settings.knowledge_base,
                // },
            ],
        },
        {
            tabIdx: 17,
            tabName: "Notes Settings",
            text: "Notes",
            protected: false,
            showForManager: true,
            class: "",
        },
    ],
    // call type manager
    ADD_CALL_TYPE: "Add Call Type",
    NEW_CALL_TYPE_PLACEHOLDER: "Add call type and press enter",
    DONE_EDIT: "Done",
    EDIT_CALL_TYPES: "Delete Call Types",
    NO_CALLTYPES: "No Call Types To Show",
    INVITATION_SUCCESS: "Invitation Successfully sent.",
    TEAM_MANAGER: {
        tabTitle: "Team Manager",
        btnLabel: "Create Team",
        btnLabelEdit: "Edit",
        btnLabelDelete: "Remove",
        nameLabel: "Team Name",
        leaderLabel: "Team Leader",
        memberLabel: "Member",
        actionLabel: "Action",
        modalLabel: "Add Team",
        teamNameInput: "name",
        teamLeaderInput: "teammanager",
        teamNameTitle: "Enter Team Name",
        modalSubmitLabel: "Create Team",
        removeTeamLabel: "Remove Team",
        editTeamLabel: "Edit Team",
        createdTeam: "Successfully Created Team",
        noAccess: "N/A",
        confirmMessage: "Are you sure you want to remove this team?",
        removeTitle: "Remove Team",
        // Team Side Pane
        fullNameLabel: "Full Name",
        emailAddressLabel: "Email Address",
        roleLabel: "Role",
        successMessage: "Saving...",
        selectLeader: "Select a Team Leader",
        teamManagerExists: "Team already has a Manager :",
    },
    TOPICMANAGER: {
        tabTitle: "Topic Manager",
        btnLabel: "Create Topic",
        modalLabel: "CREATE TOPIC",
        topicsPerPage: 10,
        searchName: "searchtopic",
        searchPlaceholder: "Search Topic by Phrase, Text or Keyword.",
        nameLabel: "Topic Name",
        topicName: "topicname",
        phrasesName: "phrases",
        keywordsLabel: "Topic Keywords",
        addKeywordsLabel: "Add Keywords",
        addPhrasesLabel: "Add Phrases",
        keywordsName: "phrases",
        submitLabel: "Save Topic",
        keywordsPlaceholder: "Seperate Keywords with Commas",
        phrasesPlaceholder: "Separate Phrases with Commas",
        topicPlaceholder: "Enter Topic Name",
        minKeywordsLabel: "Add Atleast 3",
        minPhrasesLabel: "Add Atleast 1",
        minKeywords: 3,
        minPhrases: 1,
        phrasesLabel: "Topic Phrases",
        actionsLabel: "Action",
        maxKeywords: 4,
        topic: "Topic",
        editLabel: "Edit",
        topicNameLabel: "Topic Name",
        extraSettingsLabel: "Extra Settings",
        extraSettings: "extrasettings",
        topicKeywordsLabel: "Topic Keywords",
        edit: "Edit",
        noKeywords: "No Keywords Found",
        noPhrases: "No Phrases Found",
        topicPhrasesLabel: "Topic Phrases",
        updateTopic: "Update Topic",
        addPhrase: "Add Phrase",
        addKeyword: "Add Keyword",
        confirmBoxTitle: "Confirm Remove",
        confirmMessage: "Are you sure you want to delete the topic?",
        removeTitle: "Remove Topic",
    },
    FEEDBACKMANAGER: {
        title: "Feedback Manager",
        btnLabel: "Add Question",
        question: "Question",
        responseLabel: "Response Type",
        noFeedbacks: "No Questions Found",
        editLabel: "Edit",
        removeLabel: "Remove",
        addFeedbackLabel: "CREATE NEW FEEDBACK",
        feedbackQuestion: "Feedback Question",
        enterQuestion: "Enter Your Question",
        create: "Create",
        save: "Save Changes",
        editFeedbackLabel: "Edit Feedback",
    },
    RECORDINGMANAGER: {
        heading: "Set Up Your Voice Print",
        recordCallsMessage: "Record my calls only when ",
        setupVoicePrint: "Setup my Voice Print",
        continue: "Record",
        buttonIcon: "fa-play-circle",
        myVoicePrint: "My Voice Print",
        delete: "Delete",
        record: "Record",
        recordAgain: "Record Again",
        start: "Start",
        cancel: "Cancel",
        save: "Save",
        stop: "Stop",
        readPassage: "Read The Following Passage",
        blockedMessage: "Microphone Denied Access or is unavailable.",
        notSupportedError: "Your browser does not support microphones.",
        notAvailableError: "Microphone not available in the system.",
        mediaConstraints: { audio: true, video: false },
        accessDeniedError: "Kindly allow access to Microphone for recording.",
        someOtherError: "Some error occurred during recording.",
        accessDeniedTypes: ["NotAllowedError", "PermissionDeniedError"],
        audioInputType: "audioinput",
        recording: "Recording",
        settingLabel: "Recording Assistant Setting",
        automaticLabel: "Assistant will record calls automatically",
        assistantLabel: "Configure your assistant",
        assistantName: "Assistant name:",
        announcementMsg: "Announcement message:",
        passage:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi velit lacus, consequat eget auctor a, pharetra in est. Phasellus rutrum mi a risus aliquet semper. Nullam non elementum risus. Aenean et risus sed tellus fringilla vehicula eget elementum dolor. Nam tincidunt velit id nisl tempor congue. Maecenas vitae velit sodales nulla molestie sollicitudin. Quisque interdum lorem est, eu ultrices nisl consequat ut. Aenean consequat sapien posuere lorem pharetra iaculis. In hac habitasse platea dictumst.",
        ASSISTANT_SKIP_STEP: 3,
        default_message: `Hi everyone, I'm ${
            import.meta.env.VITE_APP_BRAND_NAME
        }. I'll be taking notes for this meeting so that all of you can focus on discussion rather than taking notes. Have a great discussion.`,
        default_assistant_name: `${
            import.meta.env.VITE_APP_BRAND_NAME
        } Notetaker`,
        IS_INTERNAL: "IS_INTERNAL",
        IS_EXTERNAL: "IS_EXTERNAL",
        IS_HOST: "IS_HOST",
        IS_NOT_HOST: "IS_NOT_HOST",
        IS_BOTH: "IS_BOTH",
        IS_NONE: "IS_NONE",
    },
    TP_ARRAY: [
        "salesforce",
        "leadsquared",
        "slack",
        "pipedrive",
        "zoho",
        "close",
        "knowlarity",
        "freshworks",
        "hubspot",
        "zoom",
        "vonage",
        "twist",
        "crm_sheet",
        "airtable",
        "dialpad",
        "aircall",
        "zendesk_talk",
        "outreach",
        "ringcentral",
        "zoho_desk",
        "intercom",
        "ozonetel",
        "exotel",
        "niceincontact",
        "livechat",
    ],

    SAMPLING_RULE_STEPS: ["Configure", "Filters", "Assign"],
    BATTLE_CARDS_STEPS: [
        "Basic Info",
        "Trigger Configuration",
        "Action Configuration",
    ],
};

export default settingsConfig;
