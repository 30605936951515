import { AuthContext, IAuthContextSate } from "@convin/contexts/AuthContext";
import { useContext } from "react";

const useAuth = (): IAuthContextSate => {
    const context = useContext(AuthContext);

    if (!context)
        throw new Error("Auth context must be use inside AuthProvider");

    return context;
};

export default useAuth;
