import { PropsWithChildren, ReactElement } from "react";
import { Box, BoxProps, GridProps, useTheme } from "@mui/material";
import { isDarkMode } from "@convin/utils/helper/common.helper";
function BorderedBox({
    children,
    sx = {},
    ...rest
}: PropsWithChildren<BoxProps | GridProps>): ReactElement {
    const theme = useTheme();
    return (
        <Box
            sx={{
                border: "1px solid rgba(153, 153, 153, 0.10)",
                borderRadius: "12px",
                ...(!isDarkMode(theme) && {
                    boxShadow: " 0px 2px 16px 0px rgba(153, 153, 153, 0.10);",
                }),
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
}

export default BorderedBox;
