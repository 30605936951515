import React, { Dispatch, createContext } from "react";
import { Position } from "reactflow";
import { MultiResponseBlockData, SingleResponseNode } from "../../types/bert";

export type SingleResponseBlockType =
    | "attribute"
    | "moment"
    | "parameter"
    | "function"
    | null;

export type MultiResponseNodeType = "condition" | "response";

export type BuildingBlockType = "single" | "group" | null;

export type Anchor = "right";

export interface IBlockState {
    buildingBlockType: BuildingBlockType;
    selectedBlockType: SingleResponseBlockType | MultiResponseNodeType;
}

export type NodeStateDispatchAction = {
    payload: Partial<IBlockState>;
    type: "UPDATE";
};

export interface INodeStateContext {
    state: IBlockState;
    dispatch: Dispatch<NodeStateDispatchAction>;
    createBlock: boolean;
    setCreateBlock: React.Dispatch<React.SetStateAction<boolean>>;
    sourceId?: string;
    blockParentId?: string;
    blockId?: string;
    direction?: Position;
    onNodeSaveCallBack: () => void;
    nodeIdToUpdate?: string | null;
    nodeDataToUpdate?:
        | Partial<
              SingleResponseNode<
                  NonNullable<SingleResponseBlockType>
              >["metadata"]
          >
        | MultiResponseBlockData;
    showDefaultRuleButton?: boolean;
    hideReponseBlock?: boolean;
}

export const NodeStateContext = createContext<INodeStateContext | undefined>(
    undefined
);

export type NodeStateProviderProps = Pick<
    INodeStateContext,
    | "sourceId"
    | "direction"
    | "blockParentId"
    | "blockId"
    | "onNodeSaveCallBack"
    | "nodeIdToUpdate"
    | "nodeDataToUpdate"
    | "showDefaultRuleButton"
    | "hideReponseBlock"
>;
