import { Box, BoxProps } from "@mui/material";

export default function BackDrop({ onClick }: BoxProps) {
    return (
        <Box
            className="absolute nopan"
            onClick={onClick}
            sx={{
                height: "1000vw",
                width: "1000vh",
                top: "-100vw",
                left: "-100vw",
            }}
        />
    );
}
