import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { SummaryTemplateField } from "@convin/type/AiSummary";
import { PaginationType } from "@convin/type/Common";

export const aiSummaryApiSlice = createApi({
    reducerPath: "aiSummaryApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["AiSummaryTemplates"],
    endpoints: (builder) => ({
        getAiSummaryTemplate: builder.query<
            PaginationType<SummaryTemplateField>,
            {
                next?: string;
            }
        >({
            query: ({ next }) => ({
                url: next || "gpt/summary/",
                method: "GET",
            }),
            providesTags: ["AiSummaryTemplates"],
        }),
        createAiSummaryTemplate: builder.mutation<
            SummaryTemplateField,
            SummaryTemplateField
        >({
            query: ({ ...payload }) => ({
                url: "gpt/summary/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["AiSummaryTemplates"],
        }),
        updateAiSummaryTemplate: builder.mutation<
            SummaryTemplateField,
            Partial<SummaryTemplateField> & Pick<SummaryTemplateField, "id">
        >({
            query: ({ id, ...payload }) => ({
                url: `gpt/summary/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            invalidatesTags: ["AiSummaryTemplates"],
        }),
        deleteAiSummaryTemplate: builder.mutation<void, number>({
            query: (id) => ({
                url: `gpt/summary/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ["AiSummaryTemplates"],
        }),
    }),
});

export const {
    useGetAiSummaryTemplateQuery,
    useLazyGetAiSummaryTemplateQuery,
    useCreateAiSummaryTemplateMutation,
    useUpdateAiSummaryTemplateMutation,
    useDeleteAiSummaryTemplateMutation,
} = aiSummaryApiSlice;
