// ----------------------------------------------------------------------

export default function Select() {
    return {
        MuiSelect: {
            styleOverrides: {
                select: {
                    textTransform: "capitalize",
                },
            },
        },
    };
}
