import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function InfoLegendSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <rect width="24" height="24" rx="6" fill="#666666" />
            <mask x="5" y="5" width="14" height="14">
                <path d="M5 5H19V19H5V5Z" fill="#D9D9D9" />
            </mask>
            <g>
                <path
                    d="M11.8174 14.9141H12.9861V11.4141H11.8174V14.9141ZM12.4018 10.2474C12.5673 10.2474 12.7061 10.1915 12.8181 10.0797C12.9301 9.96788 12.9861 9.82934 12.9861 9.66406C12.9861 9.49878 12.9301 9.36024 12.8181 9.24844C12.7061 9.13663 12.5673 9.08073 12.4018 9.08073C12.2362 9.08073 12.0974 9.13663 11.9854 9.24844C11.8734 9.36024 11.8174 9.49878 11.8174 9.66406C11.8174 9.82934 11.8734 9.96788 11.9854 10.0797C12.0974 10.1915 12.2362 10.2474 12.4018 10.2474ZM12.4018 17.8307C11.5935 17.8307 10.8338 17.6776 10.1229 17.3714C9.41201 17.0651 8.79361 16.6495 8.26772 16.1245C7.74184 15.5995 7.32551 14.9821 7.01874 14.2724C6.71198 13.5627 6.55859 12.8043 6.55859 11.9974C6.55859 11.1905 6.71198 10.4321 7.01874 9.7224C7.32551 9.01267 7.74184 8.39531 8.26772 7.87031C8.79361 7.34531 9.41201 6.92969 10.1229 6.62344C10.8338 6.31719 11.5935 6.16406 12.4018 6.16406C13.2101 6.16406 13.9697 6.31719 14.6806 6.62344C15.3915 6.92969 16.0099 7.34531 16.5358 7.87031C17.0617 8.39531 17.478 9.01267 17.7848 9.7224C18.0916 10.4321 18.2449 11.1905 18.2449 11.9974C18.2449 12.8043 18.0916 13.5627 17.7848 14.2724C17.478 14.9821 17.0617 15.5995 16.5358 16.1245C16.0099 16.6495 15.3915 17.0651 14.6806 17.3714C13.9697 17.6776 13.2101 17.8307 12.4018 17.8307ZM12.4018 16.6641C13.7067 16.6641 14.8121 16.212 15.7178 15.3078C16.6235 14.4036 17.0763 13.3002 17.0763 11.9974C17.0763 10.6946 16.6235 9.59115 15.7178 8.68698C14.8121 7.78281 13.7067 7.33073 12.4018 7.33073C11.0968 7.33073 9.99146 7.78281 9.08577 8.68698C8.18007 9.59115 7.72723 10.6946 7.72723 11.9974C7.72723 13.3002 8.18007 14.4036 9.08577 15.3078C9.99146 16.212 11.0968 16.6641 12.4018 16.6641Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}
