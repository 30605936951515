import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function ResponseSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <g clipPath="url(#clip0_1185_42111)">
                <path
                    d="M10.8307 9.16927H3.33073C2.41406 9.16927 1.66406 8.41927 1.66406 7.5026V5.0026C1.66406 4.08594 2.41406 3.33594 3.33073 3.33594H10.8307V9.16927ZM3.33073 7.5026H9.16406V5.0026H3.33073V7.5026ZM12.4974 16.6693H3.33073C2.41406 16.6693 1.66406 15.9193 1.66406 15.0026V12.5026C1.66406 11.5859 2.41406 10.8359 3.33073 10.8359H12.4974V16.6693ZM3.33073 15.0026H10.8307V12.5026H3.33073V15.0026ZM18.3307 7.5026H16.6641L18.3307 3.33594H12.4974V9.16927H14.1641V16.6693L18.3307 7.5026ZM3.95573 14.3776H5.20573V13.1276H3.95573V14.3776ZM3.95573 6.8776H5.20573V5.6276H3.95573V6.8776Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_1185_42111">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
