import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function TrailingSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ width: "20px", height: "20px", ...sx }}
        >
            <path
                d="M5.83325 8.33331L9.99992 12.5L14.1666 8.33331H5.83325Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
