/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import {
    getSmoothStepPath,
    EdgeProps,
    BaseEdge,
    EdgeLabelRenderer,
    useReactFlow,
} from "reactflow";
import LogicOperatorSelector from "./LogicOperatorSelector";
import { EdgeData } from "../../../types/bert";
import { isDefined } from "@convin/utils/helper/common.helper";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";

export default function LogicOperatorEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    targetPosition,
    sourcePosition,
    style = {},
    markerEnd,
    data,
}: EdgeProps<EdgeData>) {
    const curvature = 50; // Adjust curvature as needed
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: curvature,
    });

    return (
        <>
            <Box
                component="g"
                sx={{
                    color: "textColors.333",
                    "&:hover": {
                        color: "primary.main",
                    },
                }}
            >
                <Box
                    path={edgePath}
                    markerEnd={markerEnd}
                    style={{
                        ...style,
                        stroke: "currentColor",
                        cursor: "pointer",
                        strokeDasharray: "5,5,5,5,5",
                    }}
                    component={BaseEdge}
                />
                <marker
                    id={`arrow-${id}`} // Unique ID for each arrow marker
                    viewBox="0 0 10 10"
                    refX="5"
                    refY="5"
                    markerWidth="6"
                    markerHeight="6"
                    orient="auto"
                >
                    <path d="M 0 0 L 10 5 L 0 10 z" fill="currentColor" />
                </marker>
                {/* Attach arrow to the path */}
                <path
                    d={edgePath}
                    style={{ fill: "none", stroke: "none" }}
                    markerEnd={`url(#arrow-${id})`} // Reference the arrow marker
                />
            </Box>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: "absolute",
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: "all",
                    }}
                >
                    {isDefined(data) && (
                        <LogicOperatorSelector {...data} id={id} />
                    )}
                </div>
            </EdgeLabelRenderer>
        </>
    );
}
