import homeConfigs from "@constants/Home/index";
import React, { useEffect, useState } from "react";
import { MehOutlined, WifiOutlined } from "@ant-design/icons";
import "./networkheader.scss";
import { useSelector, useDispatch } from "react-redux";
import { getDomain, getDomainMappingName } from "@tools/helpers";
import { getAppVersion } from "@apis/common/index";
import apiErrors from "@apis/common/errors";
import { storeVersionData } from "@store/common/actions";

function NetworkHeader(props) {
    const [isOffline, setIsOffline] = useState(false);
    const [isOnline, setIsOnline] = useState(false);
    let timer = null;
    const {
        common: { versionData },
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    //Eventlisteners checking user's internet connection
    useEffect(() => {
        window.addEventListener("online", () => {
            setIsOffline(false);
            setIsOnline(true);
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                setIsOnline(false);
            }, 2000);
            let appDomain = getDomainMappingName(
                getDomain(window.location.host)
            );
            if (!versionData || appDomain) {
                getAppVersion(appDomain).then((res) => {
                    if (res.status === apiErrors.AXIOSERRORSTATUS) {
                    } else {
                        dispatch(
                            storeVersionData(
                                res.stats_threshold
                                    ? { ...res }
                                    : {
                                          ...res,
                                          stats_threshold: {
                                              good: 75,
                                              average: 50,
                                              bad: 50,
                                          },
                                      }
                            )
                        );
                    }
                });
            }
        });
        window.addEventListener("offline", () => {
            setIsOnline(false);
            setIsOffline(true);
        });
    }, []);
    const handleReload = () => {
        window.location.reload();
    };
    return (
        <div
            className={`offline_online_header ${isOffline ? "offline" : ""} ${
                isOnline ? "online" : ""
            }`}
        >
            {isOffline && (
                <span>
                    <MehOutlined /> {homeConfigs.OFFLINE_MESSAGE}{" "}
                    <span onClick={handleReload} className="try_again">
                        Try Again
                    </span>
                </span>
            )}
            {isOnline && (
                <span>
                    <WifiOutlined /> {homeConfigs.ONLINE_MESSAGE}
                </span>
            )}
        </div>
    );
}

export default NetworkHeader;
