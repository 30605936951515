import { Box, Popover, PopoverProps, Typography } from "@mui/material";
import { PictureInPictureSvg, PlayerSettingsSvg } from "./MediaPlayerIcons";
import DownloadSvg from "@convin/components/svg/DownloadSvg";
import { isDefined } from "@convin/utils/helper/common.helper";
import { useState } from "react";
import useMediaPlayerContext from "../../hooks/useMediaPlayerContext";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import screenfull from "screenfull";
import { ChevronRight, SlowMotionVideo } from "@mui/icons-material";
import { playbackOptions } from "@convin/config/conversation.config";
import { showNoPermissionToast } from "@convin/utils/toast";

export default function MediaPlayerSettings({
    canDownload,
}: {
    canDownload: boolean;
}) {
    const { togglePip, playerRef, updateFullscreenState, playerState } =
        useMediaPlayerContext();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [playbackAnchorEl, setPlaybackAnchorEl] = useState<Element | null>(
        null
    );
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleDownload = () => {
        if (!canDownload) {
            return showNoPermissionToast();
        }
        if (!isDefined(playerRef.current)) return;
        const mediaUrl: string | undefined =
            playerRef.current.getInternalPlayer().src;
        if (isDefined(mediaUrl)) {
            const link = document.createElement("a");
            link.href = mediaUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        handleClose();
    };

    const toggleFullScreen = async () => {
        if (screenfull.isEnabled && !screenfull.isFullscreen) {
            const el = document.querySelector(".react-player-container");
            if (isDefined(el)) {
                try {
                    await screenfull.request(el);
                    updateFullscreenState(true);
                } catch {}
            }
        } else if (screenfull.isEnabled && screenfull.isFullscreen) {
            try {
                await screenfull.exit();
                updateFullscreenState(false);
            } catch {}
        }
        handleClose();
    };

    const closePlaybackOptions = () => {
        handleClose();
        setPlaybackAnchorEl(null);
    };

    return (
        <>
            <Box
                component="button"
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick(e);
                }}
            >
                <PlayerSettingsSvg />
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    (e as Event)?.stopPropagation?.();
                    handleClose();
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                classes={{
                    paper: "add__menu--dropdown--paper",
                }}
                disablePortal
            >
                <Box
                    onClick={() => {
                        togglePip();
                        handleClose();
                    }}
                    sx={{
                        p: 1.5,
                    }}
                    gap={1}
                    className="flex items-center react-player--picture__in__option"
                    component={"button"}
                >
                    <PictureInPictureSvg />
                    Picture in Picture
                </Box>
                <Box
                    sx={{
                        p: 1.5,
                    }}
                    gap={1}
                    className="flex items-center"
                    color="textColors.666"
                    component={"button"}
                    onClick={(e) => {
                        setPlaybackAnchorEl(e.currentTarget);
                    }}
                >
                    <SlowMotionVideo />
                    Playback Speed{" "}
                    <Typography variant="medium" color="primary.main">
                        {playerState.playbackRate ?? "1"}x
                    </Typography>
                    <ChevronRight
                        sx={{
                            "&:hover": {
                                color: "primary.main",
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        p: 1.5,
                    }}
                    gap={1}
                    className="flex items-center"
                    component={"button"}
                    color="textColors.666"
                    onClick={() => {
                        toggleFullScreen();
                    }}
                >
                    {screenfull.isFullscreen ? (
                        <FullscreenExitIcon />
                    ) : (
                        <FullscreenIcon />
                    )}
                    {screenfull.isFullscreen
                        ? "Exit Full Screen"
                        : "Full Screen"}
                </Box>
                <Box
                    onClick={() => {
                        handleDownload();
                    }}
                    sx={{
                        p: 1.5,
                    }}
                    gap={1}
                    className="flex items-center"
                    component={"button"}
                    color="textColors.666"
                >
                    <DownloadSvg />
                    Download Call
                </Box>
            </Popover>
            <PlaybackOptions
                anchorEl={playbackAnchorEl}
                onClose={closePlaybackOptions}
            />
        </>
    );
}

const PlaybackOptions = ({
    anchorEl,
    onClose,
}: Pick<PopoverProps, "anchorEl" | "onClose">) => {
    const { handlePlaybackRateChange } = useMediaPlayerContext();
    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={(e, reason) => {
                (e as Event)?.stopPropagation?.();
                onClose?.(e, reason);
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
            classes={{
                paper: "add__menu--dropdown--paper min-w-[120px] max-h-60 overflow-y-auto shadow-md rounded-lg",
            }}
            disablePortal
        >
            {playbackOptions.map((option) => (
                <Box
                    key={option}
                    onClick={() => {
                        handlePlaybackRateChange(option);
                    }}
                    sx={{
                        p: 1.5,
                        "&:hover": {
                            color: (theme) => theme.palette.primary.main,
                        },
                    }}
                    gap={1}
                    className="flex items-center w-full"
                    component={"button"}
                >
                    {option}
                </Box>
            ))}
        </Popover>
    );
};
