import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function ChevronDownSvg({
    sx,
    className = "",
}: {
    sx?: SxProps<Theme>;
    className?: string;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 14 8"
            sx={{ width: 14, height: 8, fill: "none", stroke: "#666", ...sx }}
            className={className}
        >
            <path
                d="M13 7L7 0.999999L1 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
