import { Node, NodeProps } from "reactflow";
import { DefaultNodeType } from "../../../types/bert";
import BlockNodeUI from "./BlockNodeUI";
import EditLegendSvg from "../../svg/EditLegendSvg";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import { Box } from "@mui/system";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import ConfigureSettingSvg from "../../svg/ConfigureSettingSvg";
import { BoxProps, Popover, Typography, alpha } from "@mui/material";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import { isDefined } from "@convin/utils/helper/common.helper";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";

const getOptions: (n: number) => Array<{
    index: number;
    text: string;
}> = (n) => {
    return [
        {
            index: 0,
            text: "All Blocks",
        },
        ...new Array(n ? n - 1 : 0)
            .fill(0)
            .map((_, index) => ({
                text: `Any ${index + 1} Blocks`,
                index: index + 1,
            }))
            .reverse(),
    ];
};

const Options: React.FC<
    ReturnType<typeof getOptions>[0] & Pick<BoxProps, "onClick">
> = ({ text, ...rest }) => {
    return (
        <Box
            sx={(theme) => ({
                py: 1.5,
                px: 2,
                "&:hover": {
                    background: "rgba(26, 98, 242, 0.10)",
                    cursor: "pointer",
                    "*": {
                        color: theme.palette.primary.main,
                    },
                },
            })}
            className="flex items-center justify-between nopan nodrag"
            {...rest}
        >
            <Typography variant="medium">{text}</Typography>
        </Box>
    );
};

const DefaultBlockNode: React.FC<NodeProps<DefaultNodeType>> = ({
    id: _,
    data: {
        metadata: { blocks, selected },
    },
}) => {
    const { open, anchorEl, handleClose, handleClick } =
        useAnchorEl<HTMLButtonElement>();
    const { setNodes } = useReactFlowStateContextProvider();
    const { toggleDrawerState } = useParameterAiConfigContext();
    const selectedBlocks = blocks.filter((e) => e.is_selected);
    return (
        <>
            <BlockNodeUI
                {...{
                    name: isDefined(selected)
                        ? selected
                            ? `Any ${selected} Blocks`
                            : "All Blocks"
                        : "Not Configured",
                    heading: "Default",
                    isDefaultRuleConfig: true,
                    Extra: selectedBlocks.length ? (
                        <Box className="relative overflow-visible">
                            <Box
                                sx={{
                                    bgcolor: (theme) =>
                                        alpha(
                                            theme.palette.textColors[999],
                                            0.1
                                        ),
                                    borderRadius: "6px",
                                }}
                                className="flex-shrink-0 w-[20px] h-[20px] flex items-center justify-center nodrag nopan cursor-pointer"
                                component="button"
                                onClick={(e) => handleClick(e)}
                            >
                                <ConfigureSettingSvg />
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    ),
                }}
            >
                <Box className="flex items-center" gap={0.5}>
                    <button
                        onClick={() => {
                            toggleDrawerState("isDefaultResponseDrawerOpen");
                        }}
                    >
                        <EditLegendSvg />
                    </button>
                    <DeleteButton
                        onDelete={() => {
                            return new Promise(() => {
                                setNodes(() => []);
                            });
                        }}
                        title="Delete Node"
                        message="Are you sure you want to delete this Node?"
                        DeleteOptionComponent={
                            <button>
                                <DeleteLegendSvg />
                            </button>
                        }
                    />
                </Box>
            </BlockNodeUI>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {getOptions(selectedBlocks.length).map((option, idx) => (
                    <Options
                        {...option}
                        key={idx}
                        onClick={() => {
                            setNodes((nodes) =>
                                nodes
                                    .slice(0, 1)
                                    .map((e: Node<DefaultNodeType>) => ({
                                        ...e,
                                        data: {
                                            ...e.data,
                                            metadata: {
                                                ...e.data.metadata,
                                                selected: option.index,
                                            },
                                        },
                                    }))
                            );
                            handleClose();
                        }}
                    />
                ))}
            </Popover>
        </>
    );
};

export default DefaultBlockNode;
