export const internalDomains = [
    "dev",
    "dev1",
    "qa",
    "frontend",
    "staging",
    "team",
    "demo",
    "demo1",
    "product",
    "sales",
    "internal",
    "sales_collection",
];
