import { Box, Typography, alpha } from "@mui/material";
import { BlockType } from "../constants";
import {
    BuildingBlockType,
    MultiResponseNodeType,
    SingleResponseBlockType,
} from "../context/NodeStateContext";

function BlockCard<
    T extends
        | SingleResponseBlockType
        | BuildingBlockType
        | MultiResponseNodeType
>({
    displayName,
    description,
    svgIcon,
    isActive,
    name,
    onClick,
}: BlockType<T> & { isActive: boolean; onClick: (e: T) => void }) {
    return (
        <Box
            sx={(theme) => ({
                bgcolor: isActive ? alpha(theme.palette.primary.main, 0.2) : "",
                p: 1,
                borderRadius: "6px",
                border: isActive
                    ? `1px solid ${theme.palette.primary.main}`
                    : `1px solid ${alpha(theme.palette.textColors[999], 0.2)}`,
            })}
            className="flex items-center cursor-pointer"
            gap={1}
            onClick={() => onClick(name)}
        >
            <Box color="primary.main" component="span">
                {svgIcon}
            </Box>
            <Box className="flex-1 flex flex-col">
                <Typography variant="small" className="font-semibold">
                    {displayName}
                </Typography>
                <Typography variant="small" color="textColors.999">
                    {description}
                </Typography>
            </Box>
        </Box>
    );
}

export default BlockCard;
