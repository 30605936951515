// @mui
import { alpha, useTheme } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";
import { ReactElement } from "react";
import { pxToRem } from "@convin/utils/getFontValue";
// utils

// ----------------------------------------------------------------------

export default function EditorStyle(): ReactElement {
    const theme = useTheme();

    return (
        <GlobalStyles
            styles={{
                ".editor_document_name": {
                    background: "transparent",
                    fontWeight: "bold",
                    fontSize: pxToRem(20),
                    paddingLeft: theme.spacing(1.5),
                    minWidth: "60px",
                    maxWidth: "500px",
                },

                ".editor-container": {
                    border: `1px solid ${alpha(
                        theme.palette.textColors[999],
                        0.2
                    )}`,
                    borderRadius: "12px",
                    background: "white",
                    minHeight: "450px",
                },
                ".toolbar": {
                    borderRadius: "inherit",
                    height: "74px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
                ".editor-scroller": {
                    minHeight: "400px",
                    border: "0",
                    display: "flex",
                    position: "relative",
                    outline: "0",
                    zIndex: "0",
                    overflow: "auto",
                    resize: "vertical",
                    borderRadius: "inherit",
                    fontFamily: "Arial",
                },
                ".editor": {
                    flex: "auto",
                    position: "relative",
                    resize: "vertical",
                    zIndex: "-1",
                },

                "&.editor-editable": {
                    border: "none",
                    outline: "none",
                    fontSize: "16px",

                    "&.editor-paragraph": {
                        margin: 0,
                        position: "relative",
                    },

                    ".editor-text-code": {
                        backgroundColor: "rgb(240, 242, 245)",
                        padding: "1px 0.25rem",
                        fontFamily: "Menlo, Consolas, Monaco, monospace",
                        fontSize: "94%",
                    },
                    ".editor-text-bold": {
                        fontWeight: "bold",
                    },

                    ".editor-text-italic": {
                        fontStyle: "italic",
                    },

                    ".editor-text-underline": {
                        textDecoration: "underline",
                    },

                    ".editor-text-strikethrough": {
                        textDecoration: "line-through",
                    },

                    ".editor-text-underlineStrikethrough": {
                        textDecoration: "underline line-through",
                    },

                    ".editor-heading-h1": {
                        fontSize: "24px",
                        color: "#333",
                        fontWeight: 400,
                        margin: 0,
                    },
                    ".editor-heading-h2": {
                        fontSize: "15px",
                        fontWeight: 700,
                        margin: 0,
                        textTransform: "uppercase",
                    },
                    ".editor-heading-h3": {
                        fontSize: "12px",
                        margin: "0",
                        textTransform: "uppercase",
                    },
                    ".editor__ol1": {
                        padding: 0,
                        margin: 0,
                        listStylePosition: "inside",
                        listStyleType: "number",
                    },
                    ".editor__ol2": {
                        padding: 0,
                        margin: 0,
                        listStyleType: "upper-alpha",
                        listStylePosition: "inside",
                    },
                    ".editor__ol3": {
                        padding: 0,
                        margin: 0,
                        listStyleType: "lower-alpha",
                        listStylePosition: "inside",
                    },

                    ".editor__ol4": {
                        padding: "0",
                        margin: "0",
                        listStyleType: "upper-roman",
                        listStylePosition: "inside",
                    },
                    ".editor__ol5": {
                        padding: "0",
                        margin: "0",
                        listStyleType: "lower-roman",
                        listStylePosition: "inside",
                    },
                    ".editor__ul": {
                        padding: "0",
                        margin: "0",
                        listStylePosition: "inside",
                        listStyleType: "disc",
                    },
                    ".editor__listItem": {
                        margin: "0 32px",
                    },
                    ".editor__listItemChecked, .editor__listItemUnchecked": {
                        position: "relative",
                        marginLeft: "8px",
                        marginRight: "8px",
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        listStyleType: "none",
                        outline: "none",
                    },
                    ".editor__listItemChecked": {
                        textDecoration: "line-through",
                    },
                    ".editor__listItemUnchecked:before, .editor__listItemChecked:before":
                        {
                            content: "''",
                            width: "16px",
                            height: "16px",
                            top: "2px",
                            left: "0",
                            cursor: "pointer",
                            display: "block",
                            backgroundSize: "cover",
                            position: "absolute",
                        },
                    ".editor__listItemUnchecked[dir='rtl']:before, .editor__listItemChecked[dir='rtl']:before":
                        {
                            left: "auto",
                            right: "0",
                        },
                    ".editor__listItemUnchecked:focus:before, .editor__listItemChecked:focus:before":
                        {
                            boxShadow: "0 0 0 2px #a6cdfe",
                            borderRadius: "2px",
                        },
                    ".editor__listItemUnchecked:before": {
                        border: "1px solid #999",
                        borderRadius: "2px",
                    },
                    ".editor__listItemChecked:before": {
                        border: "1px solid rgb(61, 135, 245)",
                        borderRadius: "2px",
                        backgroundColor: "#3d87f5",
                        backgroundRepeat: "no-repeat",
                    },
                    ".editor__listItemChecked:after": {
                        content: "''",
                        cursor: "pointer",
                        borderColor: "#fff",
                        borderStyle: "solid",
                        position: "absolute",
                        display: "block",
                        top: "6px",
                        width: "3px",
                        left: "7px",
                        right: "7px",
                        height: "6px",
                        transform: "rotate(45deg)",
                        borderWidth: "0 2px 2px 0",
                    },
                    ".editor__nestedListItem": {
                        listStyleType: "none",
                    },
                    ".editor__nestedListItem:before, .editor__nestedListItem:after":
                        {
                            display: "none",
                        },
                    ".editor__quote": {
                        margin: "0",
                        marginLeft: "20px",
                        marginBottom: "10px",
                        fontSize: "15px",
                        color: "rgb(101, 103, 107)",
                        borderLeftColor: "rgb(206, 208, 212)",
                        borderLeftWidth: "4px",
                        borderLeftStyle: "solid",
                        paddingLeft: "16px",
                    },
                    ".editor__code": {
                        backgroundColor: "rgb(240, 242, 245)",
                        fontFamily: "Menlo, Consolas, Monaco, monospace",
                        display: "block",
                        padding: "8px 8px 8px 52px",
                        lineHeight: "1.53",
                        fontSize: "13px",
                        margin: "0",
                        marginTop: "8px",
                        marginBottom: "8px",
                        overflowX: "auto",
                        position: "relative",
                        tabSize: "2",
                    },
                    ".editor__code:before": {
                        content: "attr(data-gutter)",
                        position: "absolute",
                        backgroundColor: "#eee",
                        left: "0",
                        top: "0",
                        borderRight: "1px solid #ccc",
                        padding: "8px",
                        color: "#777",
                        whiteSpace: "pre-wrap",
                        textAlign: "right",
                        minWidth: "25px",
                    },

                    ".editor__link": {
                        color: "rgb(33, 111, 219)",
                        textDecoration: "none",
                    },
                    ".editor__link:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                    },

                    ".video-url-input": {
                        width: "100%",
                    },
                    ".editor__embedBlock": {
                        userSelect: "none",
                    },
                    ".editor__embedBlockFocus": {
                        outline: "2px solid rgb(60, 132, 244)",
                    },
                },
                ".editor-dropdown": {
                    "z-index": 100,
                    display: "block",
                    position: "fixed",
                    boxShadow:
                        "0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)",
                    borderRadius: "8px",
                    minHeight: "40px",
                    backgroundColor: "#fff",
                },
                ".editor-dropdown .item": {
                    padding: "12px 16px",
                    color: "#666",
                    cursor: "pointer",
                    lineHeight: "16px",
                    fontSize: "15px",
                    display: "flex",
                    alignContent: "center",
                    flexDirection: "row",
                    flexShrink: 0,
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    border: 0,
                    maxWidth: "250px",
                    minWidth: "100px",
                },
                ".editor-dropdown .item.fontsize-item, .editor-dropdown .item.fontsize-item .text":
                    {
                        minWidth: "unset",
                    },
                ".editor-dropdown .item .active": {
                    display: "flex",
                    width: "20px",
                    height: "20px",
                    backgroundSize: "contain",
                },
                ".editor-dropdown .item:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.2),
                },
                ".editor-dropdown .item .text": {
                    display: "flex",
                    lineHeight: "20px",
                    flexGrow: 1,
                    minWidth: "150px",
                },
                ".editor-dropdown .item .icon": {
                    display: "flex",
                    width: "20px",
                    height: "20px",
                    userSelect: "none",
                    marginRight: "12px",
                    lineHeight: "16px",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                },
                ".editor-dropdown .divider": {
                    width: "auto",
                    backgroundColor: "#eee",
                    margin: "4px 8px",
                    height: "1px",
                },
                ".editor__table": {
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    overflowY: "scroll",
                    overflowX: "scroll",
                    tableLayout: "fixed",
                    width: "max-content",
                    margin: "30px 0",
                },
                ".editor__tableSelection": {
                    "*::selection": {
                        backgroundColor: "transparent",
                    },
                },
                ".editor__tableSelected": {
                    outline: "2px solid rgb(60, 132, 244)",
                },
                ".editor__tableCell": {
                    border: "1px solid #bbb",
                    width: 75,
                    minWidth: 75,
                    verticalAlign: "top",
                    textAlign: "start",
                    padding: "6px 8px",
                    position: "relative",
                    outline: "none",
                },
                ".editor__tableCellSortedIndicator": {
                    display: "block",
                    opacity: 0.5,
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: 4,
                    backgroundColor: "#999",
                },
                ".editor__tableCellResizer": {
                    position: "absolute",
                    right: -4,
                    height: "100%",
                    width: 8,
                    cursor: "ew-resize",
                    zIndex: 10,
                    top: 0,
                },
                ".editor__tableCellHeader": {
                    backgroundColor: "#f2f3f5",
                    textAlign: "start",
                },
                ".editor__tableCellSelected": {
                    backgroundColor: "#c9dbf0",
                },
                ".editor__tableCellPrimarySelected": {
                    border: "2px solid rgb(60, 132, 244)",
                    display: "block",
                    height: "calc(100% - 2px)",
                    position: "absolute",
                    width: "calc(100% - 2px)",
                    left: -1,
                    top: -1,
                    zIndex: 2,
                },
                ".editor__tableCellEditing": {
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
                    borderRadius: 3,
                },
                ".editor__tableAddColumns": {
                    position: "absolute",
                    top: 0,
                    width: 20,
                    backgroundColor: "#eee",
                    height: "100%",
                    right: -25,
                    animation: "table-controls 0.2s ease",
                    border: 0,
                    cursor: "pointer",
                    ":after": {
                        backgroundImage: "url(../images/icons/plus.svg)",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        display: "block",
                        content: '" "',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0.4,
                    },
                    ":hover": {
                        backgroundColor: "#c9dbf0",
                    },
                },
                ".editor__tableAddRows": {
                    position: "absolute",
                    bottom: -25,
                    width: "calc(100% - 25px)",
                    backgroundColor: "#eee",
                    height: 20,
                    left: 0,
                    animation: "table-controls 0.2s ease",
                    border: 0,
                    cursor: "pointer",
                    ":after": {
                        backgroundImage: "url(../images/icons/plus.svg)",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        display: "block",
                        content: '" "',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0.4,
                    },
                    ":hover": {
                        backgroundColor: "#c9dbf0",
                    },
                },
                ".tableControls": {
                    "0%": {
                        opacity: 0,
                    },
                    "100%": {
                        opacity: 1,
                    },
                },
                ".editor__tableCellResizeRuler": {
                    display: "block",
                    position: "absolute",
                    width: 1,
                    backgroundColor: "rgb(60, 132, 244)",
                    height: "100%",
                    top: 0,
                },
                ".editor__tableCellActionButtonContainer": {
                    display: "block",
                    right: 5,
                    top: 6,
                    position: "absolute",
                    zIndex: 4,
                    width: 20,
                    height: 20,
                },
                ".editor__tableCellActionButton": {
                    backgroundColor: "#eee",
                    display: "block",
                    border: 0,
                    borderRadius: 20,
                    width: 20,
                    height: 20,
                    color: "#222",
                    cursor: "pointer",
                    ":hover": {
                        backgroundColor: "#ddd",
                    },
                },

                ".TableCellResizer__resizer": {
                    position: "absolute",
                },

                ".table-cell-action-button-container": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    willChange: "transform",
                },
                ".table-cell-action-button": {
                    backgroundColor: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    border: 0,
                    position: "relative",
                    borderRadius: 15,
                    color: "#222",
                    display: "inline-block",
                    cursor: "pointer",
                },
            }}
        />
    );
}
