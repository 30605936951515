import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function InfoSvg({ sx }: { sx?: SxProps<Theme> }): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 12 12"
            sx={{ width: 12, height: 12, fill: "none", ...sx }}
        >
            <path
                d="M5.4 6.6C5.4 6.44087 5.46321 6.28826 5.57574 6.17574C5.68826 6.06321 5.84087 6 6 6C6.15913 6 6.31174 6.06321 6.42426 6.17574C6.53679 6.28826 6.6 6.44087 6.6 6.6V8.4C6.6 8.55913 6.53679 8.71174 6.42426 8.82426C6.31174 8.93679 6.15913 9 6 9C5.84087 9 5.68826 8.93679 5.57574 8.82426C5.46321 8.71174 5.4 8.55913 5.4 8.4V6.6ZM6 3.3C5.76131 3.3 5.53239 3.39482 5.3636 3.5636C5.19482 3.73239 5.1 3.96131 5.1 4.2C5.1 4.4387 5.19482 4.66761 5.3636 4.8364C5.53239 5.00518 5.76131 5.1 6 5.1C6.2387 5.1 6.46761 5.00518 6.6364 4.8364C6.80518 4.66761 6.9 4.4387 6.9 4.2C6.9 3.96131 6.80518 3.73239 6.6364 3.5636C6.46761 3.39482 6.2387 3.3 6 3.3ZM0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 7.5913 11.3679 9.11742 10.2426 10.2426C9.11742 11.3679 7.5913 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6V6ZM6 1.2C4.72696 1.2 3.50606 1.70571 2.60589 2.60589C1.70571 3.50606 1.2 4.72696 1.2 6C1.2 7.27304 1.70571 8.49394 2.60589 9.39411C3.50606 10.2943 4.72696 10.8 6 10.8C7.27304 10.8 8.49394 10.2943 9.39411 9.39411C10.2943 8.49394 10.8 7.27304 10.8 6C10.8 4.72696 10.2943 3.50606 9.39411 2.60589C8.49394 1.70571 7.27304 1.2 6 1.2V1.2Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
