import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AccountData,
    AccountDetails,
    AccountSearchPayload,
    AccountTag,
    Stage,
} from "@convin/type/Account";
import { PaginationType } from "@convin/type/Common";
import { SearchFilter } from "@convin/type/Search";
import { Conversation } from "@convin/type/Conversation";
import { AccountAuditStat } from "@convin/type/Audit";
import { DownloadReport } from "@convin/type/Report";
import { updateDownloadQue } from "@convin/utils/helper/common.helper";

export const accountsApiSlice = createApi({
    reducerPath: "accountsApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getStages: builder.query<Stage[], void>({
            query: () => "/account/stage/",
            transformResponse: (response: Stage[]) => {
                return Array.isArray(response)
                    ? response?.map((e) => ({ ...e, label: e.stage }))
                    : response;
            },
        }),
        getAccountTags: builder.query<AccountTag[], void>({
            query: () => "/meeting/account_tags/list_all/",
            transformResponse: (response: AccountTag[]) => {
                return Array.isArray(response)
                    ? response?.map((e) => ({ ...e, label: e.tag }))
                    : response;
            },
        }),
        getAccountList: builder.query<
            PaginationType<AccountData>,
            {
                next?: string;
                payload: AccountSearchPayload;
            }
        >({
            query: ({ next, payload }) => ({
                url: next || "/account/list_all/",
                method: "POST",
                body: payload,
            }),
        }),
        getAccountDetails: builder.query<AccountDetails, number>({
            query: (id) => `/account/detail/${id}/`,
        }),
        getAccountConversations: builder.query<
            PaginationType<Conversation>,
            { search_data: SearchFilter[]; next?: string }
        >({
            query: ({ next, ...payload }) => ({
                url: next || "/account/meeting/list/",
                method: "POST",
                body: payload,
            }),
        }),
        getAccountAuditStats: builder.query<
            AccountAuditStat[],
            { account_id: number }
        >({
            query: (params) => ({
                url: "/stats/list_account_scores/",
                params,
                method: "GET",
            }),
        }),
        downloadAuditDump: builder.mutation<
            DownloadReport,
            AccountSearchPayload
        >({
            query: (payload) => ({
                url: "/account/dump/download/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
    }),
});

export const {
    useGetStagesQuery,
    useGetAccountTagsQuery,
    useGetAccountListQuery,
    useLazyGetAccountListQuery,
    useGetAccountDetailsQuery,
    useGetAccountConversationsQuery,
    useLazyGetAccountConversationsQuery,
    useGetAccountAuditStatsQuery,
    useLazyGetAccountAuditStatsQuery,
    useDownloadAuditDumpMutation,
} = accountsApiSlice;
