import { internalDomains } from "@convin/config/domain.config";
import { getDomain } from "@convin/utils/helper/common.helper";
import { PostHogProvider } from "posthog-js/react";
import { PropsWithChildren } from "react";

const options = {
    api_host: import.meta.env.VITE_APP_PRODUCT_ANALYTICS,
    advanced_disable_decide: true,
    disable_compression: true,
    advanced_disable_feature_flags: true,
};

export function PosthogWrapper({ children }: PropsWithChildren) {
    const domain = getDomain();
    const disablePostHog = internalDomains.includes(domain);

    if (disablePostHog) return children;

    return import.meta.env.PROD ? (
        <PostHogProvider
            apiKey={btoa(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY)}
            options={options}
        >
            {children}
        </PostHogProvider>
    ) : (
        children
    );
}
