import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";

export const authApiSlice = createApi({
    reducerPath: "authApiSlice",
    keepUnusedDataFor: 0,
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        logout: builder.mutation<unknown, void>({
            query: () => ({
                url: "/person/logout/",
                method: "POST",
            }),
        }),
        genrateSalesAssistToken: builder.query<
            {
                token: string;
                user_id: number;
            },
            void
        >({
            query: () => "/live_video/saleassist_token/",
        }),
    }),
});

export const { useLogoutMutation, useGenrateSalesAssistTokenQuery } =
    authApiSlice;
