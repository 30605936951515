import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AITagTranscript,
    ConversationDetails,
    ConversationSnippet,
    PreviewConversation,
    TagTranscripts,
    Transcript,
} from "@convin/type/Conversation";
import { AxiosResponse } from "axios";
import { Template } from "@convin/type/Audit";
import { showToast } from "@convin/utils/toast";
import { LMSMedia } from "@convin/type/lms";
import { PaginationType } from "@convin/type/Common";

export const conversationsApiSlice = createApi({
    reducerPath: "conversationsApiSlice",
    baseQuery: axiosBaseQuery({ transformResponse: (response) => response }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getConversationDetails: builder.query<ConversationDetails, string>({
            query: (id) => `/meeting/meeting/retrieve/${id}/`,
        }),
        updateConversationDetails: builder.mutation<
            ConversationDetails,
            Partial<{ call_types: number[]; tags: number[] }> & {
                call_id: number;
            }
        >({
            query: ({ call_id, ...payload }) => ({
                url: `/meeting/meeting/update/${call_id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted({ call_id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        conversationsApiSlice.util.updateQueryData(
                            "getConversationDetails",
                            call_id.toString(),
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                } catch {}
            },
        }),
        getConversationMediaUrl: builder.query<
            { location: string },
            string | number
        >({
            query: (id) => `/meeting/meeting/media/${id}/`,
        }),
        getConversationTranscript: builder.query<Transcript[], string>({
            query: (id) => `/meeting/meeting/transcript/${id}/`,
        }),
        downloadTranscript: builder.mutation<Blob, string>({
            query: (conversationId) => ({
                url: `/meeting/meeting/streaming-transcript/${conversationId}/`,
                method: "GET",
                body: {},
                responseType: "blob",
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data, meta } = await queryFulfilled;
                    const { headers } = meta as Omit<AxiosResponse, "data">;
                    const contentDisposition = headers?.["content-disposition"];
                    let filename = "transcript";

                    if (contentDisposition) {
                        const filenameMatch = contentDisposition.match(
                            /filename[^;=\n]*=['"]?(.*?)['"]?$/
                        );
                        if (filenameMatch && filenameMatch[1]) {
                            filename = filenameMatch[1].trim();
                        }
                    }

                    const href = URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link); // Append to body to ensure visibility in some browsers
                    link.click();
                    document.body.removeChild(link); // Clean up
                    URL.revokeObjectURL(href);
                } catch (err) {}
            },
        }),
        getApplicableTemplatesForConversation: builder.query<
            Template[],
            number
        >({
            query: (id) => `/audit/v2/templates/${id}/`,
        }),
        requestFeedback: builder.mutation<
            { success?: string },
            { meeting_id: number; user_id: number[] }
        >({
            query: (payload) => ({
                url: "meeting/meeting/feedback/request/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if ("success" in data) {
                        showToast({
                            type: "sucess",
                            message: "Email sent successfully",
                        });
                    }
                } catch {}
            },
        }),
        addSnippetToLms: builder.mutation<
            LMSMedia,
            {
                category: number;
                start_time: number;
                end_time: number;
                note?: string;
                meeting: number;
            }
        >({
            query: (payload) => ({
                url: "/library/meeting/",
                method: "POST",
                body: payload,
                params: {
                    category: payload.category,
                },
            }),
        }),
        shareConversationSnippet: builder.mutation<
            ConversationSnippet,
            {
                start_time: number;
                end_time: number;
                comment?: string;
                meeting: number;
                public: boolean;
                emails?: string[];
                expires_in: number;
                share_with_transcript: boolean;
            }
        >({
            query: (payload) => ({
                url: "/share/share_meeting/",
                method: "POST",
                body: payload,
            }),
        }),
        getConversationSnippets: builder.query<
            PaginationType<ConversationSnippet>,
            {
                next?: string;
                meeting_id: number;
            }
        >({
            query: ({ next, meeting_id }) => ({
                url: next || "/share/snippet/list/",
                method: "GET",
                params: { meeting_id },
            }),
        }),
        deleteConversationSnippet: builder.mutation<
            PaginationType<ConversationSnippet>,
            {
                meeting_id: number;
                id: number;
            }
        >({
            query: ({ id }) => ({
                url: `/share/snippet/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(
                { meeting_id, id },
                { dispatch, queryFulfilled }
            ) {
                const deleteResult = dispatch(
                    conversationsApiSlice.util.updateQueryData(
                        "getConversationSnippets",
                        { meeting_id },
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                count: draft.count - 1,
                                results: draft.results.filter(
                                    (e) => e.id !== id
                                ),
                            });
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
        getAiTagedTranscripts: builder.query<TagTranscripts, number>({
            query: (id) => `audit/tag_snippets/${id}/`,
        }),
        createAiTagedTranscripts: builder.mutation<
            TagTranscripts,
            { score: number; snippets: AITagTranscript[] }
        >({
            query: (payload) => ({
                url: "audit/tag_snippets/create/",
                method: "POST",
                body: payload,
            }),
        }),
        updateAiTagedTranscripts: builder.mutation<
            TagTranscripts,
            { id: number; snippets: AITagTranscript[] }
        >({
            query: ({ id, snippets }) => ({
                url: `audit/tag_snippets/${id}/`,
                method: "PATCH",
                body: { snippets },
            }),
        }),
        createQmsConversation: builder.mutation<
            ConversationDetails,
            Record<string, unknown>
        >({
            query: (payload) => ({
                url: "/calendar/qms/",
                method: "POST",
                body: payload,
            }),
        }),
        getPreviewMeeting: builder.query<PreviewConversation, string>({
            query: (id) => `/share/stream_media/?share_id=${id}`,
        }),
    }),
});

export const {
    useGetConversationDetailsQuery,
    useUpdateConversationDetailsMutation,
    useGetConversationMediaUrlQuery,
    useGetConversationTranscriptQuery,
    useDownloadTranscriptMutation,
    useGetApplicableTemplatesForConversationQuery,
    useLazyGetApplicableTemplatesForConversationQuery,
    useRequestFeedbackMutation,
    useAddSnippetToLmsMutation,
    useShareConversationSnippetMutation,
    useGetConversationSnippetsQuery,
    useLazyGetConversationSnippetsQuery,
    useDeleteConversationSnippetMutation,
    useLazyGetAiTagedTranscriptsQuery,
    useCreateAiTagedTranscriptsMutation,
    useUpdateAiTagedTranscriptsMutation,
    useCreateQmsConversationMutation,
    useGetPreviewMeetingQuery,
} = conversationsApiSlice;
