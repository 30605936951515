import PropTypes from "prop-types";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(() => ({
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
    // "*::-webkit-scrollbar": {
    //     width: "6px",
    //     height: "6px",
    // },
    // "*::-webkit-scrollbar-corner": { background: "transparent" },
    // "*::-webkit-scrollbar-thumb": {
    //     backgroundColor: "transparent",
    //     borderRadius: "100px",
    // },
    // "*:hover": {
    //     scrollbarColor: "#99999966 transparent" /* For Firefox */,
    // },
    // "*:hover::-webkit-scrollbar-thumb": {
    //     backgroundColor: "#99999966",
    // },
    // "*::-webkit-scrollbar-track": {
    //     background: "transparent",
    // },
}));

// ----------------------------------------------------------------------

Scrollbar.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,
};

export default function Scrollbar({ children, sx, ...other }) {
    const userAgent =
        typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

    const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            userAgent
        );

    if (isMobile) {
        return (
            <Box sx={{ overflowX: "auto", ...sx }} {...other}>
                {children}
            </Box>
        );
    }

    return <RootStyle>{children}</RootStyle>;
}
