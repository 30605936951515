/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import BertCardWrapper from "../BertCardWrapper";
import { Handle, NodeProps, Position } from "reactflow";
import { Box, Popover, Stack, Typography } from "@mui/material";
import ConditionSvg from "../../svg/ConditionSvg";
import BertMoreOptionsSvg from "../../svg/BertMoreOptionsSvg";
import Block from "./MultiResponseBlock";
import { MultipleResponseNode } from "../../../types/bert";
import HandlerWrapper from "../Handlers/HandlerWrapper";
import CreateMutiResponseBlockType from "./CreateMutiResponseBlockType";
import {
    getParameterResponseOptions,
    isDefined,
} from "@convin/utils/helper/common.helper";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import EditLegendSvg from "../../svg/EditLegendSvg";
import NodeStatePorvider from "../../context/NodeStatePorvider";
import CreateEditConditionalNode from "../CreateEditConditionalNode";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import ResponseBlockSvg from "../../svg/ResponseBlockSvg";
import CreateEditResponseNode from "../CreateEditResponseNode";
import InfoLegendSvg from "../../svg/InfoLegendSvg";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import { Label } from "@convin/components/custom_components/Typography/Label";

const MultiResponseNode: React.FC<
    NodeProps<MultipleResponseNode<"condition">>
> = (props) => {
    const { deleteNode, nodes } = useReactFlowStateContextProvider();
    const { data, isConnectable, id, targetPosition } = props;
    const {
        open: toolBarOpen,
        handleClick: handleToolbarClick,
        handleClose: handleToolbarClose,
        anchorEl: toolbarAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();
    const {
        open: editOpen,
        handleClick: handleEditClick,
        handleClose: handleEditClose,
        anchorEl: editAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();
    const {
        open: infoOpen,
        handleClick: handleInfoClick,
        handleClose: handleInfoClose,
        anchorEl: infoAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();

    const isCondtionNode = data.type === "condition";

    return (
        <BertCardWrapper
            sx={{
                width: "250px",
                p: 1.5,
            }}
        >
            {isConnectable && isDefined(targetPosition) && (
                <Handle
                    type={"target"}
                    id={`${id}`}
                    position={targetPosition}
                />
            )}

            <Box className="flex items-center justify-between w-full" mb={2}>
                <Box
                    className="flex items-center flex-1 overflow-hidden"
                    gap={2}
                >
                    <Box
                        sx={{
                            bgcolor: isCondtionNode ? "#8741F9" : "#503C3C",
                            color: "white",
                            borderRadius: "6px",
                        }}
                        className="h-[30px] w-[30px] flex items-center justify-center flex-shrink-0"
                    >
                        {isCondtionNode ? (
                            <ConditionSvg />
                        ) : (
                            <ResponseBlockSvg />
                        )}
                    </Box>
                    <Box className="flex flex-col overflow-hidden flex-1">
                        <Typography
                            variant="small"
                            color="textColors.999"
                            className="uppercase"
                        >
                            {isCondtionNode ? "Condition" : "Response"}
                        </Typography>
                        <Label
                            variant="medium"
                            colorType="333"
                            hasTooltip
                            isEllipses
                            className="font-semibold w-full"
                        >
                            {data.metadata.name}
                        </Label>
                    </Box>
                </Box>
                <button onClick={handleToolbarClick} className="flex-shrink-0">
                    <BertMoreOptionsSvg />
                </button>
            </Box>
            <Stack gap={1} className="w-full">
                {data.metadata.blocks.map((block, idx) => {
                    return (
                        <HandlerWrapper
                            AddComponent={CreateMutiResponseBlockType}
                            {...props}
                            sourcePosition={block.sourcePosition ?? undefined}
                            blockId={block.id}
                            key={block.sourcePosition ? block.id : idx}
                            hideSourceHandles={
                                isCondtionNode
                                    ? !isDefined(block.data.type)
                                    : true
                            }
                        >
                            <Block
                                key={block.id}
                                {...block}
                                parent={id}
                                isConditionBlock={isCondtionNode}
                                hideDelete={
                                    isCondtionNode
                                        ? data.metadata.blocks.length <= 2
                                        : data.metadata.blocks.length <= 1
                                }
                            />
                        </HandlerWrapper>
                    );
                })}
            </Stack>
            <Popover
                anchorEl={toolbarAnchor}
                open={toolBarOpen}
                onClose={handleToolbarClose}
                transformOrigin={{ horizontal: -10, vertical: "center" }}
                anchorOrigin={{ horizontal: "right", vertical: "center" }}
                slotProps={{
                    paper: {
                        sx: {
                            bgcolor: "transparent",
                            border: "none",
                        },
                    },
                }}
            >
                <Box className="flex items-center flex-col" gap={1}>
                    <button onClick={handleInfoClick}>
                        <InfoLegendSvg />
                    </button>
                    <button onClick={handleEditClick}>
                        <EditLegendSvg />
                    </button>
                    {isConnectable || (!isConnectable && nodes.length === 1) ? (
                        <DeleteButton
                            onDelete={() => {
                                return new Promise(() => {
                                    deleteNode(id);
                                    handleToolbarClose();
                                });
                            }}
                            title="Delete Node"
                            message="Are you sure you want to delete this Node?"
                            DeleteOptionComponent={
                                <button>
                                    <DeleteLegendSvg />
                                </button>
                            }
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Popover>
            <Popover
                open={editOpen || infoOpen}
                anchorEl={editAnchor || infoAnchor}
                onClose={editOpen ? handleEditClose : handleInfoClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <NodeStatePorvider
                    onNodeSaveCallBack={() => {
                        handleEditClose();
                        handleToolbarClose();
                    }}
                    nodeIdToUpdate={id}
                    nodeDataToUpdate={props.data.metadata}
                >
                    <BertCardWrapper
                        className="w-[400px]"
                        sx={{
                            ...((infoOpen || !editOpen) && {
                                "& button": {
                                    display: "none",
                                },

                                "& *": {
                                    userSelect: "none",
                                    pointerEvents: "none",
                                },
                            }),
                        }}
                    >
                        {isCondtionNode ? (
                            <CreateEditConditionalNode />
                        ) : (
                            <CreateEditResponseNode />
                        )}
                    </BertCardWrapper>
                </NodeStatePorvider>
            </Popover>
        </BertCardWrapper>
    );
};

export default MultiResponseNode;
