// ----------------------------------------------------------------------

import { alpha } from "@mui/material";

export default function Pagination(theme) {
    return {
        MuiPagination: {
            styleOverrides: {
                root: {
                    "& .MuiPaginationItem-root": {
                        borderRadius: "50%",
                    },
                    "& .Mui-selected": {
                        border: "1px solid",
                        borderColor: theme.palette.primary.main,
                        borderRadius: "50%",
                        backgroundColor: `${alpha(
                            theme.palette.primary.main,
                            0.1
                        )} !important`,
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
    };
}
