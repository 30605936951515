import React from "react";
import Icon from "@ant-design/icons";
const Svg = () => (
    <svg
        width="25"
        height="20"
        viewBox="0 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.0083 17.1109L8.23069 18.4968C7.96315 18.5931 7.67421 18.5824 7.41204 18.4646C7.15521 18.3469 6.95723 18.1329 6.85557 17.86L6.03692 15.6288L5.03101 15.998L5.84965 18.2292C6.04763 18.7643 6.43822 19.1924 6.95727 19.4331C7.24621 19.5669 7.55122 19.6311 7.8562 19.6311C8.10233 19.6311 8.35382 19.5883 8.59461 19.4973L12.3723 18.1115C13.4799 17.7048 14.0524 16.4742 13.6457 15.3666L13.3086 14.4463L12.3027 14.8155L12.6398 15.7358C12.8485 16.2923 12.5595 16.9076 12.0084 17.1109L12.0083 17.1109Z"
            fill="currentColor"
        />
        <path
            d="M19.9433 6.73581C20.0235 6.94983 20.2269 7.08896 20.4462 7.08896C20.5051 7.08896 20.5693 7.07826 20.6282 7.05685L22.6507 6.3131C22.929 6.21144 23.0681 5.90645 22.9664 5.62821C22.8648 5.34997 22.5598 5.21085 22.2815 5.31252L20.259 6.05626C19.9861 6.15258 19.8416 6.45757 19.9433 6.73583L19.9433 6.73581Z"
            fill="currentColor"
        />
        <path
            d="M18.1239 2.87816C18.2148 2.93702 18.3165 2.96912 18.4182 2.96912C18.5894 2.96912 18.7606 2.88351 18.8623 2.72834L20.1197 0.834164C20.2856 0.588033 20.216 0.256309 19.9699 0.0904172C19.7237 -0.0754742 19.392 -0.00589861 19.2261 0.240238L17.974 2.13441C17.8135 2.3859 17.8777 2.71765 18.1239 2.87816H18.1239Z"
            fill="currentColor"
        />
        <path
            d="M23.8975 11.1237L21.7144 10.4923C21.4308 10.4121 21.1311 10.5726 21.0509 10.8562C20.9707 11.1398 21.1312 11.4394 21.4148 11.5196L23.5979 12.151C23.646 12.1671 23.6995 12.1724 23.7477 12.1724C23.9778 12.1724 24.1918 12.0226 24.2614 11.7872C24.3416 11.5036 24.1811 11.2039 23.8975 11.1237H23.8975Z"
            fill="currentColor"
        />
        <path
            d="M2.1947 15.8319L3.16319 15.6714L1.36534 10.7754L0.519938 11.2783C0.0811742 11.5405 -0.111438 12.0809 0.0651233 12.5679L1.01219 15.1415C1.18876 15.6285 1.68637 15.9174 2.19471 15.8318L2.1947 15.8319Z"
            fill="currentColor"
        />
        <path
            d="M16.3636 14.0288C16.3904 14.1037 16.4439 14.1358 16.476 14.1519C16.5081 14.1679 16.5669 14.184 16.6419 14.1572L18.6002 13.4402L14.5872 2.49805L12.6288 3.21503C12.5164 3.25784 12.4629 3.38091 12.5004 3.48792L16.3636 14.0288Z"
            fill="currentColor"
        />
        <path
            d="M15.1007 13.6863L11.7565 4.56836L2.30151 10.2134L4.2385 15.489L15.1007 13.6863Z"
            fill="currentColor"
        />
    </svg>
);

function SpeakerSvg(props) {
    return <Icon component={Svg} {...props} />;
}

export default SpeakerSvg;
