export const STORE_ACTION_ITEMS = "STORE_ACTION_ITEMS";
export const STORE_MOMENTS = "STORE_MOMENTS";
export const STORE_TOPICS = "STORE_TOPICS";
export const STORE_MONOLOGUE_TOPICS = "STORE_MONOLOGUE_TOPICS";
export const STORE_QUESTIONS = "STORE_QUESTIONS";
export const STORE_CLUBBED_TRANSCRIPT = "STORE_CLUBBED_TRANSCRIPT";
export const STORE_ACTUAL_TRANSCRIPT = "STORE_ACTUAL_TRANSCRIPT";
export const STORE_CALL_ID = "STORE_CALL_ID";
export const STORE_CALL_DETAILS = "STORE_CALL_DETAILS";
export const STORE_MONOLOGUES = "STORE_MONOLOGUES";
export const STORE_INDIVIDUAL_CALLS = "STORE_INDIVIDUAL_CALLS";
export const SET_CALL_COMMENTS = "SET_CALL_COMMENTS";
export const SET_CALL_COMMENT_TO_REPLY = "SET_CALL_COMMENT_TO_REPLY";
export const SET_CALL_COMMENTS_LOADER = "SET_CALL_COMMENTS_LOADER";
export const GET_SNIPPETS = "GET_SNIPPETS";
export const STORE_SNIPPETS = "STORE_SNIPPETS";
export const SET_SNIPPET_TO_UPDATE = "SNIPPET_TO_UPDATE";
