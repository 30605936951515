import { useContext } from "react";
import { IReactFlowStateContext } from "../constants";
import ReactFlowStateContext from "../context/ReactFlowStateContext";

export const useReactFlowStateContextProvider = (): IReactFlowStateContext => {
    const context = useContext(ReactFlowStateContext);
    if (!context)
        throw new Error("Bert context must be use inside Bert Provider");

    return context;
};
