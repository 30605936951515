import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { PaginationType } from "@convin/type/Common";
import {
    Report,
    ScheduledReport,
    ScheduledReportField,
} from "@convin/type/Report";

import axiosBaseQuery from "../axiosBaseQuery";

export const scheduledReportsApiSlice = createApi({
    reducerPath: "scheduledReportsApiSlice",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["Scheduled Report"],
    endpoints: (builder) => ({
        getScheduledReports: builder.query<
            PaginationType<ScheduledReport>,
            { [key: string]: unknown }
        >({
            query: (params) => ({
                url: "analytics/schedule_reports/list_all/",
                params,
            }),
            providesTags: ["Scheduled Report"],
        }),
        createScheduledReport: builder.mutation<
            ScheduledReport,
            ScheduledReportField
        >({
            query: (payload) => ({
                url: "analytics/reports/create/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, err) => (!!err ? [] : ["Scheduled Report"]),
        }),
        updateScheduledReport: builder.mutation<
            ScheduledReport,
            Partial<ScheduledReportField> & Pick<ScheduledReport, "id">
        >({
            query: ({ id, ...payload }) => ({
                url: `analytics/schedule_reports/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            invalidatesTags: (_, err) => (!!err ? [] : ["Scheduled Report"]),
        }),
        deleteScheduledReport: builder.mutation<ScheduledReport, number>({
            query: (id) => ({
                url: `analytics/schedule_reports/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (!!err ? [] : ["Scheduled Report"]),
        }),
        getAllReports: builder.query<Report[], void>({
            query: () => "analytics/reports/list/",
        }),
    }),
});

export const {
    useGetScheduledReportsQuery,
    useCreateScheduledReportMutation,
    useUpdateScheduledReportMutation,
    useDeleteScheduledReportMutation,
    useGetAllReportsQuery,
} = scheduledReportsApiSlice;
