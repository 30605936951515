export const CREATEQUESTION = "CREATEQUESTION";
export const STOREQUESTION = "STOREQUESTION";
export const STOREUSERFEEDBACKS = "STOREUSERFEEDBACKS";
export const STOREALLFEEDBACKS = "STOREALLFEEDBACKS";
export const STOREALLNOTES = "STOREALLNOTES";
export const STORE_ALL_FB_CATEGORIES = "STORE_ALL_FB_CATEGORIES";
export const CREATE_FB_CATEGORY = "CREATE_FB_CATEGORY";
export const SET_ACTIVE_FB_CATEGORY = "SET_ACTIVE_FB_CATEGORY";
export const SET_FB_LOADING = "SET_FB_LOADING";
export const SET_NOTES_LOADING = "SET_NOTES_LOADING";
