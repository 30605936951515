import { alpha } from "@mui/material";

export default function Skeleton(theme) {
    return {
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(theme.palette.textColors[333], 0.07),
                    "&::after": {
                        background: `linear-gradient(
                                90deg,
                                transparent,
                                rgba(145, 158, 171, 0.3),
                                transparent
                              )`,
                    },
                },
            },
        },
    };
}
