import { isDefined } from "@convin/utils/helper/common.helper";
import { styled, Tooltip, Typography, TypographyProps } from "@mui/material";
import { ElementType, ReactNode } from "react";

export type LabelProps = TypographyProps & {
    colorType: "999" | "666" | "333" | "primary" | "white";
    component?: ElementType;
    isEllipses?: boolean;
    hasTooltip?: boolean;
    tooltipNode?: ReactNode;
};

export const Label = styled(
    ({
        variant,
        className,
        sx,
        isEllipses,
        hasTooltip = false,
        component,
        children,
        tooltipNode,
        ...rest
    }: LabelProps) =>
        hasTooltip &&
        (typeof children === "string" || isDefined(tooltipNode)) ? (
            <Tooltip placement="top-start" title={tooltipNode ?? children}>
                <Typography
                    variant={variant}
                    className={
                        isEllipses
                            ? `text-ellipsis overflow-hidden whitespace-nowrap ${
                                  className ?? ""
                              }`
                            : className
                    }
                    sx={{ ...sx }}
                    component={component ?? "div"}
                    {...rest}
                >
                    {children}
                </Typography>
            </Tooltip>
        ) : (
            <Typography
                variant={variant}
                className={
                    isEllipses
                        ? `text-ellipsis overflow-hidden whitespace-nowrap ${
                              className ?? ""
                          }`
                        : className
                }
                sx={{ ...sx }}
                component={component ?? "div"}
                {...rest}
            >
                {children}
            </Typography>
        ),
    {
        shouldForwardProp: (prop) => prop !== "colorType",
    }
)(({ theme, colorType }) => ({
    color:
        theme.palette.mode === "light"
            ? colorType === "white"
                ? theme?.palette?.common?.white
                : colorType === "primary"
                ? theme?.palette?.primary?.main
                : theme?.palette?.textColors?.[colorType]
            : theme?.palette?.common?.white,
}));
