import { Box, Skeleton } from "@mui/material";
import { ReactElement } from "react";

export default function SkeletonLoader(): ReactElement {
    return (
        <Box
            className="flex flex-col flex-1"
            sx={{ px: 2 }}
            data-testid="skeleton-loader"
        >
            {new Array(6).fill(0).map((_, idx) => (
                <Box sx={{ py: 1 }} key={idx}>
                    <Skeleton animation="wave" />
                </Box>
            ))}
        </Box>
    );
}
