const apiErrors = {
    AXIOSNETWORKERROR: "Network Error",
    AXIOSNETWORKERRORMSG:
        "Unable to reach server. Please check your network connection!",
    AXIOSERRORSTATUS: "axios-error",
    AXIOSDC400ERROR: "domain-creation-bad-request",
    AXIOSDC400MSG: "Oh Snap! Domain creation failed. Please try again.",
    AXIOSCOMMONERROR: "Something went wrong! Please retry after some time.",
};

export default apiErrors;
