export const STORE_FIELDS = "STORE_FIELDS";
export const STORE_CALLS = "STORE_CALLS";
export const STORE_NEXT_URL = "STORE_NEXT_URL";
export const STORE_COUNT = "STORE_COUNT";
export const STORE_TRACKERS = "STORE_TRACKERS";
export const SET_FETCHING = "SET_FETCHING";
export const STORE_SEARCH_URL = "STORE_SEARCH_URL";
export const STORE_CONF_TOOLS = "STORE_CONF_TOOLS";
export const SET_ACTIVE_SEARCH_VIEW = "SET_ACTIVE_SEARCH_VIEW";
export const SET_SEARCH_VIEWS = "SET_SEARCH_VIEWS";
export const GET_SEARCH_VIEWS = "GET_SEARCH_VIEWS";
export const CREATE_SEARCH_VIEW = "CREATE_SEARCH_VIEW";
export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
export const SET_ACTIVE_SEARCH_FILTERS = "SET_ACTIVE_SEARCH_FILTERS";
export const CLEAR_SEARCH_FILTERS = "CLEAR_SEARCH_FILTERS";
export const SET_COMPLETED_CALLS_AS_DEFAULT = "SET_COMPLETED_CALLS_AS_DEFAULT";
export const UPDATE_SEARCH_VIEWS = "UPDATE_SEARCH_VIEWS";
export const SET_DEFAULT_SEARCH_FILTERS = "SET_DEFAULT_SEARCH_FILTERS";
export const SET_SAMPLING_RULES = "SET_SAMPLING_RULES";
export const SET_ACTIVE_SAMPLING_RULE = "SET_ACTIVE_SAMPLING_RULE";
export const SET_SAMPLING_RULES_LOADING = "SET_SAMPLING_RULES_LOADING";
