import {
    FormProvider,
    RHFSelect,
    RHFTextField,
} from "@convin/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Skeleton, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
    ParameterBlockFormType,
    SingleResponseNode,
} from "../../../types/bert";
import useNodeContext from "../../hooks/useNodeContext";
import { useGetTemplateInfoByIdQuery } from "@convin/redux/services/settings/auditManager.service";
import {
    getParameterResponseOptions,
    getParameters,
    isDefined,
} from "@convin/utils/helper/common.helper";
import { useParams } from "react-router-dom";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import BlockHeader from "./BlockHeader";

const schema = yup.object().shape({
    name: yup.string().required("Name is Required"),
    parameter: yup.number().nullable(true).required("Parameter is Required"),
    response: yup.number().nullable(true).required("Response is Required"),
});

const ParameterBlock = ({
    showCreateButton = true,
}: {
    showCreateButton?: boolean;
}) => {
    const { template_id, parameter_id } = useParams<{
        template_id: string;
        parameter_id: string;
    }>();
    const { data, isLoading } = useGetTemplateInfoByIdQuery(
        { template_id: +template_id, questions: "all" },
        {
            skip: !isDefined(template_id),
        }
    );
    const allParameters = getParameters(data)
        .filter((e) => e.id !== +parameter_id)
        .filter((e) => e.question_type !== "none");

    const {
        sourceId,
        direction,
        onNodeSaveCallBack,
        nodeIdToUpdate,
        nodeDataToUpdate,
        blockParentId,
    } = useNodeContext();

    const { createSingleResponseNode, updateSingleResponseNode } =
        useReactFlowStateContextProvider();

    const methods = useForm<ParameterBlockFormType>({
        resolver: yupResolver(schema),
        values: isDefined(nodeDataToUpdate)
            ? { ...(nodeDataToUpdate as ParameterBlockFormType) }
            : {
                  name: "",
                  parameter: null,
                  response: null,
              },
    });

    const { watch, setValue } = methods;

    const activeParameterId = watch("parameter");

    const reposneOptions =
        getParameterResponseOptions(
            allParameters.find((e) => e.id === activeParameterId)
        ) ?? [];

    const onSubmit = async (values: ParameterBlockFormType) => {
        if (isDefined(nodeIdToUpdate)) {
            updateSingleResponseNode<ParameterBlockFormType>({
                data: values,
                sourceId: nodeIdToUpdate,
                blockParentId,
            });
        } else
            createSingleResponseNode<SingleResponseNode<"parameter">>({
                data: {
                    type: "parameter",
                    is_not: false,
                    metadata: values,
                },
                sourceId,
                direction,
                blockParentId,
            });
        onNodeSaveCallBack();
    };

    const { handleSubmit } = methods;

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            className="w-full"
        >
            <BlockHeader
                title="Parameter"
                showCreateButton={showCreateButton}
            />

            <Box
                sx={{
                    width: "100%",
                    maxWidth: "400px",
                }}
            >
                <Stack gap={1.5} className="w-full">
                    <RHFTextField
                        name="name"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        placeholder="Block Name"
                        label="Block Name"
                    />
                    <RHFSelect
                        name="parameter"
                        className="w-full nodrag nopan"
                        variant="outlined"
                        size="small"
                        label="Select Parameter"
                        options={
                            allParameters?.map((e) => ({
                                label: e.question_text,
                                id: e.id,
                            })) || []
                        }
                        onChangeCallback={() => {
                            setValue("response", null);
                        }}
                    />
                    <RHFSelect
                        name="response"
                        className="w-full nodrag nopan"
                        variant="outlined"
                        size="small"
                        label="Select Response"
                        options={reposneOptions}
                    />
                </Stack>
            </Box>
        </FormProvider>
    );
};

export default ParameterBlock;
