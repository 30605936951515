import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function DeleteLegendSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <rect width="24" height="24" rx="6" fill="#F93739" />
            <mask
                id="mask0_1185_38433"
                maskUnits="userSpaceOnUse"
                x="5"
                y="5"
                width="14"
                height="14"
            >
                <rect x="5" y="5" width="14" height="14" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1185_38433)">
                <path
                    d="M9.08203 17.25C8.7612 17.25 8.48655 17.1358 8.25807 16.9073C8.0296 16.6788 7.91536 16.4042 7.91536 16.0833V8.5H7.33203V7.33333H10.2487V6.75H13.7487V7.33333H16.6654V8.5H16.082V16.0833C16.082 16.4042 15.9678 16.6788 15.7393 16.9073C15.5109 17.1358 15.2362 17.25 14.9154 17.25H9.08203ZM14.9154 8.5H9.08203V16.0833H14.9154V8.5ZM10.2487 14.9167H11.4154V9.66667H10.2487V14.9167ZM12.582 14.9167H13.7487V9.66667H12.582V14.9167Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}
