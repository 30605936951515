import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, {
    AutocompleteProps,
    createFilterOptions,
} from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SxProps, Theme, UseAutocompleteProps } from "@mui/material";
import { isDefined } from "@convin/utils/helper/common.helper";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Fragment } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface CustomAutoCompleteProps<
    T extends { id: number | string; label: string }
> {
    autocompleteProps: UseAutocompleteProps<
        T,
        true,
        boolean | undefined,
        boolean | undefined
    > &
        Partial<
            Omit<
                AutocompleteProps<
                    T,
                    true,
                    boolean | undefined,
                    boolean | undefined
                >,
                "options"
            >
        > & {
            options: T[];
        };
    label: string;
    loading?: boolean;
    values: T[];
    setValues?: (val: T[]) => void;
    className?: string;
    limitTags?: number;
    sx?: SxProps<Theme>;
    error?: boolean;
    helperText?:
        | string
        | FieldError
        | Merge<FieldError, FieldErrorsImpl<never>>;
    placeholder?: string;
}

const AIResponseSelector = <T extends { id: number | string; label: string }>(
    props: CustomAutoCompleteProps<T>
): JSX.Element => {
    const {
        label,
        values = [],
        setValues = () => {
            return;
        },
        error = false,
        helperText = "",
        placeholder = "Search",
        autocompleteProps: { options, ...rest },
        ...other
    } = props;
    const handleOptionChange: CustomAutoCompleteProps<T>["autocompleteProps"]["onChange"] =
        (e, value) => {
            if (isDefined(value) && Array.isArray(value)) {
                if (
                    value.find(
                        (option) =>
                            typeof option !== "string" &&
                            (option as T)?.id === "all"
                    )
                )
                    return setValues(
                        value.length === options.length + 1 ? [] : options
                    );

                setValues(value as T[]);
            }
        };

    return (
        <Autocomplete
            multiple
            disableListWrap
            id="checkboxes-tags-demo"
            disableCloseOnSelect
            options={options}
            getOptionLabel={(option) => (option as T).label}
            filterOptions={(options, params) => {
                // <<<--- inject the Select All option
                const filter = createFilterOptions<T>();
                const filtered = filter(options, params) as T[];
                return [
                    !!options.length && {
                        label: "Select All",
                        id: "all",
                        all: true,
                    },
                    ...filtered,
                ].filter(Boolean) as T[];
            }}
            renderOption={(props, option, { selected }) => {
                return (
                    <Fragment key={option.id}>
                        <li
                            {...props}
                            data-value={option.label}
                            title={option.label}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                    option?.id === "all"
                                        ? values.length === options.length
                                        : selected
                                }
                            />
                            {option.label}
                        </li>
                    </Fragment>
                );
            }}
            renderInput={(params) => {
                const { InputProps, ...rest } = params;
                const { startAdornment, ...restInputProps } = InputProps;
                return (
                    <TextField
                        {...rest}
                        label={label}
                        placeholder={placeholder}
                        InputProps={{
                            ...restInputProps,
                            startAdornment: (
                                <div className="overflow-auto max-h-20">
                                    {startAdornment}
                                </div>
                            ),
                        }}
                        error={error}
                        helperText={<>{helperText}</>}
                    />
                );
            }}
            value={options.filter((option) =>
                values.map((e) => e.id).includes(option.id)
            )}
            onChange={handleOptionChange}
            {...rest}
            {...other}
        />
    );
};

export default AIResponseSelector;
