import {
    AuditStateType,
    AuditStatus,
    CustomScoreObject,
    ManualScore,
    ScoreObject,
} from "@convin/type/Audit";
import {
    formatFloatNumber,
    getDurationInSeconds,
    isDefined,
} from "./common.helper";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { violationManagerApiSlice } from "@convin/redux/services/settings/violationManager.service";
import { auditSheetApiSlice } from "@convin/redux/services/audit/auditSheet.service";

/**
 * @description return the calculated score for the score given to a partuicular question's response
 *
 * @export
 * @param {(Pick<CustomScoreObject, "score_given" | "question_data"> & {
 *     deductionList: CustomScoreObject[];
 * })} {
 *     score_given,
 *     question_data: { question_type, settings },
 *     deductionList,
 * }
 * @return {*}  {number}
 */
export function getCalculatedScore({
    score_given,
    question_data: { question_type, settings },
    deductionList,
}: Pick<CustomScoreObject, "score_given" | "question_data"> & {
    deductionList: CustomScoreObject[];
}): number {
    let score = 0;
    if (score_given === null || score_given === -1) {
        return 0;
    }

    if (question_type === "yes_no") {
        score =
            score_given === 1
                ? settings.yes_weight ?? 0
                : score_given === 0
                ? settings.no_weight ?? 0
                : 0;
    } else if (question_type === "rating") {
        const weight = settings.weight || 0;
        score = (weight * score_given) / 10;
    } else if (question_type === "custom") {
        const weight = settings.custom.find(
            (response) => response.id === score_given
        )?.weight;
        score = weight ?? 0;
    }
    const decuctionScoreObjectToApply = [...deductionList]
        ?.sort((a, b) => b.idx - a.idx)
        .at(0);

    if (isDefined(decuctionScoreObjectToApply)) {
        const { question_data, score_given } = decuctionScoreObjectToApply;
        const deductionToApply = question_data?.settings?.custom.find(
            (e) => e.id === score_given
        );
        if (deductionToApply?.weight) {
            score = formatFloatNumber(
                score - (score * deductionToApply.weight) / 100,
                2
            );
        }
    }
    return score;
}

/**
 *
 * @description Returns the list of violation id's for a particular response
 * @export
 * @param {(Pick<ScoreObject, "score_given" | "question_data">)} {
 *     score_given,
 *     question_data,
 * }
 * @return {*}  {number[]}
 */
export function extractViolationFromScoreObject({
    score_given,
    question_data,
}: Pick<ScoreObject, "score_given" | "question_data">): number[] {
    const { question_type, settings } = question_data;
    const { violation } = settings || {};
    if (!violation) return [];
    if (question_type === "yes_no") {
        if (score_given === 1) {
            return violation.yes_weight || [];
        }
        if (score_given === 0) {
            return violation.no_weight || [];
        }

        return [];
    }
    if (question_type === "rating" && isDefined(score_given)) {
        return violation?.[score_given] || [];
    }

    if (question_type === "custom") {
        const score_repr = getCustomScoreDisplay({
            question_data,
            score_given,
        });
        if (score_repr) return violation?.[score_repr] || [];
    }

    return [];
}

/**
 *  @description Returns custom question response name for the score given
 *
 * @param {(Pick<ScoreObject, "question_data" | "score_given">)} { question_data: {settings}, score_given }
 * @return {*}
 */
const getCustomScoreDisplay = ({
    question_data: { settings },
    score_given,
}: Pick<ScoreObject, "question_data" | "score_given">) => {
    if (typeof settings === "object") {
        for (const response of settings?.custom) {
            //score_given can be null, compare with random integer to make condition False
            if (isDefined(response.id)) {
                if (response.id === score_given) {
                    return response["name"];
                }
            }
        }
    }
};

export function calculateManualScore({
    scoreObjects,
    questionListWithTemplateViolation,
    questionListWithCategoryViolation,
}: {
    scoreObjects: CustomScoreObject[];
    questionListWithTemplateViolation: ScoreObject["question_data"][];
    questionListWithCategoryViolation: ScoreObject["question_data"][];
}): Partial<AuditStatus<ManualScore>["scores"]> {
    const isDependent = (category: number) => {
        if (questionListWithTemplateViolation?.length) return true;
        if (
            questionListWithCategoryViolation.find(
                (e) => e.category === category
            )
        )
            return true;
        return false;
    };

    const scores: Pick<
        AuditStatus<ManualScore>["scores"],
        | "template_ques_audited"
        | "template_max_marks"
        | "draft_template_marks_audited"
        | "draft_template_score"
        | number
    > = {
        template_ques_audited: 0,
        template_max_marks: 0,
        draft_template_marks_audited: 0,
        draft_template_score: 0,
    };

    for (let i = 0; i < scoreObjects.length; i++) {
        const score = scoreObjects[i];
        const categoryPk = score?.question_data?.category;
        if (!scores[categoryPk]) {
            scores[categoryPk] = {
                category_score: 0,
                category_ques_audited: 0,
                category_max_marks: 0,
                category_marks_audited: 0,
                category_non_fatal_score: 0,
            };
        }
        const weight = getMaximumScore(score);
        scores.template_max_marks += weight;
        if (isDefined(scores[categoryPk].category_max_marks))
            scores[categoryPk].category_max_marks +=
                score.score_given === -1 ? 0 : weight;

        if (
            score.score_given !== null ||
            score.question_data?.question_type === "none"
        ) {
            scores[categoryPk].category_ques_audited++;
        }

        if (
            score.score_given === null &&
            isDependent(score?.question_data?.category)
        ) {
            scores.draft_template_marks_audited += weight;
        }

        if (score.score_given !== null) {
            scores.template_ques_audited++;

            if (
                score.score_given !== -1 &&
                !score?.question_data?.settings?.is_deduction
            ) {
                const dependent = isDependent(score?.question_data?.category);
                scores.draft_template_score += dependent
                    ? 0
                    : score.calculated_score || 0;

                scores[categoryPk].category_score += dependent
                    ? 0
                    : score.calculated_score || 0;
                scores[categoryPk].category_non_fatal_score +=
                    score.non_fatal_score || 0;
                scores.draft_template_marks_audited += weight;
                scores[categoryPk].category_marks_audited += weight;
            }
        }
    }
    return scores;
}

function getMaximumScore(instance: CustomScoreObject) {
    const settings = instance?.question_data?.settings;
    if (settings?.is_deduction) {
        return 0;
    }
    if (instance?.question_data?.question_type === "yes_no") {
        return Math.max(settings.yes_weight ?? 0, settings.no_weight ?? 0);
    } else if (instance?.question_data?.question_type === "custom") {
        const weights = settings.custom.map((c) => c.weight);
        if (weights.length) {
            return Math.max(...weights);
        } else {
            return -1;
        }
    } else if (instance?.question_data?.question_type === "rating") {
        return settings.weight;
    } else {
        return 0;
    }
}

export const updateAuditScores = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<any, any, AnyAction>,
    scoreObjects: CustomScoreObject[],
    payload: AuditStateType
): Promise<{
    dispatchAuditCalculation: PatchCollection;
    dispatchGetScores: PatchCollection;
    dispatchUpdateManaualScores: PatchCollection;
}> => {
    const { data: violations } = await dispatch(
        violationManagerApiSlice.endpoints.getViolations.initiate()
    );
    const deductionList: CustomScoreObject[] = scoreObjects
        .filter(
            (score) =>
                score.question_data.settings.is_deduction && score.score_given
        )
        .filter(
            (question) =>
                !!question.question_data?.settings?.custom.find(
                    (e) => e.id === question.score_given
                )?.weight
        );
    let questionListWithTemplateViolation: ScoreObject["question_data"][] = [];
    let questionListWithCategoryViolation: ScoreObject["question_data"][] = [];
    scoreObjects.forEach((scoreObject) => {
        const violation_ids = extractViolationFromScoreObject(scoreObject);
        const { question_data } = scoreObject;
        const applicableViolationList = violations?.filter(({ id }) =>
            violation_ids?.includes(id)
        );
        const isTemplateViolation = applicableViolationList?.filter(
            ({ applicability }) => applicability === "template"
        ).length;
        const isCategoryViolation = applicableViolationList?.filter(
            ({ applicability }) => applicability === "category"
        ).length;
        if (isTemplateViolation) {
            questionListWithTemplateViolation = [
                ...questionListWithTemplateViolation.filter(
                    (e) => e.id !== question_data.id
                ),
                question_data,
            ];
            questionListWithCategoryViolation =
                questionListWithCategoryViolation.filter(
                    (e) => e.id !== question_data.id
                );
        } else if (isCategoryViolation) {
            questionListWithCategoryViolation = [
                ...questionListWithCategoryViolation.filter(
                    (e) => e.id !== question_data.id
                ),
                question_data,
            ];
        } else {
            questionListWithTemplateViolation =
                questionListWithTemplateViolation.filter(
                    (e) => e.id !== question_data.id
                );
            questionListWithCategoryViolation =
                questionListWithCategoryViolation.filter(
                    (e) => e.id !== question_data.id
                );
        }
    });

    const dispatchAuditCalculation = dispatch(
        auditSheetApiSlice.util.updateQueryData(
            "auidtCalculationVariables",
            { template_id: payload.template_id },
            (draft) => {
                draft.deductionList = deductionList;
                draft.questionListWithCategoryViolation =
                    questionListWithCategoryViolation;
                draft.questionListWithTemplateViolation =
                    questionListWithTemplateViolation;
            }
        )
    );
    const scoreObjectsWithCalculatedScore = scoreObjects.map((e) => ({
        ...e,
        calculated_score: getCalculatedScore({
            ...e,
            deductionList: deductionList,
        }),
    }));
    const dispatchGetScores = dispatch(
        auditSheetApiSlice.util.updateQueryData(
            "getScoreObjects",
            payload,
            (draft) => {
                Object.assign(draft, scoreObjectsWithCalculatedScore);
            }
        )
    );

    const dispatchUpdateManaualScores = dispatch(
        auditSheetApiSlice.util.updateQueryData(
            "getAuditStatus",
            { ...payload, status_for: "manual" },
            (draft) => {
                Object.assign(draft, {
                    ...draft,
                    ...(isDefined(draft.audited_date)
                        ? {
                              is_draft: scoreObjects
                                  ?.filter(
                                      (e) =>
                                          new Date(e.created).getTime() <
                                          new Date(
                                              draft.audited_date!
                                          ).getTime()
                                  )
                                  ?.some((e) => e.is_in_draft),
                          }
                        : {
                              is_draft: scoreObjects
                                  ?.filter(
                                      (e) =>
                                          isDefined(e.created) &&
                                          isDefined(e.updated) &&
                                          Math.floor(
                                              getDurationInSeconds(
                                                  new Date(
                                                      e.created
                                                  ).toISOString(),
                                                  new Date(
                                                      e.updated
                                                  ).toISOString()
                                              )
                                          ) > 0
                                  )
                                  ?.some((e) => e.is_in_draft),
                          }),
                    scores: {
                        ...draft.scores,
                        ...calculateManualScore({
                            scoreObjects: scoreObjectsWithCalculatedScore,
                            questionListWithCategoryViolation,
                            questionListWithTemplateViolation,
                        }),
                    },
                });
            }
        )
    );

    return {
        dispatchAuditCalculation,
        dispatchGetScores,
        dispatchUpdateManaualScores,
    };
};
