import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { ScoreSense, scoreSenseType } from "@convin/type/ScoreSense";

export const scoreSenseApiSlice = createApi({
    reducerPath: "scoreSenseApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getScoreSenseConfig: builder.query<ScoreSense<scoreSenseType>[], void>({
            query: () => "gpt/score_sense/",
        }),
        updateScoreSenseConfig: builder.mutation<
            ScoreSense<scoreSenseType>,
            Pick<ScoreSense<scoreSenseType>, "id"> &
                Partial<ScoreSense<scoreSenseType>>
        >({
            query: (payload) => ({
                url: `gpt/score_sense/${payload.id}/`,
                method: "PATCH",
                body: payload,
            }),
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        scoreSenseApiSlice.util.updateQueryData(
                            "getScoreSenseConfig",
                            undefined,
                            (draft) =>
                                draft.map((e) => (e.id === data.id ? data : e))
                        )
                    );
                } catch {}
            },
        }),
    }),
});

export const {
    useGetScoreSenseConfigQuery,
    useUpdateScoreSenseConfigMutation,
} = scoreSenseApiSlice;
