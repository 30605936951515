import { Box, Paper } from "@mui/material";
import { BoxProps } from "@mui/system";
import { PropsWithChildren } from "react";

export default function BertCardWrapper({
    children,
    sx,
    ...rest
}: PropsWithChildren<BoxProps>) {
    return (
        <Box
            {...rest}
            sx={{
                p: 2.5,
                border: "1px solid",
                borderColor: "rgba(153, 153, 153, 0.20)",
                borderRadius: "12px",
                boxShadow: "0px 4px 20px 0px rgba(51, 51, 51, 0.10)",
                position: "relative",
                ...sx,
            }}
            component={Paper}
        >
            {children}
        </Box>
    );
}
