export const SET_LOADING = "SET_LOADING";
export const STORE_TEMPLATES_FOR_AUDIT = "STORE_TEMPLATES_FOR_AUDIT";
export const STORE_CATEGORIES = "STORE_CATEGORIES";
export const STORE_QUESTIONS = "STORE_QUESTIONS";
export const STORE_AVAILABLE_TEAMS_FOR_AUDIT =
    "STORE_AVAILABLE_TEAMS_FOR_AUDIT";
export const STORE_NON_AVAILABLE_TEAMS_FOR_AUDIT =
    "STORE_NON_AVAILABLE_TEAMS_FOR_AUDIT";
export const DISABLE_LOADING = "DISABLE_LOADING";
export const SAVING_NOTE = "SAVING_NOTE";
export const STORE_SCORE_DETAILS = "STORE_SCORE_DETAILS";
export const STORE_AUDIT_TEMPLATE = "STORE_AUDIT_TEMPLATE";
export const AUDIT_DONE = "AUDIT_DONE";
export const SHOW_AUDIT_INCOMPLETE = "SHOW_AUDIT_INCOMPLETE";
export const RESET_CALL_AUDIT = "RESET_CALL_AUDIT";
export const CALL_AUDIT_OVERALL_DETAILS = "CALL_AUDIT_OVERALL_DETAILS";
export const ACCOUNT_AUDIT_OVERALL_DETAILS = "ACCOUNT_AUDIT_OVERALL_DETAILS";
export const STORE_FILTER = "STORE_FILTER";
export const STORE_DELETED_FILTER = "STORE_DELETED_FILTER";
export const STORE_CATEGORY_QUESTION_OBJ = "STORE_CATEGORY_QUESTION_OBJ";
export const STORE_GLOBAL_EXPRESSIONS = "STORE_GLOBAL_EXPRESSIONS";
export const LOADING = "AUDIT_LOADING";
export const STORE_AI_AUDIT_SCORE = "STORE_AI_AUDIT_SCORE";
export const SET_SEARCH_AUDIT_TEMPLATE = "SET_SEARCH_AUDIT_TEMPLATE";
export const SET_LEAD_SCORE_FOR_TEMPLATE = "SET_LEAD_SCORE_FOR_TEMPLATE";
export const STORE_FILTER_SEETINGS_TEMPLATE = "STORE_FILTER_SEETINGS_TEMPLATE";
export const SET_IS_ACCOUNT_LEVEL = "SET_IS_ACCOUNT_LEVEL";
export const SET_IS_MANUAL_LEVEL = "SET_IS_MANUAL_LEVEL";
export const SET_RUN_COMMAND_LOADING = "SET_RUN_COMMAND_LOADING";
export const SET_PARAMETERS_LOADING = "SET_PARAMETERS_LOADING";
export const STORE_WORD_CLOUD = "STORE_WORD_CLOUD";
export const SET_SCORE_DETAILS_LOADING = "SET_SCORE_DETAILS_LOADING";
export const STORE_ALL_AUDIT_TEMPLATE = "STORE_ALL_AUDIT_TEMPLATE";
export const SET_AI_LOADING = "SET_AI_LOADING";
export const CREATE_MANUAL_CALL = "CREATE_MANUAL_CALL";
export const NEW_CALL_CREATED = "NEW_CALL_CREATED";
