import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function SingleBlockSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <mask
                id="mask0_1174_33817"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1174_33817)">
                <path
                    d="M9.91683 16.8337L3 9.91683L9.91683 3L16.8337 9.91683L9.91683 16.8337Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
