import { ChevronDownSvg } from "@convin/components/svg";
import { isDefined } from "@convin/utils/helper/common.helper";
import { LoadingButton } from "@mui/lab";
import { Box, Typography, Button } from "@mui/material";
import useNodeContext from "../../hooks/useNodeContext";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";

export default function BlockHeader({
    showCreateButton,
    title,
}: {
    showCreateButton?: boolean;
    title: string;
}) {
    const { isDefaultRuleConfig, isSaving } =
        useReactFlowStateContextProvider();
    const { dispatch, nodeIdToUpdate, onNodeSaveCallBack } = useNodeContext();
    return (
        <Box
            className="flex items-center justify-between flex-shrink-0"
            sx={{ mb: 2 }}
        >
            <Box
                onClick={() => {
                    if (isDefined(nodeIdToUpdate)) return;
                    dispatch({
                        payload: { selectedBlockType: null },
                        type: "UPDATE",
                    });
                }}
                className="flex items-center"
            >
                {!isDefined(nodeIdToUpdate) && (
                    <ChevronDownSvg
                        sx={{
                            transform: "rotate(270deg)",
                            width: 16,
                            height: 16,
                            cursor: "pointer",
                        }}
                    />
                )}

                <Typography variant="medium16" sx={{ fontWeight: 600, ml: 1 }}>
                    {title}
                </Typography>
            </Box>
            <Box
                sx={{
                    ...(isDefaultRuleConfig && {
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                    }),
                }}
                className="flex"
                gap={2}
            >
                {isDefined(nodeIdToUpdate) ? (
                    <>
                        {isDefaultRuleConfig && (
                            <Button
                                variant="outlined"
                                onClick={onNodeSaveCallBack}
                            >
                                Cancel
                            </Button>
                        )}
                        <LoadingButton
                            type="submit"
                            className={
                                isDefaultRuleConfig ? "h-[40px]" : "h-[30px]"
                            }
                            variant="global"
                            loading={isSaving}
                        >
                            Save
                        </LoadingButton>
                    </>
                ) : showCreateButton ? (
                    <LoadingButton
                        type="submit"
                        className={
                            isDefaultRuleConfig ? "h-[40px]" : "h-[30px]"
                        }
                        variant="global"
                        loading={isSaving}
                    >
                        {isDefaultRuleConfig ? "Add Block" : "Create"}
                    </LoadingButton>
                ) : null}
            </Box>
        </Box>
    );
}
