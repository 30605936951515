import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    MomentType,
    RuleEngineConfig,
} from "@convin/modules/settings/modules/auditManager/components/types/bert";

export const auditAiConfig = createApi({
    reducerPath: "auditAiConfig",
    keepUnusedDataFor: 0,
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getAIAuditConfig: builder.query<
            RuleEngineConfig,
            { question_id: number }
        >({
            query: (payload) => ({
                url: "audit/rule/edit/",
                method: "GET",
                params: payload,
            }),
        }),
        createAIAuditConfig: builder.mutation<
            RuleEngineConfig,
            Omit<
                RuleEngineConfig,
                "id" | "config" | "created_at" | "updated_at" | "published_at"
            >
        >({
            query: (payload) => ({
                url: "audit/rule/",
                method: "POST",
                body: payload,
            }),
        }),
        runRule: builder.mutation<
            void,
            { question_id: number; meeting_id: number }
        >({
            query: (payload) => ({
                url: "audit/rule/execute/",
                method: "POST",
                body: payload,
            }),
        }),
        updateAIAuditConfig: builder.mutation<
            RuleEngineConfig,
            Omit<RuleEngineConfig, "config" | "updated_at">
        >({
            query: (payload) => ({
                url: "audit/rule/edit/",
                method: "PUT",
                body: payload,
            }),
            async onQueryStarted(
                { question_id },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditAiConfig.util.updateQueryData(
                            "getAIAuditConfig",
                            {
                                question_id,
                            },
                            (draft) => {
                                return { ...draft, ...data };
                            }
                        )
                    );
                } catch {}
            },
        }),
        saveAIAuditConfig: builder.mutation<
            RuleEngineConfig,
            { validate: boolean; question_id: number } & Omit<
                RuleEngineConfig,
                "config" | "internal_config" | "published_at"
            >
        >({
            query: (payload) => ({
                url: "audit/rule/edit/",
                method: "PUT",
                body: payload,
            }),
            async onQueryStarted(
                { question_id },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditAiConfig.util.updateQueryData(
                            "getAIAuditConfig",
                            {
                                question_id,
                            },
                            (draft) => {
                                return { ...draft, ...data };
                            }
                        )
                    );
                } catch {}
            },
        }),
        deleteAIAuditConfig: builder.mutation<void, { rule_id: string }>({
            query: (payload) => ({
                url: "audit/rule/edit/",
                method: "DELETE",
                body: payload,
            }),
        }),
        createMoment: builder.mutation<MomentType, Omit<MomentType, "id">>({
            query: (payload) => ({
                url: "audit/moments/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditAiConfig.util.updateQueryData(
                            "getMoments",
                            undefined,
                            (draft) => ({
                                moments: [...draft.moments, data],
                            })
                        )
                    );
                } catch {}
            },
        }),
        updateMoment: builder.mutation<MomentType, MomentType>({
            query: (payload) => ({
                url: "audit/moments/edit/",
                method: "PUT",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditAiConfig.util.updateQueryData(
                            "getMoments",
                            undefined,
                            (draft) => ({
                                moments: draft.moments.map((e) =>
                                    e.id === data.id ? data : e
                                ),
                            })
                        )
                    );
                } catch {}
            },
        }),
        getMoments: builder.query<{ moments: MomentType[] }, void>({
            query: () => "audit/moments/",
        }),
    }),
});

export const {
    useCreateMomentMutation,
    useUpdateMomentMutation,
    useGetMomentsQuery,
    useGetAIAuditConfigQuery,
    useCreateAIAuditConfigMutation,
    useUpdateAIAuditConfigMutation,
    useDeleteAIAuditConfigMutation,
    useSaveAIAuditConfigMutation,
    useRunRuleMutation,
    useLazyGetAIAuditConfigQuery,
} = auditAiConfig;
