import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export default function DeleteSvg({ sx, ...rest }: SvgIconProps): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 14 16"
            sx={{ width: 14, height: 16, fill: "#333", ...sx }}
            {...rest}
            data-testid="DeleteSvgIcon"
        >
            <path
                d="M2.83331 15.5C2.37498 15.5 1.98262 15.3368 1.65623 15.0104C1.32984 14.684 1.16665 14.2917 1.16665 13.8333V3H0.333313V1.33333H4.49998V0.5H9.49998V1.33333H13.6666V3H12.8333V13.8333C12.8333 14.2917 12.6701 14.684 12.3437 15.0104C12.0173 15.3368 11.625 15.5 11.1666 15.5H2.83331ZM11.1666 3H2.83331V13.8333H11.1666V3ZM4.49998 12.1667H6.16665V4.66667H4.49998V12.1667ZM7.83331 12.1667H9.49998V4.66667H7.83331V12.1667Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
