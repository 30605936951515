import { alpha } from "@mui/material";

// ----------------------------------------------------------------------

export default function AutoComplete(theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    boxShadow: "0px 4px 16px 2px rgba(51, 51, 51, 0.10)",
                    border: "1px solid rgba(153, 153, 153, 0.20)",
                    borderRadius: theme.shape.borderRadius,
                    minWidth: "196px",
                    padding: 0,
                    marginTop: "8px",
                },
                root: {
                    "&.requiredMark": {
                        ".MuiFormLabel-root": {
                            "&:after": {
                                content: '"*"',
                                color: "#fd6c6f",
                            },
                        },
                    },
                    ".MuiChip-filled": {
                        background: alpha(theme.palette.primary.main, 0.2),
                        color: theme.palette.primary.main,
                    },
                },
                popper: {
                    minWidth: "196px",
                },
                listbox: {
                    padding: 0,
                },
            },
        },
    };
}
