import {
    AuditorDashboardGraphFields,
    AuditorListColumnKeys,
    CollectionCategoryType,
    CsatCategoryType,
    LeadStageType,
} from "@convin/type/Analytics";

export const defaultCompositionGraphColors = ["#1AC468", "#F8AA0D", " #F93739"];

export const auditDashboardGraphToColumnMap: Record<
    AuditorDashboardGraphFields,
    AuditorListColumnKeys
> = {
    accounts: "account_audit_details",
    agents: "agent_audit_details",
    calls: "call_audit_details",
    minutes: "minute_audit_details",
    audits_performed: "audits_performed_details",
};

export const leadStages: LeadStageType[] = ["hot", "warm", "cold"];
export const csatCategories: CsatCategoryType[] = [
    "satisfied",
    "neutral",
    "dissatisfied",
];
export const collectionCategories: CollectionCategoryType[] = [
    "good",
    "average",
    "poor",
];

[
    null,
    {
        hour: 0,
        minute: 0,
        day_of_week: "*",
        day_of_month: "*",
    },
    {
        hour: 0,
        minute: 0,
        day_of_week: "monday",
        day_of_month: "*",
    },
    {
        hour: 0,
        minute: 0,
        day_of_week: "*",
        day_of_month: "1",
    },
];

export const NotificationOptions = [
    { id: "Immediately", label: "Immediately" },
    { id: "Daily", label: "Daily" },
    { id: "Weekly", label: "Weekly" },
    { id: "Monthly", label: "Monthly" },
];

export const NotificationSettings: Record<
    string,
    null | Record<string, string | number>
> = {
    Immediately: null,
    Daily: {
        hour: 0,
        minute: 0,
        day_of_week: "*",
        day_of_month: "*",
    },
    Weekly: {
        hour: 0,
        minute: 0,
        day_of_week: "monday",
        day_of_month: "*",
    },
    Monthly: {
        hour: 0,
        minute: 0,
        day_of_week: "*",
        day_of_month: "1",
    },
};

export type NotificationSettingsValues =
    (typeof NotificationSettings)[keyof typeof NotificationSettings];
