import { NodeProps } from "reactflow";
import { MomentReferenceType, SingleResponseNode } from "../../../types/bert";
import { Box, Popover } from "@mui/material";

import HandlerWrapper from "../Handlers/HandlerWrapper";
import CreateSingleResponseBlockType from "./CreateSingleResponseBlockType";
import EditLegendSvg from "../../svg/EditLegendSvg";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import NotOperatorLegendSvg from "../../svg/NotOperatorLegendSvg";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { SingleResponseBlockType } from "../../context/NodeStateContext";
import BlockNodeUI from "./BlockNodeUI";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import AttributeBlock from "../Blocks/AttributeBlock";
import BertCardWrapper from "../BertCardWrapper";
import ParameterBlock from "../Blocks/ParameterBlock";
import MomentsBlock from "../Blocks/MomentsBlock";
import PredefinedFuncBlock from "../Blocks/PredefinedFuncBlock";
import NodeStatePorvider from "../../context/NodeStatePorvider";
import { useGetMomentsQuery } from "@convin/redux/services/settings/auditAiConfig.service";
import InfoLegendSvg from "../../svg/InfoLegendSvg";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";

const typeMap: Record<
    SingleResponseNode<NonNullable<SingleResponseBlockType>>["type"],
    string
> = {
    attribute: "Attribute",
    moment: "Moment",
    function: "Function",
    parameter: "Parameter",
};

const SingleBlockNode: React.FC<
    NodeProps<SingleResponseNode<NonNullable<SingleResponseBlockType>>>
> = (props) => {
    const { isGroupBlockTree } = useReactFlowStateContextProvider();
    const {
        open: editOpen,
        anchorEl: editAnchor,
        handleClick: handleEditClick,
        handleClose: handleEditClose,
    } = useAnchorEl<HTMLButtonElement | null>();
    const {
        open: infoOpen,
        handleClick: handleInfoClick,
        handleClose: handleInfoClose,
        anchorEl: infoAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();
    const { deleteNode, setNodes } = useReactFlowStateContextProvider();

    const {
        id,
        data: { type, is_not, metadata },
    } = props;

    const { name } = metadata;

    const { data, isLoading } = useGetMomentsQuery(undefined, {
        skip: type !== "moment",
    });

    return (
        <HandlerWrapper AddComponent={CreateSingleResponseBlockType} {...props}>
            <BlockNodeUI
                {...{
                    name:
                        type === "moment"
                            ? data?.moments?.find(
                                  (e) =>
                                      e.id ===
                                      (metadata as MomentReferenceType).id
                              )?.name ?? ""
                            : name,
                    heading: typeMap[type],
                    is_not,
                    isLoading,
                }}
            >
                <Box className="flex items-center" gap={0.5}>
                    <button onClick={handleInfoClick}>
                        <InfoLegendSvg />
                    </button>
                    <button onClick={handleEditClick}>
                        <EditLegendSvg />
                    </button>
                    <DeleteButton
                        onDelete={() => {
                            return new Promise(() => {
                                deleteNode(id);
                            });
                        }}
                        title="Delete Node"
                        message="Are you sure you want to delete this Node?"
                        DeleteOptionComponent={
                            <button>
                                <DeleteLegendSvg />
                            </button>
                        }
                    />

                    <button
                        onClick={() => {
                            setNodes((nodes) =>
                                nodes.map((e) =>
                                    e.id === id
                                        ? {
                                              ...e,
                                              data: {
                                                  ...e.data,
                                                  is_not: !is_not,
                                              },
                                          }
                                        : e
                                )
                            );
                        }}
                    >
                        <NotOperatorLegendSvg />
                    </button>
                </Box>
            </BlockNodeUI>
            <Popover
                open={editOpen || infoOpen}
                anchorEl={editAnchor || infoAnchor}
                onClose={editOpen ? handleEditClose : handleInfoClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: isGroupBlockTree ? "bottom" : "top",
                    horizontal: "center",
                }}
                sx={{
                    ".MuiPaper-root": {
                        borderRadius: "10px",
                    },
                }}
            >
                <NodeStatePorvider
                    onNodeSaveCallBack={() => handleEditClose()}
                    nodeIdToUpdate={id}
                    nodeDataToUpdate={props.data.metadata}
                >
                    <BertCardWrapper
                        className="w-[400px]"
                        sx={{
                            ...((infoOpen || !editOpen) && {
                                "& button": {
                                    display: "none",
                                },

                                "& *": {
                                    userSelect: "none",
                                    pointerEvents: "none",
                                },
                            }),
                        }}
                    >
                        {type === "attribute" ? <AttributeBlock /> : <></>}
                        {type === "parameter" ? <ParameterBlock /> : <></>}
                        {type === "moment" ? <MomentsBlock /> : <></>}
                        {type === "function" ? <PredefinedFuncBlock /> : <></>}
                    </BertCardWrapper>
                </NodeStatePorvider>
            </Popover>
        </HandlerWrapper>
    );
};

export default SingleBlockNode;
