import { useRegisterSW } from "virtual:pwa-register/react";

const useAppUpdate = () => {
    const {
        needRefresh: [needRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegistered(_r) {
            // console.log("SW Registered: " + JSON.stringify(r));
        },
        onRegisterError(_error) {
            // console.log("SW registration error", error);
        },
    });

    return { needRefresh, updateServiceWorker }; // The modal content is shown in the toast, so return null here
};

export default useAppUpdate;
