import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useSearchQuery(): URLSearchParams {
    const location = useLocation();
    const [search, setSearch] = useState<URLSearchParams>(
        new URLSearchParams(location.search)
    );

    useEffect(() => {
        setSearch(new URLSearchParams(location.search));
    }, [location.search]);

    return search;
}
