import useNodeContext from "../hooks/useNodeContext";
import AttributeBlock from "./Blocks/AttributeBlock";
import MomentsBlock from "./Blocks/MomentsBlock";
import ParameterBlock from "./Blocks/ParameterBlock";
import PredefinedFuncBlock from "./Blocks/PredefinedFuncBlock";
import CreateEditConditionalNode from "./CreateEditConditionalNode";
import CreateEditResponseNode from "./CreateEditResponseNode";

const ConfigureSingleResponseBlockType = () => {
    const { state } = useNodeContext();
    return (
        <>
            {state.selectedBlockType === "attribute" ? (
                <AttributeBlock showCreateButton />
            ) : (
                <></>
            )}
            {state.selectedBlockType === "parameter" ? (
                <ParameterBlock showCreateButton />
            ) : (
                <></>
            )}
            {state.selectedBlockType === "moment" ? (
                <MomentsBlock showCreateButton />
            ) : (
                <></>
            )}
            {state.selectedBlockType === "function" ? (
                <PredefinedFuncBlock showCreateButton />
            ) : (
                <></>
            )}
            {state.selectedBlockType === "condition" ? (
                <CreateEditConditionalNode />
            ) : (
                <></>
            )}
            {state.selectedBlockType === "response" ? (
                <CreateEditResponseNode />
            ) : (
                <></>
            )}
        </>
    );
};

export default ConfigureSingleResponseBlockType;
