import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { AIRecommendationQueryResponse } from "@convin/type/Recommendation.model";
import { ConversationsWithSnippets } from "@convin/type/customerIntelligence";
import { PaginationType } from "@convin/type/Common";

export const recommendationApiSlice = createApi({
    reducerPath: "recommendationApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getRecommendations: builder.query<
            AIRecommendationQueryResponse,
            string
        >({
            query: (query: string) => ({
                url: "/search/phrase/",
                method: "GET",
                params: {
                    phrase: query,
                },
            }),
        }),
        getRecommendationPhraseSnippets: builder.query<
            PaginationType<ConversationsWithSnippets>,
            {
                next?: string;
                payload: string;
            }
        >({
            query: ({ next, payload }) => ({
                url: next || `/search/meeting/phrase/?phrase=${payload}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useLazyGetRecommendationsQuery,
    useGetRecommendationPhraseSnippetsQuery,
    useLazyGetRecommendationPhraseSnippetsQuery,
} = recommendationApiSlice;
