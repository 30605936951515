const auditConfig = {
    CREATE_TEMPLATE: "CERATE_TEMPLAET",
    NO_TEMPLATES: "NO_TEMPLATES",
    VIEW_TEMAPLATES: "VIEW_TEMPLATES",
    VIEW_CATEGORIES: "VIEW_CATEGORIES",
    VIEW_QUESTIONS: "VIEW_QUESTIONS",
    CRITICAL: "Critical",
    RED_ALERT: "Red alert",
    ZERO_TOLERANCE: "Zero tolerance",
    BOOLEAN_TYPE: "yes_no",
    RATING_TYPE: "rating",
    SCALE_NO: 11,
    FILTER_LIST_TAB: "FILTER_LIST_TAB",
    EDIT_FILTERS_TAB: "EDIT_FILTERS_TAB",
    CREATE_FILTERS_TAB: "CREATE_FILTERS_TAB",
    DELETED_FILTERS_TAB: "DELETED_FILTERS_TAB",
    CATEGORY_LIST_TAB: "CATEGORY_LIST_TAB",
    FILTER_NAME_LABEL: "FILTER NAME",
    FILTER_PHRASE_LABEL: "FILTER PHRASE",
    PRESENCE_LABEL: "PRESENCE",
    SPEAKER_LABEL: "SPEAKER",
    FILTER_NAME_PLACEHOLDER: "Enter Filter Name",
    FILTER_PHRASE_PLACEHOLDER: "Enter Phrase",
    PYTHON_CODE_PLACEHOLDER: "Enter Python Code",
    DISPLAY_NAME_PLACEHOLDER: "Enter Display Name",
    VARIABLE_NAME_PLACEHOLDER: "Enter Variable Name",
    PRESENT_LABEL: "PRESENCE",
    SAID_BY_REP: "REP",
    SAID_BY_OTHERS: "CLIENTS",
    SAID_BY_BOTH: "ANYONE",
    SEARCH_LEVEL_LABEL: "SEARCH LEVEL",
    SEARCH_LEVEL_PLACEHOLDER: "Enter search level",
    MONOLOGUE: "monologue",
    SENTENCE: "sentence",
    SPEAKER: "speaker",
    MEETING: "meeting",
    CONTINUOUS_N_SENTENCES: "continuous_n_sentences",
    N_SENTENCES_LABEL: "N SENTENCES",
    N_SENTENCES_PLACEHOLDER: "Enter n sentences count",
    SENTIMENT_LABEL: "SENTIMENT",
    SENTIMENT_PLACEHOLDER: "Enter sentiment",
    START_TIME_LABEL: "START TIME",
    END_TIME_LABEL: "END TIME",
    START_TIME_PLACEHOLDER: "Enter Start Time",
    END_TIME_PLACEHOLDER: "Enter End time",
    SEARCH_START_PLACEHOLDER: "Enter Search Start",
    SEARCH_END_PLACEHOLDER: "Enter Search End",
    VIEW_GLOBAL_EXPRESSIONS: "VIEW_GLOBAL_EXPRESSIONS",
    CUSTOM_TYPE: "custom",
    FATAL: "Fatal",
    NONE: "none",
    MANUAL_AUDIT_TYPE: "Manual Audit",
    AI_AUDIT_TYPE: "Ai Audit",
};

export default auditConfig;
