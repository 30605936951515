import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export default function DownloadSvg({
    sx,
    ...rest
}: SvgIconProps): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
            {...rest}
        >
            <mask
                id="mask0_22803_11523"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_22803_11523)">
                <path
                    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
