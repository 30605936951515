import {
    FormProvider,
    RHFRadioGroup,
    RHFSelect,
    RHFTextField,
} from "@convin/components/hook-form";
import RHFSelectCustom from "@convin/components/hook-form/RHFSelectCustom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, styled, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
    MomentBlockFormType,
    MomentLocationTypes,
    MomentReferenceType,
    MomentType,
    SingleResponseNode,
} from "../../../types/bert";
import useNodeContext from "../../hooks/useNodeContext";
import { participantOption } from "../../constants";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import BlockHeader from "./BlockHeader";
import { isDefined } from "@convin/utils/helper/common.helper";
import RHFPhraseListField from "@convin/modules/settings/modules/auditManager/components/bertRuleEngine/components/RHFPhraseListField";
import {
    useCreateMomentMutation,
    useGetMomentsQuery,
    useUpdateMomentMutation,
} from "@convin/redux/services/settings/auditAiConfig.service";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import { pxToRem } from "@convin/utils/getFontValue";
import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    speaker: yup.string().required("Speaker is required"),
    phrases: yup.array(),
    window: yup
        .number()
        .required("Window is required")
        .typeError("Window size must be a number")
        .required("Please provide plan cost.")
        .min(1, "Window size Must be between 1 to 3")
        .max(3, "Window size Must be between 1 to 3"),
    location: yup.string().required("Location required"),
    from: yup.string().when("location", {
        is: "specific",
        then: yup.string().required("From is required"),
        otherwise: yup.string(),
    }),
    moment_id: yup.string().when("from", {
        is: "moment_id",
        then: yup.string().required("Moment is required"),
    }),
    seconds: yup
        .number()
        .min(0, "Min : 0")
        .when("location", {
            is: "specific",
            then: yup.number().required("Seconds required"),
        }),
    operation: yup.string().when("from", {
        is: "moment",
        then: yup.string().required("Before/After is required"),
        otherwise: yup.string(),
    }),
});

const options: Array<{
    id: MomentLocationTypes;
    label: string;
}> = [
    {
        id: "recording_start_time",
        label: "Recording Start Time",
    },
    {
        id: "recording_end_time",
        label: "Recording End Time",
    },
    {
        id: "talk_start_time",
        label: "Transcript Start Time",
    },
    {
        id: "talk_end_time",
        label: "Transcript End Time",
    },
    {
        id: "moment_id",
        label: "Moment",
    },
];

const beforeAfterOptions: Array<{
    id: "+" | "-" | "+/-";
    label: string;
}> = [
    { id: "-", label: "Before" },
    { id: "+", label: "After" },
    { id: "+/-", label: "Nearby" },
];

const StyledRHFSelectCustom = styled(RHFSelectCustom)(({ theme }) => ({
    "&.MuiAutocomplete-root": {
        width: "100%",
        height: "18px",
        "& *": {
            outline: "none !important",
            border: "none !important",
        },
        "& .MuiFormControl-root": {
            height: "22px",
        },
        "& .MuiInputBase-root": {
            padding: "0px !important",
            "& .MuiInputBase-input": {
                padding: "0px !important",
                fontSize: pxToRem(12),
                color: `${theme.palette.primary.main} !important`,
                fontWeight: "600",
                "::placeholder": {
                    color: `${theme.palette.primary.main} !important`,
                    opacity: "1 !important",
                    fontWeight: "600",
                },
            },
        },
        "& .MuiButtonBase-root": {
            display: "none",
        },
    },
}));

const formatMomentData: (e: MomentType) => MomentBlockFormType = (e) => {
    const { id, ...rest } = e;
    return {
        ...rest,
        phrase: e.phrase?.map((phrase) => ({ id: phrase, phrase })) ?? [],
    };
};

const constructMomentData: (
    e: MomentBlockFormType
) => Omit<MomentType, "id" | "template_id"> = (e) => {
    return {
        ...e,
        phrase: e.phrase.map(({ phrase }) => phrase),
        variable_name: e.name.split(" ").join("_").toLowerCase(),
    };
};

const MomentsBlock = ({
    showCreateButton = true,
}: {
    showCreateButton?: boolean;
}) => {
    const canUpdate = useRef<boolean>(false);
    const { template_id } = useParams<{
        template_id: string;
    }>();
    const { data } = useGetMomentsQuery();
    const [createMoment] = useCreateMomentMutation();
    const [updateMoment] = useUpdateMomentMutation();
    const {
        sourceId,
        direction,
        onNodeSaveCallBack,
        blockParentId,
        nodeDataToUpdate,
    } = useNodeContext();

    const { createSingleResponseNode, handleSetIsSaving } =
        useReactFlowStateContextProvider();

    const { isMultiResponseTree } = useParameterAiConfigContext();

    const momentToUpdate = isDefined(nodeDataToUpdate)
        ? data?.moments.find(
              (e) => e.id === (nodeDataToUpdate as MomentReferenceType).id
          )
        : null;

    const formatMomentToUpdate = isDefined(momentToUpdate)
        ? formatMomentData(momentToUpdate)
        : isMultiResponseTree && isDefined(nodeDataToUpdate)
        ? ({
              name: nodeDataToUpdate.name,
              location: "anywhere",
              speaker: "anyone",
              window: 1,
              phrase: [],
          } as MomentBlockFormType)
        : null;

    const methods = useForm<MomentBlockFormType>({
        resolver: yupResolver(schema),
        values: isDefined(formatMomentToUpdate)
            ? { ...formatMomentToUpdate }
            : {
                  name: "",
                  location: "anywhere",
                  speaker: "anyone",
                  window: 1,
                  phrase: [],
              },
    });

    const { handleSubmit, watch, setValue } = methods;
    const location = watch("location");
    const onSubmit = async (values: MomentBlockFormType) => {
        if (values.phrase.length === 0) {
            showToast({
                ...CreateUpdateToastSettings,
                type: "error",
                message: "Atleast one phrase is required",
            });
            return;
        }
        if (isDefined(momentToUpdate)) {
            handleSetIsSaving();
            updateMoment({
                ...constructMomentData(values),
                template_id: +template_id,
                id: momentToUpdate.id,
            })
                .unwrap()
                .then(() => {
                    handleSetIsSaving();
                    onNodeSaveCallBack();
                })
                .catch(() => {
                    handleSetIsSaving();
                });
        } else {
            handleSetIsSaving();
            createMoment({
                ...constructMomentData(values),
                template_id: +template_id,
            })
                .unwrap()
                .then((res) => {
                    createSingleResponseNode<SingleResponseNode<"moment">>({
                        data: {
                            type: "moment",
                            is_not: false,
                            metadata: {
                                id: res.id,
                                name: "",
                            },
                        },
                        sourceId,
                        direction,
                        blockParentId,
                    });
                    onNodeSaveCallBack();
                    handleSetIsSaving();
                })
                .catch(() => {
                    handleSetIsSaving();
                });
        }
    };

    useEffect(() => {
        if (location === "anywhere") {
            setValue("seconds", undefined);
            setValue("from", undefined);
        }
    }, [location]);

    const from = watch("from");

    useEffect(() => {
        if (canUpdate.current) {
            if (from !== "moment_id") {
                setValue("moment_id", undefined);
                setValue("operation", undefined);
            }
            if (
                (
                    [
                        "recording_start_time",
                        "talk_start_time",
                    ] as MomentLocationTypes[]
                ).includes(from ?? "")
            ) {
                setValue("operation", "+");
            }
            if (
                (
                    [
                        "recording_end_time",
                        "talk_end_time",
                    ] as MomentLocationTypes[]
                ).includes(from ?? "")
            ) {
                setValue("operation", "-");
            }
        } else {
            canUpdate.current = true;
        }
    }, [from]);

    return (
        <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            className="h-full flex flex-col"
        >
            <BlockHeader title="Moments" showCreateButton={showCreateButton} />
            <Box
                sx={{
                    width: "100%",
                }}
                className="flex-1"
            >
                <Stack gap={1.5} className="h-full">
                    <RHFTextField
                        name="name"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        placeholder="Moment Name"
                        label="Moment Name"
                    />
                    <RHFPhraseListField />
                    <Box className="flex gap-2">
                        <RHFSelect
                            name="speaker"
                            className="w-full nodrag nopan"
                            size="small"
                            options={participantOption}
                            label="Spoken By"
                            variant="outlined"
                        />
                        <RHFTextField
                            name="window"
                            className="w-full"
                            variant="outlined"
                            size="small"
                            placeholder="Window"
                            label="Window"
                        />
                    </Box>

                    <Typography variant="medium" sx={{ fontWeight: 600 }}>
                        Location
                    </Typography>
                    <RHFRadioGroup<"specific" | "anywhere">
                        name="location"
                        options={[
                            { id: "anywhere", label: "Anywhere" },
                            { id: "specific", label: "Specific Location" },
                        ]}
                        value={location}
                        handleChange={(value) => {
                            setValue("location", value);
                        }}
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                fontSize: "0.876rem",
                            },
                            ml: -1,
                        }}
                        className="flex"
                    />
                    {location === "specific" ? (
                        <Box
                            className="flex items-center"
                            sx={{
                                px: 2,
                                py: 1.5,
                                gap: 2,
                                background: "rgba(153, 153, 153, 0.1)",
                                borderRadius: "6px",
                            }}
                        >
                            <Typography variant="small">From</Typography>
                            <Box
                                sx={{
                                    width: from
                                        ? from === "moment_id"
                                            ? "45px"
                                            : "120px"
                                        : "40px",
                                }}
                                className="flex-shrink-0"
                            >
                                <StyledRHFSelectCustom
                                    name="from"
                                    autocompleteProps={{
                                        options,
                                        className: "nodrag nopan flex-1",
                                        size: "small",
                                    }}
                                    label=""
                                    required={true}
                                    placeholder="Select"
                                />
                            </Box>

                            {from === "moment_id" ? (
                                <Box
                                    sx={{
                                        width: "40px",
                                    }}
                                    className="flex-shrink-0"
                                >
                                    <StyledRHFSelectCustom
                                        name="moment_id"
                                        autocompleteProps={{
                                            size: "small",
                                            options:
                                                data?.moments.map((e) => ({
                                                    id: e.id,
                                                    label: e.name,
                                                })) ?? [],
                                            className: "nodrag nopan",
                                        }}
                                        label=""
                                        required={true}
                                    />
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Box className="!w-[50px] relative">
                                <RHFTextField
                                    name="seconds"
                                    variant="filled"
                                    placeholder="Sec"
                                    sx={{
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                        "& input[type=number]::-webkit-outer-spin-button":
                                            {
                                                WebkitAppearance: "none",
                                                margin: 0,
                                            },
                                        "& input[type=number]::-webkit-inner-spin-button":
                                            {
                                                WebkitAppearance: "none",
                                                margin: 0,
                                            },
                                        "& .MuiInputBase-root > fieldset": {
                                            border: "none",
                                        },
                                        "& .MuiFilledInput-input": {
                                            py: 1,
                                            px: 1.5,
                                            fontSize: "0.75rem",
                                        },
                                    }}
                                    required={true}
                                    type="number"
                                />
                            </Box>

                            {from === "moment_id" ? (
                                <Box className="w-[100px]">
                                    <StyledRHFSelectCustom
                                        name="operation"
                                        autocompleteProps={{
                                            className: "nodrag nopan",
                                            options: beforeAfterOptions,
                                            size: "small",
                                        }}
                                        label=""
                                        placeholder="Before/After"
                                        required={true}
                                    />
                                </Box>
                            ) : (
                                <Typography
                                    variant="small"
                                    sx={{
                                        fontWeight: 600,
                                        color: (theme) =>
                                            theme.palette.primary.main,
                                    }}
                                >
                                    {(from === "recording_start_time" ||
                                        from === "talk_start_time") &&
                                        "After"}
                                    {(from === "recording_end_time" ||
                                        from === "talk_end_time") &&
                                        "Before"}
                                </Typography>
                            )}
                        </Box>
                    ) : null}
                </Stack>
            </Box>
        </FormProvider>
    );
};

export default MomentsBlock;
