import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { DomainConfig } from "@convin/type/Common";

export const domainApiSlice = createApi({
    reducerPath: "domainApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getDomainConfig: builder.query<DomainConfig, void>({
            query: () => ({
                url: "app/api/version/",
                method: "GET",
            }),
        }),
    }),
});

export const { useGetDomainConfigQuery } = domainApiSlice;
