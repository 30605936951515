import React from "react";

import CreatorNode from "../CreatorNode";
import CreateSingleResponseBlockType from "./CreateSingleResponseBlockType";

const CreateSingleResponseNode: React.FC = (): JSX.Element => {
    return (
        <CreatorNode>
            <CreateSingleResponseBlockType
                onNodeSaveCallBack={() => {}}
                showDefaultRuleButton
            />
        </CreatorNode>
    );
};

export default CreateSingleResponseNode;
