import React, { useEffect, Suspense, useState } from "react";
import { Result, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter, Switch, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./app/static/styles/main.scss";
import routes from "@constants/Routes/index";
import authFormConfig from "@constants/AuthForm";
import { setDomain, storeVersionData } from "@store/common/actions";
import { FallbackUI, NotFound } from "@reusables";
import commonConfig from "@constants/common/index";
import { Helmet } from "react-helmet";
import NetworkHeader from "./app/components/NetworkHeader/NetworkHeader";
import { Button } from "antd";
// import useTimer from "hooks/useTimer";
import { emptyCache, getDisplayName, getDomain } from "@tools/helpers";
import SpeakerSvg from "./app/static/svg/SpeakerSvg";
import CloseSvg from "./app/static/svg/CloseSvg";
import { IntercomProvider } from "react-use-intercom";
import { checkAuth } from "@store/auth/actions";
import { getName } from "tools/helpers";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import FaviconPng from "./app/static/images/favicon.png";
import { Box, useTheme } from "@mui/material";
import { errorReload, isDefined } from "@convin/utils/helper/common.helper";
import useAppUpdate from "@convin/hooks/useAppUpdate";
import { usePostHog } from "posthog-js/react";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { internalDomains } from "@convin/config/domain.config";
import useSearchQuery from "@convin/hooks/useSearchQuery";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ChangePassword = React.lazy(() =>
    import("app/components/container/Authentication/ChangePassword")
);

const VerifyCode = React.lazy(() =>
    import("app/components/container/Authentication/VerifyCode")
);

const SuccessInvitation = React.lazy(() =>
    import("./pages/Success/SuccessInvitation")
);

const SettingUpAccount = React.lazy(() =>
    import("./pages/SettingUpAccount/SettingUpAccount")
);

const ForgetPassword = React.lazy(() =>
    import("@container/Authentication/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
    import("@container/Authentication/ResetPassword")
);
const Home = React.lazy(() => import("@container/Home/Home"));
const InitialSetup = React.lazy(() =>
    import("@container/InitialSetup/InitialSetup")
);
const ProtectedRoute = React.lazy(() =>
    import("@container/Authentication/ProtectedRoute")
);
const Authentication = React.lazy(() =>
    import("@container/Authentication/Authentication")
);
const Landing = React.lazy(() => import("@container/Authentication/Landing"));
const PreviewCall = React.lazy(() => import("@convin/modules/previewCall"));

function App() {
    const query = useSearchQuery();
    const { needRefresh, updateServiceWorker } = useAppUpdate();
    const [versionCheckDone, setVersionCheckDone] = useState(false);
    const [versionText, setVersionText] = useState("Checking for updates...");

    const { data, isError } = useGetDomainConfigQuery();
    const theme = useTheme();

    const posthog = usePostHog();

    const {
        common: { domain, versionData },
        auth,
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDomain(window.location.host));
        let tourJson = localStorage.getItem(commonConfig.TOUR_KEY);
        if (!tourJson) {
            localStorage.setItem(commonConfig.TOUR_KEY, JSON.stringify({}));
        }
    }, [domain]);

    useEffect(() => {
        if (internalDomains.includes(domain)) {
            return;
        }
        if (auth.id) {
            window?.zapscale?.init(
                import.meta.env.VITE_APP_ZAPSCALE_KEY,
                {
                    product_name: "convin",
                    organization_name: domain,
                    organization_id: domain,
                    unique_organization_id: domain,
                    user_id: auth.email,
                    user_name: getName(auth),
                    user_email: auth.email,
                    role_id: [
                        "admin",
                        "rep",
                        "manager",
                        "team lead",
                        "auditor",
                    ].includes(auth?.role?.name?.toLowerCase())
                        ? auth?.role?.name?.toLowerCase()
                        : "custom role",
                    role_name: [
                        "admin",
                        "rep",
                        "manager",
                        "team lead",
                        "auditor",
                    ].includes(auth?.role?.name?.toLowerCase())
                        ? auth?.role?.name?.toLowerCase()
                        : "custom role",
                },
                {
                    development: !import.meta.env.PROD,
                }
            );

            if (import.meta.env.PROD) {
                posthog?.identify(auth.email, {
                    email: auth.email,
                    name: getName(auth),
                    tenant_id: domain,
                    role_id: auth.role.id,
                    team_id: auth.team,
                });
            }
        }
    }, [auth?.id]);

    useEffect(() => {
        if (isDefined(data)) {
            dispatch(
                storeVersionData(
                    data.stats_threshold
                        ? { ...data }
                        : {
                              ...data,
                              stats_threshold: {
                                  good: 75,
                                  average: 50,
                                  bad: 50,
                              },
                          }
                )
            );
            let appVersion = localStorage.getItem("av");
            if (!appVersion || +appVersion !== data.version) {
                setVersionText("Found new update. Updating please wait...");
                localStorage.setItem("av", data.version);
                // window.location.href = '/update.html';
                emptyCache();
            }
            setVersionCheckDone(true);
        }
        if (isError) {
            setVersionText("Skipping version check.");
            setVersionCheckDone(true);
        }
    }, [data, isError]);

    useEffect(() => {
        const access = query.get("access");
        const refresh = query.get("refresh");
        console.log(access, refresh);
        if (access && refresh) {
            localStorage.setItem(
                "authTokens",
                JSON.stringify({
                    access,
                    refresh,
                })
            );
        }
        dispatch(checkAuth());
    }, []);

    useEffect(() => {
        window.addEventListener("vite:preloadError", (event) => {
            errorReload("Error during page load", 1);
        });
        return () => {
            window.removeEventListener("vite:preloadError", () => {});
        };
    }, []);

    return (
        <Suspense fallback={<FallbackUI />}>
            <IntercomWrapper
                appId={import.meta.env.VITE_APP_INTERCOM_APP_ID}
                autoBoot
                autoBootProps={{
                    backgroundColor: theme.palette.primary.main,
                    actionColor: theme.palette.primary.main,
                    alignment: "left",
                }}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{getDomain(window.location.host)}</title>
                    {Object.keys(versionData || {}).length ? (
                        <link
                            rel="icon"
                            type="image/png"
                            href={versionData.logo || FaviconPng}
                            sizes="16x16"
                        />
                    ) : null}
                </Helmet>
                <Box
                    sx={{
                        a: {
                            color: "white",
                        },
                    }}
                    className="w-full"
                >
                    {versionCheckDone && <SubscriptionExpireBanner />}
                    {versionCheckDone && <ObserverBanner />}
                    {versionCheckDone && <SessionBanner />}
                    {versionCheckDone && needRefresh && (
                        <div className="flex update_notification alignCenter justifyCenter bolder">
                            {/* <MyTimer /> */}
                            <span>New update available!. Click on</span>
                            <Button
                                type="link"
                                onClick={() => updateServiceWorker(true)}
                            >
                                Update Now!
                            </Button>
                        </div>
                    )}
                </Box>
                <NetworkHeader />
                {versionCheckDone ? (
                    <>
                        {import.meta.env.VITE_APP_APP_DOMAIN.split(
                            " "
                        ).includes(domain) &&
                        import.meta.env.VITE_APP_DISABLE_APP_DOMAIN ===
                            "true" ? (
                            <Switch>
                                <Route
                                    exact
                                    path={routes.HOME}
                                    render={() => (
                                        <Landing
                                            renderForm={
                                                authFormConfig.AUTHFORM_REG
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    render={() => {
                                        return (
                                            <NotFound backLink={routes.HOME} />
                                        );
                                    }}
                                />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route
                                    exact
                                    path={routes.FORGET_PASSWORD}
                                    render={() => (
                                        <>
                                            <Helmet>
                                                <meta charSet="utf-8" />
                                                <title>
                                                    {"Forgot Password"}
                                                </title>
                                            </Helmet>
                                            <ForgetPassword />
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={routes.RESET_PASSWORD}
                                    render={() => (
                                        <>
                                            <Helmet>
                                                <meta charSet="utf-8" />
                                                <title>
                                                    {"Reset Password"}
                                                </title>
                                            </Helmet>
                                            <ResetPassword />
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={routes.SIGNIN}
                                    render={() => (
                                        <>
                                            <Helmet>
                                                <meta charSet="utf-8" />
                                                <title>{"SignIn"}</title>
                                            </Helmet>
                                            <Authentication
                                                renderForm={
                                                    authFormConfig.AUTHFORM_SIGNIN
                                                }
                                            />
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={routes.PREVIEW}
                                    component={PreviewCall}
                                />
                                <Route
                                    exact
                                    path={routes.SUCCESS_MAIL}
                                    component={SuccessInvitation}
                                />
                                <Route
                                    exact
                                    path={routes.CREATING_ORG}
                                    component={SettingUpAccount}
                                />
                                <Route
                                    exact
                                    path={routes.SIGNUP}
                                    render={() => (
                                        <Authentication
                                            renderForm={
                                                authFormConfig.AUTHFORM_REG
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path={routes.CHANGE_PASSWORD}
                                    render={() => (
                                        <>
                                            <Helmet>
                                                <meta charSet="utf-8" />
                                                <title>
                                                    {"Change Password"}
                                                </title>
                                            </Helmet>
                                            <ChangePassword />
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={routes.VERIFY_CODE}
                                    render={() => (
                                        <>
                                            <Helmet>
                                                <meta charSet="utf-8" />
                                                <title>{"Verify Code"}</title>
                                            </Helmet>
                                            <VerifyCode />
                                        </>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path={routes.SETUP}
                                    component={InitialSetup}
                                />
                                <ProtectedRoute
                                    path={routes.HOME}
                                    component={Home}
                                />
                                <Route
                                    render={() => {
                                        return (
                                            <NotFound backLink={routes.HOME} />
                                        );
                                    }}
                                />
                            </Switch>
                        )}
                    </>
                ) : (
                    <div className="flex items-center justify-center w-full h-full convin_checkingUpdate">
                        <Result
                            icon={
                                <SystemUpdateAltIcon
                                    sx={{
                                        transform: "scale(3)",
                                        color: "primary.main",
                                    }}
                                />
                            }
                            title={versionText}
                            extra={<Spin indicator={antIcon} />}
                        />
                    </div>
                )}
            </IntercomWrapper>
        </Suspense>
    );
}

const IntercomWrapper = ({ children }) => {
    const {
        auth,
        common: { domain, versionData },
    } = useSelector((state) => state);

    const { role } = auth;

    const grantAccess = (heading, code_name, permission_type) => {
        const permissionsObj = role?.code_names?.find(
            (e) => e.heading === heading
        )?.permissions;
        if (permission_type === "view") {
            const viewable_permissions = Object.keys(permissionsObj || {}).map(
                (key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.view;
                }
            );
            return !!viewable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }
        if (permission_type === "edit") {
            const editable_permissions = [].concat.apply(
                [],
                Object.keys(permissionsObj || {}).map((key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.edit;
                })
            );
            return !!editable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }
        if (permission_type === "delete") {
            const deleteable_permissions = [].concat.apply(
                [],
                Object.keys(permissionsObj || {}).map((key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.delete;
                })
            );
            return !!deleteable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }

        return !!role?.code_names?.find((e) => e.heading === heading)
            ?.is_visible;
    };

    const location_path = window.location.pathname;

    return !(
        location_path ===
        `/lms/knowledge_base/${location_path.split("/")[3]}/document/${
            location_path.split("/")[5]
        }/view`
    ) &&
        !location_path.includes("configure") &&
        auth.id &&
        Object.keys(versionData)?.length &&
        !versionData?.logo &&
        grantAccess("General", "app.can_access_intercom", "edit") ? (
        <IntercomProvider
            appId={import.meta.env.VITE_APP_INTERCOM_APP_ID}
            autoBoot={true}
            autoBootProps={{
                backgroundColor: "#1a62f2",
                actionColor: "#1a62f2",
                alignment: "left",
                email: auth.email,
                owner: getDisplayName(auth),
                name: getDisplayName(auth),
                phone: auth.primary_phone,
                domain,
            }}
        >
            {children}
        </IntercomProvider>
    ) : (
        <>{children}</>
    );
};

// const MyTimer = React.memo(
//     () => {
//         const time = new Date();
//         const expiryTimestamp = time.setSeconds(time.getSeconds() + 30);
//         const { seconds } = useTimer({
//             expiryTimestamp,
//             onExpire: () => {},
//         });

//         return (
//             <span>New update available!. Page will update in {seconds} s</span>
//         );
//     },
//     () => true
// );

const SubscriptionExpireBanner = React.memo(
    () => {
        const {
            common: { versionData },
        } = useSelector((state) => state);

        return versionData?.banners ? (
            versionData?.banners?.map(
                (e) => !e?.session && <Banner key={e.text} {...e} />
            )
        ) : (
            <></>
        );
    },
    () => true
);

const Banner = ({ level, text, onClose }) => {
    const [hideBanner, setHideBanner] = useState(false);
    const handleClose = () => {
        if (onClose) {
            onClose();
            setHideBanner(true);
        } else setHideBanner(true);
    };

    return (
        <div
            style={{
                background:
                    level === "error"
                        ? "linear-gradient(92.16deg, #FD586B 1.18%, #F93F78 99.49%)"
                        : "linear-gradient(113.24deg, #195EE7 -4.43%, #968ADF 85.5%)",
            }}
            className={`expire_banner ${hideBanner ? "" : "active"}`}
        >
            <SpeakerSvg />
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
            {level === "permanent" || (
                <span
                    className="closeSvg"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <CloseSvg />
                </span>
            )}
        </div>
    );
};

const SessionBanner = React.memo(
    () => {
        const {
            common: { versionData },
        } = useSelector((state) => state);
        const [showSessionBanner, setShowSessionBanner] = useState(false);

        useEffect(() => {
            let isContainSessionBanner = versionData?.banners?.some(
                (e) => e?.session === true
            );
            const sessionBannerExpiry = localStorage.getItem(
                "sessionBannerExpiry"
            );
            const currentTimeStamp = Math.floor(Date.now() / 1000);
            const timePeriod = 60 * 60 * 24; // seconds in a day

            if (!!isContainSessionBanner) {
                if (!sessionBannerExpiry) {
                    setShowSessionBanner(true);
                } else {
                    if (
                        currentTimeStamp - JSON.parse(sessionBannerExpiry) >=
                        timePeriod
                    ) {
                        setShowSessionBanner(true);
                    }
                }
            }
        }, []);

        const handleClose = () => {
            const currentTimeStamp = Math.floor(Date.now() / 1000);
            localStorage.setItem(
                "sessionBannerExpiry",
                JSON.stringify(currentTimeStamp)
            );
        };

        return versionData?.banners && showSessionBanner ? (
            versionData?.banners?.map(
                (e) =>
                    e.session && (
                        <Banner key={e.text} onClose={handleClose} {...e} />
                    )
            )
        ) : (
            <></>
        );
    },
    () => true
);

const ObserverBanner = React.memo(
    () => {
        const [showObservorBanner, setShowObservorBanner] = useState(false);
        const { auth } = useSelector((state) => state);

        useEffect(() => {
            const flag = localStorage.getItem("has_seen_observer_banner");
            if (auth.user_type === 0 && !flag) {
                setShowObservorBanner(true);
            }
        }, [auth]);

        return showObservorBanner ? (
            <div
                style={{
                    background:
                        "linear-gradient(113.24deg, #195EE7 -4.43%, #968ADF 85.5%)",
                }}
                className={`expire_banner ${
                    showObservorBanner ? "active" : ""
                }`}
            >
                <SpeakerSvg />
                <div>
                    Observer type users will not be able to record meetings
                </div>
                <span
                    className="closeSvg"
                    onClick={() => {
                        localStorage.setItem("has_seen_observer_banner", true);

                        setShowObservorBanner(false);
                    }}
                >
                    <CloseSvg />
                </span>
            </div>
        ) : (
            <></>
        );
    },
    () => true
);
export default withRouter(App);
