// ----------------------------------------------------------------------

import { alpha } from "@mui/material";

export default function Menu(theme) {
    return {
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: 0,
                    maxHeight: "200px",
                },

                paper: {
                    marginTop: "10px",
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: "0px 4px 16px 2px rgba(51, 51, 51, 0.10)",
                    border: "1px solid rgba(153, 153, 153, 0.20)",
                    "&.add__menu--dropdown--paper": {
                        border: `1px solid ${alpha(
                            theme?.palette?.textColors?.["333"],
                            0.1
                        )}`,
                        borderRadius: theme.shape.borderRadius,
                        boxShadow: "0px 4px 16px 2px rgba(51, 51, 51, 0.10)",
                        minWidth: "160px",
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    padding: theme.spacing(1),
                    display: "block",
                },
            },
        },
    };
}
