const commonConfig = {
    ERRORSTATUS: "error",
    SUCCESSSTATUS: "success",
    CTA_CALLOUT: "continue",
    ACCESS_TOKEN: "at",
    ACCESS_TOKEN_EXPIRE: 180, // in days
    PLAY_ICON: "fa-play-circle",
    ADMIN: 1,
    MANAGER: 2,
    TEAM_LEAD: 3,
    SALES_REP: 4,
    AUDITOR: 5,
    DESIGNATION: {
        1: "Admin",
        2: "Manager",
        3: "Team Lead",
        4: "Sales Rep",
        5: "Auditor",
    },
    USER_TYPE: {
        0: "Observer",
        1: "Product User",
    },
    TOPIC_COLORS: [
        "#800000",
        "#FF0000",
        "#FF6347",
        "#FF7F50",
        "#CD5C5C",
        "#F08080",
        "#E9967A",
        "#FA8072",
        "#FFA07A",
        "#FF4500",
        "#FF8C00",
        "#EEE8AA",
        "#BDB76B",
        "#F0E68C",
        "#808000",
        "#FFFF00",
        "#9ACD32",
        "#556B2F",
        "#32CD32",
        "#90EE90",
        "#00FF7F",
        "#2E8B57",
        "#66CDAA",
        "#3CB371",
        "#E0FFFF",
        "#00CED1",
        "#40E0D0",
        "#7FFFD4",
        "#B0E0E6",
        "#5F9EA0",
        "#4682B4",
        "#6495ED",
        "#00BFFF",
        "#1E90FF",
        "#ADD8E6",
        "#87CEEB",
        "#87CEFA",
        "#191970",
        "#BA55D3",
        "#800080",
        "#D8BFD8",
        "#DDA0DD",
        "#EE82EE",
        "#FF00FF",
        "#DA70D6",
        "#C71585",
        "#DB7093",
        "#FF1493",
        "#FF69B4",
        "#D2B48C",
        "#F0F8FF",
    ],
    TOUR_KEY: "tour_status",
    TOURS_KEYS: {
        overview: "overview",
        search: "search",
        statistics: "statistics",
        coaching: "coaching",
        library: "library",
        feedbackManager: "feedbackManager",
        userManager: "userManager",
        user: "user",
        topicManager: "topicManager",
        integrations: "integrations",
        teamManager: "teamManager",
        recordingManager: "recordingManager",
        callTypeManager: "callTypeManager",
        callTagsManager: "callTagsManager",
        auditManager: "auditManager",
        billing: "billing",
        sch_reports: "sch_reports",
        violation_manager: "violation_manager",
        Notes: "notes",
    },
};
export default commonConfig;
