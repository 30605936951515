import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function ConfigureSettingSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            sx={{ width: 16, height: 16, fill: "none", ...sx }}
        >
            <mask id="mask0_1185_43981" x="0" y="0" width="16" height="16">
                <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g>
                <path
                    d="M6.16354 14.6615L5.89687 12.5281C5.75243 12.4726 5.61632 12.4059 5.48854 12.3281C5.36076 12.2503 5.23576 12.167 5.11354 12.0781L3.13021 12.9115L1.29688 9.74479L3.01354 8.44479C3.00243 8.36701 2.99687 8.29201 2.99687 8.21979V7.76979C2.99687 7.69757 3.00243 7.62257 3.01354 7.54479L1.29688 6.24479L3.13021 3.07812L5.11354 3.91146C5.23576 3.82257 5.36354 3.73924 5.49687 3.66146C5.63021 3.58368 5.76354 3.51701 5.89687 3.46146L6.16354 1.32812H9.83021L10.0969 3.46146C10.2413 3.51701 10.3774 3.58368 10.5052 3.66146C10.633 3.73924 10.758 3.82257 10.8802 3.91146L12.8635 3.07812L14.6969 6.24479L12.9802 7.54479C12.9913 7.62257 12.9969 7.69757 12.9969 7.76979V8.21979C12.9969 8.29201 12.9858 8.36701 12.9635 8.44479L14.6802 9.74479L12.8469 12.9115L10.8802 12.0781C10.758 12.167 10.6302 12.2503 10.4969 12.3281C10.3635 12.4059 10.2302 12.4726 10.0969 12.5281L9.83021 14.6615H6.16354ZM8.03021 10.3281C8.67465 10.3281 9.22465 10.1003 9.68021 9.64479C10.1358 9.18924 10.3635 8.63924 10.3635 7.99479C10.3635 7.35035 10.1358 6.80035 9.68021 6.34479C9.22465 5.88924 8.67465 5.66146 8.03021 5.66146C7.37465 5.66146 6.82187 5.88924 6.37187 6.34479C5.92188 6.80035 5.69687 7.35035 5.69687 7.99479C5.69687 8.63924 5.92188 9.18924 6.37187 9.64479C6.82187 10.1003 7.37465 10.3281 8.03021 10.3281Z"
                    fill="#999999"
                />
            </g>
        </SvgIcon>
    );
}
