import { useContext } from "react";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    IMediaPlayerContextValues,
    MediaPlayerContext,
} from "../context/MediaPlayerContextProvider";

const useMediaPlayerContext = (): IMediaPlayerContextValues => {
    const context = useContext(MediaPlayerContext);
    if (!isDefined(context))
        throw new Error(
            "MediaPlayerContext must be use inside MediaPlayerContextProvider"
        );
    return context;
};

export default useMediaPlayerContext;
