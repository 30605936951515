import { Handle, HandleProps, Position } from "reactflow";
import AddButton from "../AddButton";
import { HTMLAttributes } from "react";
import { Box } from "@mui/system";
import BertCardWrapper from "../BertCardWrapper";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { Popover } from "@mui/material";

export default function AddHandler({
    handleClick,
    anchorEl,
    handleClose,
    children: createNewNode,
    ...props
}: HandleProps &
    Omit<HTMLAttributes<HTMLDivElement>, "id"> & {
        handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
        anchorEl: HTMLDivElement | null;
        handleClose: () => void;
    }) {
    const { isGroupBlockTree } = useReactFlowStateContextProvider();
    const open = Boolean(anchorEl);
    return (
        <>
            <Handle {...props} />
            {props.className?.split(" ").includes("invisible") ? (
                <></>
            ) : (
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",

                        ...(props.position === Position.Bottom && {
                            top: "100%",
                            left: "50%",
                            transform: "translate(-50%,0%)",
                            flexDirection: "column",
                        }),
                        ...(props.position === Position.Right && {
                            top: "50%",
                            right: "-5%",
                            transform: "translate(100%,-50%)",
                            flexDirection: "row",
                        }),
                        ...(props.position === Position.Left && {
                            top: "50%",
                            left: "-5%",
                            transform: "translate(-100%,-50%)",
                            flexDirection: "row-reverse",
                        }),
                        zIndex: 10,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: -1,
                            "& button": {
                                display: isGroupBlockTree
                                    ? "flex"
                                    : open
                                    ? "flex"
                                    : "none",
                            },
                            "&:hover": {
                                "& button": {
                                    display: "flex",
                                },
                            },
                            height: "60px",
                            width: "60px",
                        }}
                        className="nodrag nopan"
                    >
                        <AddButton onClick={handleClick} />
                    </Box>
                    {isGroupBlockTree ? (
                        <>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                classes={{
                                    paper: "overflow-visible bg-transparent",
                                }}
                                sx={{
                                    ".MuiPaper-root": {
                                        minHeight: "0px !important",
                                    },
                                }}
                            >
                                <Box
                                    className="relative"
                                    sx={{
                                        width: "420px",
                                    }}
                                >
                                    <BertCardWrapper
                                        sx={{
                                            left: "0",
                                            transform: "translate(0%,0%)",
                                            position: "absolute",
                                            bottom: "16px",
                                        }}
                                    >
                                        {createNewNode}
                                    </BertCardWrapper>
                                </Box>
                            </Popover>
                        </>
                    ) : (
                        <>
                            {open && (
                                <BertCardWrapper>
                                    {createNewNode}
                                </BertCardWrapper>
                            )}
                        </>
                    )}
                </Box>
            )}
        </>
    );
}
