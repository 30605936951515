const PauseSvg = () => (
    <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.680664"
            y="0.416748"
            width="5.05552"
            height="15.1666"
            fill="currentColor"
        />
        <rect
            x="8.26416"
            y="0.416748"
            width="5.05552"
            height="15.1666"
            fill="currentColor"
        />
    </svg>
);

const PlaySvg = () => (
    <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.4938 5.61691C12.5582 6.23144 12.5582 7.76777 11.4938 8.38231L2.95024 13.3149C1.88584 13.9294 0.555337 13.1613 0.555337 11.9322L0.555338 2.067C0.555338 0.837937 1.88584 0.0697686 2.95024 0.684301L11.4938 5.61691Z"
            fill="currentColor"
        />
    </svg>
);

const PlayerSettingsSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
    >
        <path
            d="M14.3896 9.80022C14.4203 9.54376 14.4409 9.27704 14.4409 9.00007C14.4409 8.7231 14.4203 8.46664 14.3896 8.19992L16.113 6.84583C16.2668 6.72273 16.3079 6.50731 16.2053 6.32266L14.564 3.49137C14.4716 3.31698 14.246 3.24517 14.0716 3.31698L12.0302 4.13764C11.6096 3.80938 11.148 3.54266 10.6453 3.3375L10.3375 1.16274C10.317 0.967835 10.1426 0.824219 9.93748 0.824219H6.65482C6.44966 0.824219 6.28553 0.967835 6.25475 1.16274L5.947 3.3375C5.4546 3.54266 4.99298 3.81964 4.56213 4.13764L2.52073 3.31698C2.33609 3.24517 2.12066 3.31698 2.02834 3.49137L0.387011 6.32266C0.284428 6.49705 0.335719 6.72273 0.479335 6.84583L2.20273 8.19992C2.17195 8.45638 2.15144 8.73336 2.15144 9.00007C2.15144 9.26679 2.17195 9.5335 2.20273 9.80022L0.479335 11.1543C0.325461 11.2774 0.284428 11.4928 0.387011 11.6775L2.02834 14.5088C2.12066 14.6832 2.34634 14.755 2.52073 14.6832L4.56213 13.8625C4.98272 14.1908 5.44435 14.4575 5.947 14.6626L6.25475 16.8374C6.27527 17.0323 6.44966 17.1759 6.65482 17.1759H9.93748C10.1426 17.1759 10.3068 17.0323 10.3375 16.8374L10.6453 14.6626C11.1377 14.4575 11.5993 14.1805 12.0302 13.8625L14.0716 14.6832C14.2562 14.755 14.4716 14.6832 14.564 14.5088L16.2053 11.6775C16.2976 11.5031 16.2566 11.2774 16.113 11.1543L14.3896 9.80022ZM8.29615 11.8621C6.71637 11.8621 5.42383 10.5798 5.42383 8.98981C5.42383 7.41004 6.70612 6.11749 8.29615 6.11749C9.87593 6.11749 11.1685 7.39978 11.1685 8.98981C11.1685 10.5696 9.88618 11.8621 8.29615 11.8621Z"
            fill="currentColor"
        />
    </svg>
);

const RewindSvg = () => (
    <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.00001 2.95V0.25L2.62501 3.625L6.00001 7V4.3C8.23426 4.3 10.05 6.11575 10.05 8.35C10.05 10.5843 8.23426 12.4 6.00001 12.4C3.76576 12.4 1.95001 10.5843 1.95001 8.35H0.600006C0.600006 11.3335 3.01651 13.75 6.00001 13.75C8.98351 13.75 11.4 11.3335 11.4 8.35C11.4 5.3665 8.98351 2.95 6.00001 2.95ZM5.25751 10.375H4.68376V8.1745L4.00201 8.38375V7.918L5.19676 7.49275H5.25751V10.375ZM8.14651 9.187C8.14651 9.403 8.12626 9.592 8.07901 9.7405C8.03176 9.889 7.96426 10.024 7.88326 10.1252C7.80226 10.2265 7.69426 10.3007 7.57951 10.348C7.46476 10.3953 7.32976 10.4155 7.18126 10.4155C7.03276 10.4155 6.90451 10.3953 6.78301 10.348C6.66151 10.3007 6.56026 10.2265 6.47251 10.1252C6.38476 10.024 6.31726 9.89575 6.27001 9.7405C6.22276 9.58525 6.19576 9.403 6.19576 9.187V8.6875C6.19576 8.4715 6.21601 8.2825 6.26326 8.134C6.31051 7.9855 6.37801 7.8505 6.45901 7.74925C6.54001 7.648 6.64801 7.57375 6.76276 7.5265C6.87751 7.47925 7.01251 7.459 7.16101 7.459C7.30951 7.459 7.43776 7.47925 7.55926 7.5265C7.68076 7.57375 7.78201 7.648 7.86976 7.74925C7.95751 7.8505 8.02501 7.97875 8.07226 8.134C8.11951 8.28925 8.14651 8.4715 8.14651 8.6875V9.187ZM7.57276 8.6065C7.57276 8.47825 7.56601 8.37025 7.54576 8.2825C7.52551 8.19475 7.49851 8.12725 7.46476 8.07325C7.43101 8.01925 7.39051 7.97875 7.33651 7.9585C7.28251 7.93825 7.22851 7.92475 7.16776 7.92475C7.10701 7.92475 7.04626 7.93825 6.99901 7.9585C6.95176 7.97875 6.90451 8.01925 6.87076 8.07325C6.83701 8.12725 6.81001 8.19475 6.78976 8.2825C6.76951 8.37025 6.76276 8.47825 6.76276 8.6065V9.26125C6.76276 9.3895 6.76951 9.4975 6.78976 9.58525C6.81001 9.673 6.83701 9.74725 6.87076 9.80125C6.90451 9.85525 6.94501 9.89575 6.99901 9.916C7.05301 9.93625 7.10701 9.94975 7.16776 9.94975C7.22851 9.94975 7.28926 9.93625 7.33651 9.916C7.38376 9.89575 7.43101 9.85525 7.46476 9.80125C7.49851 9.74725 7.52551 9.673 7.53901 9.58525C7.55251 9.4975 7.56601 9.3895 7.56601 9.26125V8.6065H7.57276Z"
            fill="currentColor"
        />
    </svg>
);

const ForwardSvg = () => (
    <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.1093 8.36974C10.1093 10.6367 8.26698 12.4791 6.00002 12.4791C3.73305 12.4791 1.89071 10.6367 1.89071 8.36974C1.89071 6.10277 3.73305 4.26043 6.00002 4.26043V6.99997L9.42444 3.57555L6.00002 0.151123V2.89066C2.97282 2.89066 0.520935 5.34255 0.520935 8.36974C0.520935 11.3969 2.97282 13.8488 6.00002 13.8488C9.02721 13.8488 11.4791 11.3969 11.4791 8.36974H10.1093Z"
            fill="currentColor"
        />
        <path
            d="M5.24663 10.4245V7.5H5.18499L3.97275 7.93148V8.40405L4.66448 8.19173V10.4245H5.24663Z"
            fill="currentColor"
        />
        <path
            d="M7.58894 7.53431C7.46566 7.48637 7.33553 7.46582 7.18486 7.46582C7.03419 7.46582 6.90406 7.48637 6.78078 7.53431C6.6575 7.58225 6.55477 7.65759 6.47258 7.76032C6.39039 7.86305 6.31506 7.99318 6.27396 8.15071C6.23287 8.30823 6.20547 8.49315 6.20547 8.71231V9.21913C6.20547 9.43829 6.23287 9.63006 6.28081 9.78073C6.32875 9.93141 6.39724 10.0684 6.48628 10.1711C6.57531 10.2738 6.67805 10.3492 6.80132 10.3971C6.9246 10.4451 7.05473 10.4656 7.20541 10.4656C7.35608 10.4656 7.48621 10.4451 7.60949 10.3971C7.73277 10.3492 7.8355 10.2738 7.91769 10.1711C7.99987 10.0684 8.06836 9.93826 8.1163 9.78073C8.16425 9.62321 8.18479 9.43829 8.18479 9.21913V8.71231C8.18479 8.49315 8.1574 8.30138 8.10945 8.15071C8.06151 8.00003 7.99302 7.86305 7.90399 7.76032C7.81495 7.65759 7.70537 7.58225 7.58894 7.53431ZM7.59579 9.29446C7.59579 9.42459 7.58894 9.53417 7.5684 9.62321C7.54785 9.71224 7.5273 9.78758 7.49306 9.84237C7.45881 9.89716 7.41772 9.93825 7.36293 9.9588C7.30814 9.97935 7.25335 9.99305 7.19171 9.99305C7.13007 9.99305 7.06843 9.97935 7.02049 9.9588C6.97255 9.93825 6.9246 9.89716 6.89036 9.84237C6.85612 9.78758 6.82872 9.71224 6.80817 9.62321C6.78763 9.53417 6.78078 9.42459 6.78078 9.29446V8.63012C6.78078 8.5 6.78763 8.39041 6.80817 8.30138C6.82872 8.21234 6.84927 8.14386 6.89036 8.08907C6.93145 8.03427 6.9657 7.99318 7.02049 7.97264C7.07528 7.95209 7.13007 7.93839 7.19171 7.93839C7.25335 7.93839 7.31499 7.95209 7.36293 7.97264C7.41087 7.99318 7.45881 8.03427 7.49306 8.08907C7.5273 8.14386 7.5547 8.21234 7.57524 8.30138C7.59579 8.39041 7.60264 8.5 7.60264 8.63012V9.29446H7.59579Z"
            fill="currentColor"
        />
    </svg>
);

const VolumeSvg = () => (
    <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.61601 2.67975V13.5817C8.61601 14.2774 7.77416 14.6201 7.28651 14.1325L4.39944 11.246H1.08764C0.657512 11.246 0.308838 10.8973 0.308838 10.4672V5.79439C0.308838 5.36427 0.657512 5.01559 1.08764 5.01559H4.39944L7.28654 2.12905C7.77361 1.64197 8.61601 1.98335 8.61601 2.67975ZM14.5237 0.152685C14.0346 -0.146536 13.3953 0.00753622 13.0961 0.496783C12.7969 0.98603 12.9509 1.6252 13.4402 1.92442C15.5268 3.20044 16.9232 5.50179 16.9232 8.13078C16.9232 10.5136 15.7525 12.7481 13.7915 14.1082C13.3203 14.4351 13.2033 15.0821 13.5301 15.5533C13.8571 16.0247 14.5041 16.1415 14.9751 15.8148C17.4954 14.0667 19 11.1942 19 8.13078C19 4.78053 17.2289 1.80704 14.5237 0.152685ZM15.8848 8.13078C15.8848 5.90644 14.7117 3.91953 12.9008 2.81221C12.4116 2.51302 11.7724 2.66713 11.4732 3.15641C11.174 3.64569 11.3281 4.28482 11.8174 4.58401C13.0638 5.34619 13.808 6.67209 13.808 8.13078C13.808 9.49251 13.1389 10.7696 12.0181 11.547C11.5469 11.8738 11.4299 12.5208 11.7567 12.992C11.9584 13.2828 12.2819 13.4387 12.6109 13.4387C12.8221 13.4388 13.0283 13.3741 13.2018 13.2535C14.8818 12.0881 15.8848 10.1731 15.8848 8.13078ZM12.7696 8.13078C12.7696 7.03667 12.2119 6.04263 11.2778 5.47171C10.7885 5.17271 10.1494 5.32688 9.85027 5.8162C9.55118 6.30551 9.70542 6.94464 10.1947 7.24373C10.5066 7.43434 10.6928 7.76595 10.6928 8.13078C10.6927 8.29879 10.6519 8.46427 10.5739 8.61308C10.4959 8.76188 10.383 8.88958 10.2449 8.98525C9.77363 9.31199 9.65642 9.95894 9.98316 10.4302C10.3099 10.9016 10.9569 11.0188 11.4282 10.692C12.2681 10.1097 12.7696 9.15224 12.7696 8.13078Z"
            fill="currentColor"
        />
    </svg>
);

const CollapsePlayerSvg = () => (
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_24226_8954"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="19"
            height="19"
        >
            <rect
                x="0.582031"
                y="0.539062"
                width="18"
                height="18"
                fill="#D9D9D9"
            />
        </mask>
        <g mask="url(#mask0_24226_8954)">
            <path
                d="M3.13203 17.0391L2.08203 15.9891L7.03203 11.0391H3.58203V9.53906H9.58203V15.5391H8.08203V12.0891L3.13203 17.0391ZM9.58203 9.53906V3.53906H11.082V6.98906L16.032 2.03906L17.082 3.08906L12.132 8.03906H15.582V9.53906H9.58203Z"
                fill="white"
            />
        </g>
    </svg>
);

const ExpandPlayerSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
    >
        <mask
            id="mask0_21330_38811"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="17"
            height="16"
        >
            <rect x="0.0842285" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_21330_38811)">
            <path
                d="M2.08423 14V8.66667H3.41756V11.7333L11.8176 3.33333H8.7509V2H14.0842V7.33333H12.7509V4.26667L4.3509 12.6667H7.41756V14H2.08423Z"
                fill="currentColor"
            />
        </g>
    </svg>
);

const PictureInPictureSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
        >
            <path
                d="M2.4978 16.2842C1.9478 16.2842 1.47697 16.0883 1.0853 15.6967C0.693636 15.305 0.497803 14.8342 0.497803 14.2842V2.28418C0.497803 1.73418 0.693636 1.26335 1.0853 0.87168C1.47697 0.480013 1.9478 0.28418 2.4978 0.28418H18.4978C19.0478 0.28418 19.5186 0.480013 19.9103 0.87168C20.302 1.26335 20.4978 1.73418 20.4978 2.28418V14.2842C20.4978 14.8342 20.302 15.305 19.9103 15.6967C19.5186 16.0883 19.0478 16.2842 18.4978 16.2842H2.4978ZM9.4978 9.28418H17.4978V3.28418H9.4978V9.28418Z"
                fill="#666666"
            />
        </svg>
    );
};

const VolumeMuteSvg = () => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.4998 16.8335L12.979 14.3127C12.6318 14.5349 12.2637 14.7259 11.8748 14.8856C11.4859 15.0453 11.0832 15.1738 10.6665 15.271V13.5627C10.8609 13.4932 11.0519 13.4238 11.2394 13.3543C11.4269 13.2849 11.604 13.2016 11.7707 13.1043L8.99984 10.3335V14.6668L4.83317 10.5002H1.49984V5.50016H4.1665L0.166504 1.50016L1.33317 0.333496L16.6665 15.6668L15.4998 16.8335ZM15.3332 12.0002L14.1248 10.7918C14.3609 10.3613 14.538 9.90989 14.6561 9.43766C14.7741 8.96544 14.8332 8.47933 14.8332 7.97933C14.8332 6.67377 14.4512 5.50711 13.6873 4.47933C12.9234 3.45155 11.9165 2.75711 10.6665 2.396V0.687663C12.3887 1.07655 13.7915 1.94808 14.8748 3.30225C15.9582 4.65641 16.4998 6.21544 16.4998 7.97933C16.4998 8.71544 16.3991 9.42377 16.1978 10.1043C15.9964 10.7849 15.7082 11.4168 15.3332 12.0002ZM12.5415 9.2085L10.6665 7.3335V4.62516C11.3193 4.93072 11.8297 5.38905 12.1978 6.00016C12.5658 6.61127 12.7498 7.27794 12.7498 8.00016C12.7498 8.2085 12.7325 8.41336 12.6978 8.61475C12.663 8.81614 12.6109 9.01405 12.5415 9.2085ZM8.99984 5.66683L6.83317 3.50016L8.99984 1.3335V5.66683Z"
                fill="currentColor"
            />
        </svg>
    );
};

export {
    PauseSvg,
    PictureInPictureSvg,
    PlaySvg,
    PlayerSettingsSvg,
    CollapsePlayerSvg,
    ExpandPlayerSvg,
    ForwardSvg,
    RewindSvg,
    VolumeSvg,
    VolumeMuteSvg,
};
