import routes from "@constants/Routes";
import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import { UserType } from "@convin/type/User";
import { isDefined } from "@convin/utils/helper/common.helper";
import { usePostHog } from "posthog-js/react";
import {
    PropsWithChildren,
    ReactElement,
    createContext,
    useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export type CheckCanAccessParams = {
    heading: PermissionModules;
    code_name?: string;
    permission_type?: PermissionTypes;
};
export interface IAuthContextSate {
    user: UserType | null;
    checkIsOwner: (id: number | undefined | null) => boolean;
    isAuditor: boolean;
    logout: () => void;
    checkCanAccess: ({
        heading,
        code_name,
        permission_type,
    }: CheckCanAccessParams) => boolean;
}

const AuthContext = createContext<IAuthContextSate | null>(null);
// ----------------------------------------------------------------------

function AuthProvider({ children }: PropsWithChildren): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();
    const posthog = usePostHog();

    const { auth } = useSelector((state: { auth: UserType | null }) => state);

    const checkIsOwner: IAuthContextSate["checkIsOwner"] = useCallback(
        (id) => {
            return isDefined(auth?.id) && auth?.id === id;
        },
        [auth]
    );

    const logout = useCallback(() => {
        localStorage.removeItem("authTokens");
        dispatch({ type: "LOGOUT" });
        history.push(routes.SIGNIN);
        posthog.reset();
    }, []);

    /**
     * @param {*} heading
     * @param {*} code_name optional
     * @param {*} permission_type optional
     * @return {*} Boolean value
     * @description Based on the heading, code name and permission
     * findes weather the permission exists in role field of the loged in user object
     * @author Mahen Parameshwar
     */
    const checkCanAccess: IAuthContextSate["checkCanAccess"] = ({
        heading,
        code_name,
        permission_type,
    }) => {
        const permissionsObj =
            auth?.role?.code_names?.find((e) => e.heading === heading)
                ?.permissions || {};
        if (permission_type === "view") {
            const viewable_permissions = Object.keys(permissionsObj || {}).map(
                (key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.view;
                }
            );
            return !!viewable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }
        if (permission_type === "edit") {
            const editable_permissions = Object.keys(permissionsObj || {})
                .map((key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.edit;
                })
                .flat();

            return !!editable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }
        if (permission_type === "delete") {
            const deleteable_permissions = Object.keys(permissionsObj || {})
                .map((key) => {
                    if (
                        permissionsObj[key]?.view &&
                        permissionsObj[key]?.view?.is_selected
                    )
                        return permissionsObj[key]?.delete;
                })
                .flat();

            return !!deleteable_permissions.find(
                (e) => e?.code_name === code_name
            )?.is_selected;
        }

        return !!auth?.role?.code_names?.find((e) => e.heading === heading)
            ?.is_visible;
    };

    const isAuditor = checkCanAccess({
        heading: PermissionModules.Audit_Manager,
        code_name: "audit.can_audit",
        permission_type: PermissionTypes.edit,
    });

    return (
        <AuthContext.Provider
            value={{
                user: auth ?? null,
                checkIsOwner,
                isAuditor,
                logout,
                checkCanAccess,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
