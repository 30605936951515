export const SET_ACCOUNTS_LOADER = "SET_ACCOUNTS_LOADER";
export const SET_ACCOUNTS_LIST = "SET_ACCOUNTS_LIST";
export const SET_ACCOUNTS_TEAMS = "SET_ACCOUNTS_TEAMS";
export const SET_ACCOUNTS_REPS = "SET_ACCOUNTS_REPS";
export const SET_ACTIVE_TEAM_FOR_ACCOUNTS = "SET_ACTIVE_TEAM_FOR_ACCOUNTS";
export const SET_ACTIVE_REP_FOR_ACCOUNTS = "SET_ACTIVE_REP_FOR_ACCOUNTS";
export const SET_ACCOUNT_FILTER_DATES = "SET_ACCOUNT_FILTER_DATES";
export const SET_ACCOUNT_ACTIVE_FILTER_DATES =
    "SET_ACCOUNT_ACTIVE_FILTER_DATES";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const SET_ACCOUNT_CALLS = "SET_ACCOUNT_CALLS";
export const SET_ACCOUNT_GRAPH = "SET_ACCOUNT_GRAPH";
export const SET_ACCOUNT_COMMENTS = "SET_ACCOUNT_COMMENTS";
export const SET_ACCOUNT_COMMENT_TO_REPLY = "SET_ACCOUNT_COMMENT_TO_REPLY";
export const SET_ACCOUNT_SEARCH_FILTER = "SET_ACCOUNT_SEARCH_FILTER";
export const SET_ACCOUNT_AI_DATA = "SET_ACCOUNT_AI_DATA";
export const SET_ACCOUNT_CALL_DURATION = "SET_ACCOUNT_CALL_DURATION";
export const SET_ACCOUNT_FILTER_CALL_DURATION =
    "SET_ACCOUNT_FILTER_CALL_DURATION";
export const SET_ACCOUNT_LIST_SEARCH_TEXT = "SET_ACCOUNT_LIST_SEARCH_TEXT";
export const SET_SORT_KEY_ACCOUNTS = "SET_SORT_KEY_ACCOUNTS";
export const SET_AUDIT_TEMPLATES = "SET_AUDIT_TEMPLATES";
export const SET_ACTIVE_AUDIT_TEMPLATE = "SET_ACTIVE_AUDIT_TEMPLATE";
export const SET_AUDIT_TEMPLATE_QUESTIONS = "SET_AUDIT_TEMPLATE_QUESTIONS";
export const SET_ACTIVE_STAGE = "SET_ACTIVE_STAGE";
export const SET_ACTIVE_INTERACTIONS = "SET_ACTIVE_INTERACTIONS";
export const SET_ACTIVE_DEAL_SIZE = "SET_ACTIVE_DEAL_SIZE";
export const SET_ACTIVE_CLOSE_DATE = "SET_ACTIVE_CLOSE_DATE";
export const SET_ACTIVE_LAST_CONTACT = "SET_ACTIVE_LAST_CONTACT";
export const SET_STAGE = "SET_STAGE";
export const SET_ACTIVE_FILTER_QUESTIONS = "SET_ACTIVE_FILTER_QUESTIONS";
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const RESET_ACCOUNT_LIST_FILTERS = "RESET_ACCOUNT_LIST_FILTERS";
export const SET_LEAD_SCORE_FOR_ACCOUNTS = "SET_LEAD_SCORE_FOR_ACCOUNTS";
export const SET_FILTER_DATES_FOR_ACCOUNTS = "SET_FILTER_DATES_FOR_ACCOUNTS";
export const SET_LEAD_SCORE_OBJECTS = "SET_LEAD_SCORE_OBJECTS";
export const SET_ACTIVE_LEAD_SCORE_TEMPLATE = "SET_ACTIVE_LEAD_SCORE_TEMPLATE";
export const SET_ACTIVE_LEAD_SCORE_QUESTIONS =
    "SET_ACTIVE_LEAD_SCORE_QUESTIONS";
export const SET_ACTIVE_LEAD_SCORE_PERCENT = "SET_ACTIVE_LEAD_SCORE_PERCENT";
export const SET_ACTIVE_AUDIT_TYPE = "SET_ACTIVE_AUDIT_TYPE";
export const SET_ACCOUNT_TAGS = "SET_ACCOUNT_TAGS";
export const SET_ACTIVE_ACCOUNT_TAGS = "SET_ACTIVE_ACCOUNT_TAGS";
