/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import {
    getSmoothStepPath,
    EdgeProps,
    BaseEdge,
    EdgeLabelRenderer,
    Node,
} from "reactflow";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import {
    extractSourceHandleId,
    isDefined,
} from "@convin/utils/helper/common.helper";
import type {
    EdgeData,
    MultiResponseBlockData,
    MultipleResponseNode,
} from "../../../types/bert";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";

export default function CustomDeleteEdge(props: EdgeProps<EdgeData>) {
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        targetPosition,
        sourcePosition,
        style = {},
        markerEnd,
        sourceHandleId,
        source,
    } = props;
    const curvature = 50; // Adjust curvature as needed
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: curvature,
    });
    const { setEdges, setNodes } = useReactFlowStateContextProvider();
    const { canUpdate } = useParameterAiConfigContext();
    const onDelete = () => {
        if (isDefined(sourceHandleId)) {
            canUpdate.current = true;
            const blockId = extractSourceHandleId(sourceHandleId);
            setEdges((edges) => {
                return edges.filter((e) => e.id !== id);
            });

            setNodes((nodes) =>
                nodes.map((n: Node<MultipleResponseNode<"condition">>) =>
                    n.id === source
                        ? {
                              ...n,
                              data: {
                                  ...n.data,
                                  metadata: {
                                      ...n.data.metadata,
                                      blocks: n.data.metadata.blocks.map(
                                          (
                                              e: MultiResponseBlockData["blocks"][0]
                                          ) =>
                                              e.id === blockId
                                                  ? {
                                                        ...e,

                                                        sourcePosition: null,
                                                    }
                                                  : e
                                      ),
                                  },
                              },
                          }
                        : n
                )
            );
        }
    };

    return (
        <>
            <Box
                component="g"
                sx={{
                    color: "textColors.333",
                }}
            >
                <Box
                    path={edgePath}
                    markerEnd={markerEnd}
                    style={{
                        ...style,
                        stroke: "currentColor",
                        cursor: "pointer",
                        strokeDasharray: "5,5,5,5,5",
                    }}
                    component={BaseEdge}
                />
                <marker
                    id={`arrow-${id}`} // Unique ID for each arrow marker
                    viewBox="0 0 10 10"
                    refX="5"
                    refY="5"
                    markerWidth="6"
                    markerHeight="6"
                    orient="auto"
                >
                    <path d="M 0 0 L 10 5 L 0 10 z" fill="currentColor" />
                </marker>
                {/* Attach arrow to the path */}
                <path
                    d={edgePath}
                    style={{ fill: "none", stroke: "none" }}
                    markerEnd={`url(#arrow-${id})`} // Reference the arrow marker
                />
            </Box>
            <EdgeLabelRenderer>
                <DeleteButton
                    onDelete={() => {
                        return new Promise(() => {
                            onDelete();
                        });
                    }}
                    title="Delete Edge"
                    message="Are you sure you want to delete this Edge?"
                    DeleteOptionComponent={
                        <div
                            style={{
                                position: "absolute",
                                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                                fontSize: 12,
                                // everything inside EdgeLabelRenderer has no pointer events by default
                                // if you have an interactive element, set pointer-events: all
                                pointerEvents: "all",
                                zIndex: 0,
                            }}
                            className="nodrag nopan cursor-pointer"
                        >
                            <DeleteLegendSvg />
                        </div>
                    }
                />
            </EdgeLabelRenderer>
        </>
    );
}
