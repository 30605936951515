import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {} from "@convin/type/lms";
import { EventsResponse } from "@convin/type/SupervisorAssist";
import { GlobalFiltersStateType } from "@convin/contexts/GlobalFiltersStateContextProvider";
import {
    getDomain,
    grpcPayloadConstructor,
} from "@convin/utils/helper/common.helper";

export const supervisorAssistApiSlice = createApi({
    reducerPath: "supervisorAssistApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://live.convin.ai/v1/",
    }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getCalls: builder.query<
            EventsResponse,
            Pick<GlobalFiltersStateType, "reps" | "teams"> & {
                overall_sentiment?: "eq:negative";
                violations?: boolean;
            }
        >({
            query: ({ teams, reps, ...rest }) => {
                const filter = grpcPayloadConstructor({
                    ...(teams.length && { team_id: teams }),
                    ...(reps.length && { agent_id: reps }),
                    ...rest,
                });

                return {
                    url: `${getDomain()}/events`,
                    method: "POST",
                    body: {
                        ...(filter.length && {
                            filter,
                        }),
                    },
                };
            },
        }),
    }),
});

export const { useGetCallsQuery } = supervisorAssistApiSlice;
