import { useContext } from "react";
import {
    IParameterAiConfigContext,
    ParameterAiConfigContext,
} from "../context/ParameterAiConfigContext";

const useParameterAiConfigContext = (): IParameterAiConfigContext => {
    const context = useContext(ParameterAiConfigContext);

    if (!context)
        throw new Error(
            "Bert parameter context must be use inside BertParameterContextProvider"
        );

    return context;
};

export default useParameterAiConfigContext;
