import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import useAuth from "@convin/hooks/useAuth";
import MediaPlayer from "@convin/modules/conversationDetails/components/MediaPlayer";
import MediaPlayerContextProvider from "@convin/modules/conversationDetails/context/MediaPlayerContextProvider";
import useMediaPlayerContext from "@convin/modules/conversationDetails/hooks/useMediaPlayerContext";
import { useGetConversationMediaUrlQuery } from "@convin/redux/services/conversation/conversations.service";
import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

export default function SnippetPlayer({
    id,
    start_time,
    end_time,
}: {
    id: number | string;
    start_time: number;
    end_time: number;
}) {
    const { data } = useGetConversationMediaUrlQuery(id);

    return (
        <MediaPlayerContextProvider hideController={true}>
            <MonologuePlayer
                url={data?.location}
                {...{ start_time, end_time }}
            />
        </MediaPlayerContextProvider>
    );
}

const MonologuePlayer: React.FC<{
    url?: string;
    end_time: number;
    start_time: number;
}> = ({ url, end_time, start_time }) => {
    const { checkCanAccess } = useAuth();
    const hasPlayed = useRef<boolean>(false);
    const {
        handlePause,
        playerState: { playedSeconds, loaded },
        handleSeek,
    } = useMediaPlayerContext();

    useEffect(() => {
        if (playedSeconds >= end_time) {
            handleSeek(start_time);
            handlePause();
        }
    }, [playedSeconds]);

    useEffect(() => {
        if (loaded && !hasPlayed.current) {
            handleSeek(start_time);
            hasPlayed.current = true;
        }
    }, [loaded]);
    return (
        <Box
            height={218}
            minWidth={"387px"}
            className="w-auto overflow-hidden"
            bgcolor={"#000"}
            borderRadius={"12px"}
        >
            <MediaPlayer
                mediaUrl={url}
                height={218}
                canDownload={checkCanAccess({
                    heading: PermissionModules.Meeting,
                    code_name: "meeting.restrict_transcript_access",
                    permission_type: PermissionTypes.edit,
                })}
                isSnippet
                startTime={start_time}
                endTime={end_time}
            />
        </Box>
    );
};
